import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import useLoginMutation from "../../api/hooks/useLoginMutation";
import { COLOR_YELLOW } from "../../Helper/theme";

interface FormValues {
  email: string;
  password: string;
}

const validateEmail = (value: string) => {
  let error;
  if (!value) {
    error = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

const validatePassword = (value: string) => {
  let error;
  if (!value) {
    error = "Password is required";
  } else if (value.length < 2) {
    error = "Password must be at least 8 characters long";
  }
  return error;
};

const LoginForm = () => {
  const { mutateAsync } = useLoginMutation();
  const handleSubmit = (values: FormValues) => {
    // handle form submission here
    mutateAsync(values);
  };

  return (
    <Formik<FormValues>
      initialValues={{ email: "", password: "" }}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <Field name="email" validate={validateEmail}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl isInvalid={!!(errors.email && touched.email)}>
                <FormLabel color="white" htmlFor="email">
                  Email
                </FormLabel>
                <Input
                  {...field}
                  id="email"
                  type="email"
                  placeholder="moozart@example.com"
                  bg="white"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password" validate={validatePassword}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl isInvalid={!!(errors.password && touched.password)}>
                <FormLabel className="mt-5" color="white" htmlFor="password">
                  Password
                </FormLabel>
                <Input
                  {...field}
                  id="password"
                  type="password"
                  placeholder="password"
                  bg="white"
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            type="submit"
            color={"#fff"}
            bg={COLOR_YELLOW}
            className="w-full mt-5"
            size="md"
          >
            Login
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
