import moment from "moment";
import { useRecoilState } from "recoil";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import FormUpdateWarehouse from "../../components/forms/warehouseUpdate";
import NoPermission from "../../components/molecules/noPermission";
import { permissionState } from "../../provider/atoms";

export default function UpdateWarehouse() {
  const [dataPermission] = useRecoilState<any>(permissionState);

  return (
    <DashboardContainer>
      {!dataPermission?.warehouse_access?.warehouse_access ? (
        <NoPermission />
      ) : (
        <>
          <FormUpdateWarehouse />
        </>
      )}
    </DashboardContainer>
  );
}

const WarehouseTable = ({ data }: any) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Warehouse Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Created at
            </th>
            {/* <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
              >
                eke
              </th> */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.code}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item?.created_at).format("L")}
              </td>
              {/* <td className="px-6 py-4 whitespace-nowrap">
                    <Flex >
                  <Button
                    mr={5}
                    size="sm"
                    bg={COLOR_YELLOW}
                    color="#fff"
                  >
                   Edit
                  </Button>
                  <Button
                    size="sm"
                    bg={"#d63031"}
                    color="#fff"
                  >
                   Delete
                  </Button>
                    </Flex>
                </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
