import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../../Helper/theme";
import { ModalStockAdjustment } from "../modal/modalStockAdjustment";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";
function TableStock({ data, dataBrand, dataCategory, refetch }: any) {
  const navigate = useNavigate();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleClick = (item: any) => {
    navigate("/stock-product", {
      state: {
        id: item?.id,
        name: item?.product_unit?.name,
        category: item?.product_unit?.product?.product_category?.name,
        brand: item?.product_unit?.product?.product_brand?.name,
        qty: item?.qty,
        adjust_qty: item?.adjust_qty,
        warehouse_id: item?.warehouse?.id,
      },
    });
  };
  return (
    <div
      className="overflow-x-auto whitespace-nowrap"
      style={{ maxWidth: "100%", overflowX: "scroll" }}
    >
      {" "}
      <table>
        <thead className="text-[16px] text-white h-[50px] uppercase  ">
          <tr>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Item No.
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Brand
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Qty
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Unit
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Warehouse
            </th>
            <th
              scope="col"
              className="text-[10px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody
          className="bg-white divide-y divide-gray-200"
          style={{ borderRadius: "10px 0 0 10px", background: "#e1eaf6" }}
        >
          {data?.map((item: any) => (
            <tr key={item?.id}>
              <td className="px-6 py-4 text-[12px]">
                {item?.product_unit?.code}
              </td>
              <td className="px-6 py-4 text-[12px]">
                {item?.product_unit?.name}
              </td>
              <td className="px-6 py-4 text-[12px]">
                {item?.product_unit?.product?.product_category?.name}
              </td>
              <td className="px-6 py-4 text-[12px]">
                {item?.product_unit?.product?.product_brand?.name}
              </td>
              <td className="px-6 py-4 text-[12px]">{item?.qty}</td>
              <td className="px-6 py-4 text-[12px]">
                {item?.product_unit?.uom?.name}
              </td>
              <td className="px-6 py-4 text-[12px]">{item?.warehouse?.name}</td>
              <td className="px-6 py-4 text-[12px] min-w-[280px]">
                <Button
                  onClick={() =>
                    navigate("/stock/movement", {
                      state: item,
                    })
                  }
                  size="xs"
                  bg={"#0DCAFD"}
                  color="#fff"
                  isDisabled={!dataPermission?.stock_history_access}
                >
                  Movement
                </Button>
                <ModalStockAdjustment data={item?.id} refetch={refetch} />
                <Button
                  onClick={() => handleClick(item)}
                  size="xs"
                  bg={COLOR_YELLOW}
                  color="#fff"
                >
                  See Detail
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableStock;
