import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  permission_ids: any;
  id: number;
};

const useUpdateRolesList = () => {
  const api = useAxios();
  return useMutation(({ name, permission_ids, id }: ParamType) => {
    return api
      .put(`roles/${id}`, {
        name: name,
        permission_ids: permission_ids,
      })
      .then((res) => {
        console.log(res);
        toast("Role berhasil diupdate");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  });
};

export default useUpdateRolesList;
