import { Box, Button } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import PrintDeliveryOrder from "../../../components/molecules/downloadDO";
import Header from "../../../components/molecules/header";
import "../../../components/molecules/tables/tableStyle.css";

function MobileDeliveryDetail({
  data,
  header,
  props,
  date,
  SONumber,
  customer,
  address,
  phone,
  item,
  est,
}: any) {
  const [name, setName] = useState("");
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const nav = useNavigate();
  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);
  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setName(searchTerm);
    handleSearch(searchTerm);
  };
  return (
    <div style={{ background: "#fff" }}>
      <div>
        <div className="py-5 pr-5">
          <Header
            headerName={header}
            back={true}
            navName={"/delivery"}
            exportBtn
            id={data?.[0]?.delivery_order_id}
            type={"delivery-orders"}
          />
          <div>
            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Date</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {date}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">SO Number</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {SONumber}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Customer</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {customer}
                </h1>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="bodys"
                style={{
                  width: "50%",
                }}
              >
                <h3 className="bodyTextH3Mobile">Address</h3>
                <h1 className="bodyTextH1Mobile">{address}</h1>
              </div>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Phone Number</h3>
                <h1 className="bodyTextH1Mobile">{phone}</h1>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Items</h3>
                <h1 className="bodyTextH1Mobile">{item}</h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Est. Shipment</h3>
                <h1 className="bodyTextH1Mobile">{est}</h1>
              </div>
            </div>
          </div>
          <Box p={10}>
            {data?.map((item: any) => (
              <div
                style={{
                  background: "#fff",
                  borderRadius: 10,
                  marginBottom: "1rem",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      padding: "1rem",
                    }}
                  >
                    {/* <h3 style={{ fontWeight: "bold" }}>JPDF01 (hard code)</h3> */}
                    <h1>{item?.sales_order_detail?.product_unit?.name}</h1>
                    <h1>
                      {
                        item?.sales_order_detail?.product_unit?.product
                          ?.product_category?.name
                      }{" "}
                      -{" "}
                      {
                        item?.sales_order_detail?.product_unit?.product
                          ?.product_brand?.name
                      }
                    </h1>
                    <h1>
                      @{item?.sales_order_detail?.qty}{" "}
                      {item?.sales_order_detail?.product_unit?.uom?.name}
                    </h1>
                  </div>
                  <div
                    style={{
                      padding: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={() =>
                        nav("/qr", {
                          state: {
                            DO: item?.delivery_order_id,
                            SO: item?.id,
                          },
                        })
                      }
                    >
                      Verification
                    </Button>
                    <h3
                      style={{
                        fontWeight: "bold",
                        marginTop: "0.5em",
                        textAlign: "right",
                      }}
                    >
                      {item?.sales_order_detail?.fulfilled_qty}/
                      {item?.sales_order_detail?.qty}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </Box>
          <Box ml={5}>
            <PrintDeliveryOrder data={data} props={props} />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default MobileDeliveryDetail;
