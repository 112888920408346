import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import useGetShowQuotationDetail from "../../../api/hooks/invoice/spg/useGetShowQuotationDetail";
import useGetInvoiceDetail from "../../../api/hooks/invoice/useGetInvoiceDetail";
import { formatCurrency } from "../../../components/atoms/formatCurrency";

function MobileInvoiceDetailScreen({ header }: any) {
  const location = useLocation();
  const SalesOrderId = location.state;
  const {
    data: rawData,
    refetch,
    isLoading,
  } = useGetShowQuotationDetail({
    id: SalesOrderId,
    include: "voucher",
  });
  const data = rawData?.data;
  const [name, setName] = useState("");
  const shipementFee = data?.shipment_fee || 0;
  const handleSearch = debounce((searchTerm) => {
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  let totalAmount = 0;
  const handleNavigate = () => {
    const baseUrl = `${window.location.origin}/payments-detail`;
    const params = new URLSearchParams({
      param1: encodeURIComponent(JSON.stringify(data?.payments)),
    });

    const url = `${baseUrl}?${params.toString()}`;
    window.open(url, "_blank");
  };
  const navigate = useNavigate();
  console.log(data);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg">
      <div className="py-0 ">
        <div className=" z-10 bg-gradient-to-r from-[#01338C] to-[#00A3E0] h-15 py-3 flex items-center">
          <Button
            onClick={() => navigate(-1)}
            variant={"ghost"}
            _hover={{ bg: "#fff" }}
          >
            <ArrowBackIcon fontSize="xl" color="white" />
          </Button>
          <p className="text-lg font-medium text-white">Detail Invoice</p>
        </div>
        <div className=" px-2 bg-slate-100">
          <Flex className="   w-full justify-between py-2">
            <h3>Date</h3>
            <Text align="right" fontWeight="medium">
              {moment(data?.transaction_date).format("D MMMM YYYY")}
            </Text>
          </Flex>
          <Flex className="   w-full justify-between py-2">
            <h3>Customer</h3>
            <Text align="right" fontWeight="medium">
              {data?.reseller?.name}
            </Text>
          </Flex>

          <Flex className="   w-full justify-between py-2">
            <h3>Phone Number</h3>
            <Text align="right" fontWeight="medium">
              {data?.reseller?.phone}
            </Text>
          </Flex>
          <Flex className="   w-full justify-between py-2">
            <h3>Est. Shipment</h3>
            <Text align="right" fontWeight="medium">
              {moment(data?.shipment_estimation_datetime).format("D MMMM YYYY")}
            </Text>
          </Flex>
          <Flex className="   w-full justify-between py-2">
            <h3>Payment Status</h3>
            <button onClick={handleNavigate}>
              <Text align="right" fontWeight="medium">
                {data?.payments?.length > 0 ? "Paid" : "None"}
              </Text>
            </button>
          </Flex>
          <Flex className="   w-full justify-between py-2">
            <h3>Address</h3>
            <Text fontWeight="medium">{data?.reseller?.address}</Text>
          </Flex>
        </div>
      </div>
      <div
        className="overflow-x-auto whitespace-nowrap"
        style={{ maxWidth: "100%", overflowX: "scroll" }}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Item No
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Description
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Category
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Brand
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Qty
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Unit
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                PPN
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data?.details?.map((item: any) => {
              const total_price = item?.unit_price * item?.qty;
              const discount = (item?.discount / 100) * total_price;
              const price_with_discount = total_price - discount;
              const tax = item?.tax / 100;
              const price_before_tax = price_with_discount * tax;
              const price_after_tax = price_with_discount + price_before_tax;
              totalAmount += price_after_tax;
              return (
                <>
                  <tr key={item.id} style={{ background: "#E1EAF6" }}>
                    <td className="px-6 py-4 ">{item?.product_unit?.code}</td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.description}
                      {item?.packaging_id ? "*" : null}
                    </td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.product?.product_category?.name}
                    </td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.product?.product_brand?.name}
                    </td>
                    <td className="px-6 py-4 ">{item?.qty}</td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.uom?.name}
                    </td>
                    <td className="px-6 py-4 ">
                      {item?.tax === 0 ? "-" : `${item?.tax}%`}
                    </td>
                    <td className="px-6 py-4 ">
                      {formatCurrency(item?.unit_price)}
                    </td>

                    <td className="px-6 py-4 ">
                      {formatCurrency(price_after_tax)}
                    </td>
                  </tr>
                  {item?.packaging_id !== null ? (
                    <tr key={item.id} style={{ background: "#ffffff" }}>
                      <td className="px-6 ">{item?.packaging?.code}</td>
                      <td className="px-6  ">{item?.packaging?.name}</td>
                      <td className="px-6 ">Packaging</td>
                      <td className="px-6 "></td>
                      <td className="px-6 ">{item?.qty}</td>
                      <td className="px-6 ">{item?.product_unit?.uom?.name}</td>
                      <td className="px-6 "></td>
                      <td className="px-6 "></td>
                      <td className="px-6 ">
                        {formatCurrency(item?.packaging?.price)}
                      </td>
                      <td className="px-6 ">
                        {formatCurrency(item?.packaging?.price)}
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mb-4">
        <div className="w-[97%] mx-2 align-middle">
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Subtotal</p>
            <p>{formatCurrency(totalAmount)}</p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Shipment Fee</p>
            <p> {formatCurrency(data?.shipment_fee)}</p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Discount pameran</p>
            <p>
              {formatCurrency(data?.auto_discount_nominal)}{" "}
              {`(${data?.auto_discount}%)`}
            </p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Voucher</p>
            <p className="text-[#228B22]">
              {!!data?.voucher_id
                ? `${data?.voucher_code} (${
                    data?.voucher_type === "percentage"
                      ? `${data?.voucher_value}% - ${formatCurrency(
                          data?.voucher_value_nominal
                        )}`
                      : `${formatCurrency(data?.voucher_value)}`
                  })`
                : "0"}
            </p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Total</p>
            <p>{formatCurrency(data?.price)}</p>
          </div>
        </div>
        <div className="w-fit mt-2 p-2 rounded-md border-2 mx-2">
          <Text fontWeight={"medium"}>Notes: {data?.description}</Text>
        </div>
      </div>
    </div>
  );
}

export default MobileInvoiceDetailScreen;
