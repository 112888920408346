import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  is_approved: number;
  reason: string;
};

const useApproveAdjust = () => {
  const api = useAxios();
  return useMutation(({ id, reason, is_approved }: ParamType) => {
    return api
      .put(`adjustment-requests/${id}/approve`, {
        is_approved: is_approved,
        reason: reason,
      })
      .then((res) => {
        console.log(res, "check res");
        toast(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useApproveAdjust;
