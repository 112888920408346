import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../../provider/query";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  email: string;
  password: string;
  roles: [];
  warehouse: [];
};

const useCreateAdminMutation = () => {
  const api = useAxios();
  return useMutation(
    ({ email, name, password, roles, warehouse }: ParamType) => {
      return api
        .post(`users`, {
          name: name,
          email: email,
          password: password,
          role_ids: roles,
          type: "admin",
          warehouse_ids: warehouse,
        })
        .then((res) => {
          toast("Admin berhasil dibuat");
          queryClient.invalidateQueries("userList");
        })
        .catch((err) => {
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useCreateAdminMutation;
