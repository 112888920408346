import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  receiveId: number;
};

const useDeleteRO = () => {
  const api = useAxios();
  return useMutation(({ receiveId }: ParamType) => {
    return api
      .delete(`receive-orders/${receiveId}`)
      .then((res) => {
        console.log(res, "check res");
        toast(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useDeleteRO;
