type LogErrorMutationData = { log: Error | any; hint?: string };

const logErrors = ({ log, hint = undefined }: LogErrorMutationData) => {
  try {
    // Don't log if we're in development since it'll probably send a lot of false info
    if (process.env.NODE_ENV === "development") {
      return null;
    }
    // TODO: Log errors
  } catch (e) {
    console.error(new Date(), "LOGGING ERROR", e);
  }
};

export default logErrors;
