import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

const useSalesOrderDetail = (
  params?: any,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  const api = useAxios();

  return useQuery<any>(
    `SoDetail-${params.id}`,
    () => {
      return api
        .get(`sales-orders/${params.id}`, {
          params: {
            ...params,
          },
        })
        .then((res) => {
          const data = res.data as any;
          return data;
          //DEBT: DOCS RESULT
          //   return {
          //     ...data,
          //     users: data.users.map((user) => ({
          //       // map the user properties to your own interface here
          //     })),
          //   };
        });
    },
    options
  );
};

export default useSalesOrderDetail;
