import {
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  useDisclosure,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import useCreatePaymentMutation from "../../api/hooks/invoice/useCreatePaymentInvoiceMutation";
import { useAxios } from "../../api/hooks/useApi";
import { COLOR_PRIMARY, COLOR_RED, COLOR_YELLOW } from "../../Helper/theme";
import { permissionState } from "../../provider/atoms";
import { queryClient } from "../../provider/query";
import ImageUpload from "./imageUpload/imageUpload";
import moment from "moment";

const PrintSalesOrder = ({ data }: any) => {
  const [isFullPayment, setIsFullPayment] = useState<boolean>(false);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [paymentType, setPaymentType] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [note, setNote] = useState("");
  const [files, setFiles] = useState<any>();
  const api = useAxios();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPayment,
    onOpen: openPayment,
    onClose: onClosePayment,
  } = useDisclosure();
  const navigate = useNavigate();
  const printData = () => {
    api
      .get(`invoices/${data?.id}/bill`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice-${moment(new Date()).format("YYYY-MM-DD")}.pdf`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the PDF file:", error);
        // Handle the error case accordingly
      });
  };
  // const printInvoice = () => {
  //   axios
  //     .get(
  //       `https://api.platinumadisentosa.com/invoices/${data?.id_hash}/print`,
  //       {
  //         responseType: "blob",
  //       }
  //     )
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `invoice-order-${data?.invoice_no}.pdf`; // Specify the desired file name
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the PDF file:", error);
  //       // Handle the error case accordingly
  //     });
  // };
  const handleDelete = () => {
    api
      .delete(`invoices/${data?.id}`)
      .then((response) => {
        navigate(-1);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        // Handle the error case accordingly
      });
  };
  const handleImageUpload = (imageData: any) => {
    setFiles(imageData);
  };
  const { mutateAsync, isSuccess } = useCreatePaymentMutation();

  const handlePayment = () => {
    setIsFullPayment(false);
    mutateAsync({
      sales_order_id: data.id,
      files: files,
      amount: paymentAmount,
      type: paymentType,
      note: note,
      is_full_payment: isFullPayment,
    });
  };
  useEffect(() => {
    queryClient.invalidateQueries(`invoiceDetail-${data?.id}`);
    onClosePayment();
  }, [isSuccess]);
  return (
    <>
      <div className="border-t-2" />
      <Flex p={0} className=" justify-between w-full">
        <Button
          className="w-[20%]"
          bg={COLOR_RED}
          color="#fff"
          size={"sm"}
          onClick={onOpen}
        >
          Delete
        </Button>
        <Button
          className="w-[20%] border-[1px]"
          bg={"#2ecc71"}
          color={"white"}
          size={"sm"}
          onClick={() => window.open(data?.whatsapp_url, "_blank")}
        >
          Share Whatsapp
        </Button>

        <Button
          className="w-[20%]"
          bg={COLOR_YELLOW}
          color="#fff"
          size={"sm"}
          onClick={openPayment}
        >
          Add payment
        </Button>
        <Button
          className="w-[20%] border-[1px] border-[#01338C]"
          bg={"white"}
          color={COLOR_PRIMARY}
          size={"sm"}
          onClick={printData}
        >
          Print Invoice
        </Button>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Sales Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Peringatan: SO akan dihapus secara permanen. Lanjutkan?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={handleDelete}
              bg={"red"}
              color={"#fff"}
              ml={"1rem"}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPayment} size={"5xl"} onClose={onClosePayment}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p className="mb-8">Tipe pembayaran</p>

              <RadioGroup defaultValue="1">
                <Stack spacing={5} direction="row">
                  <Radio
                    colorScheme="green"
                    value="cash"
                    onChange={(val) => setPaymentType(val.target.value)}
                  >
                    <div className="mr-10">
                      <Image
                        src={require("../../assets/icon/cash.png")}
                        h={50}
                        w={50}
                      />
                      <p>Cash</p>
                    </div>
                  </Radio>
                  <Radio
                    colorScheme="green"
                    value="credit_card"
                    onChange={(val) => setPaymentType("qris")}
                  >
                    <div className="mr-10">
                      <Image
                        src={require("../../assets/icon/cc.png")}
                        h={50}
                        w={50}
                      />
                      <p>Qris</p>
                    </div>
                  </Radio>
                  <Radio
                    colorScheme="green"
                    value="transfer"
                    onChange={(val) => setPaymentType(val.target.value)}
                  >
                    <div>
                      <Image
                        src={require("../../assets/icon/banktransfer.png")}
                        h={50}
                        w={50}
                      />
                      <p>Bank Transfer</p>
                    </div>
                  </Radio>
                </Stack>
              </RadioGroup>
              <Stack className="mt-10">
                <Checkbox
                  onChange={(e) => {
                    setIsFullPayment(e.target.checked);
                  }}
                  checked={isFullPayment}
                >
                  Full payment
                </Checkbox>
              </Stack>
              <p className="mb-2 mt-5">Input Nominal</p>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="black"
                  fontSize="1em"
                  children="Rp."
                />
                <Input
                  disabled={isFullPayment === true ? true : false}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  bg="white"
                  type="number"
                  inputMode="numeric"
                />
              </InputGroup>
              <p className="mb-2 mt-5">Catatan</p>

              <Textarea
                onChange={(e) => setNote(e.target.value)}
                bg={"white"}
                placeholder="Input Notes"
                // value={null}
              />
              <p className="mb-2 mt-5">Bukti Pembayaran</p>

              <ImageUpload onImageUpload={handleImageUpload} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              alignSelf={"center"}
              w={"100%"}
              onClick={handlePayment}
              bg={COLOR_PRIMARY}
              color={"#fff"}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PrintSalesOrder;
