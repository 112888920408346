import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  is_tempel: boolean | number;
};

const useUpdateVerification = () => {
  const api = useAxios();
  return useMutation(({ id, is_tempel }: ParamType) => {
    return api
      .put(`stocks/${id}/verification-tempel`, {
        is_tempel: is_tempel,
      })
      .then((res) => {
        console.log(res, "check res");
        toast("stocks berhasil di verifikasi");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useUpdateVerification;
