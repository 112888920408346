import { Input, Stack, Box, Button } from "@chakra-ui/react";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import useProductUnitList from "../../../api/hooks/product/useProductUnitList";
import Loading from "../../../components/atoms/loading";
import { dataFromPaginated } from "../../../Helper/pagination";
import { formatCurrency } from "../../../components/atoms/formatCurrency";
import Pagination from "../../../components/molecules/pagination";
import Header from "../../../components/molecules/header";
import { COLOR_YELLOW } from "../../../Helper/theme";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";
function ProductUnit() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const nav = useNavigate()
  const [dataPermission] = useRecoilState<any>(permissionState);

  const [searchState, setSearchState] = useState<string>("");
  const { data, isLoading, error } = useProductUnitList({
    page: currentPage,
    filter: {
      name: searchState,
    },
  });
  const dataProductUnit = useMemo(() => dataFromPaginated(data), [data]);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  const countData = data?.pages[0].meta.total;
  if (error) {
    return <div>Something went wrong....</div>;
  }
  return (
    <>
      <Header back={true} headerName="Product Pameran" />

      <Box>
        <Stack style={{ padding: "10px" }}>
          <Input
            placeholder={"Search product"}
            w={"90%"}
            bg={"white"}
            value={searchState}
            onChange={(e) => setSearchState(e?.target?.value)}
          />
        </Stack>
        {isLoading && <Loading />}
        {!isLoading && (
          <Stack>

            <table className="styled-table">
              <thead style={{ backgroundColor: "#F8FBFF" }}>
                <th
                  onClick={() => console.log("date")}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1em",
                    width: "10rem",
                  }}
                >
                  Code
                </th>
                <th
                  onClick={() => console.log("number")}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1em",
                    width: "15rem",
                  }}
                >
                  Name
                </th>

                <th
                  onClick={() => console.log("items")}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1em",
                    width: "10rem",
                  }}
                >
                  UOMS
                </th>
                <th
                  onClick={() => console.log("items")}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1em",
                    width: "10rem",
                  }}
                >
                  Price
                </th>
                <th
                  onClick={() => console.log("items")}
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "1em",
                    width: "10rem",
                  }}
                >
                  Action
                </th>
              </thead>
              <tbody>
                {dataProductUnit?.map((item: any) => {
                  return (
                    <tr key={item?.id} style={{ marginTop: "0.5rem" }}>
                      <td style={{ fontWeight: "500", fontSize: "1em" }}>
                        {item?.code ?? "-"}
                      </td>
                      <td style={{ fontWeight: "500", fontSize: "1em" }}>
                        {item?.name ?? "-"}
                      </td>
                      <td style={{ fontWeight: "500", fontSize: "1em" }}>
                                            {item?.uom?.name}
                                        </td>
                      <td style={{ fontWeight: "500", fontSize: "1em" }}>
                        {formatCurrency(item?.price)}
                      </td>
                      <td style={{ fontWeight: "400" }}>
                        <Button isDisabled={
                          !dataPermission?.product_unit_access?.product_unit_edit
                        } onClick={() =>
                          nav("/product/detail/edit-detail", { state: item })
                        } size={'sm'} bg={COLOR_YELLOW} color='white' width={'100px'}>Edit</Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Stack>
        )}
        <Pagination
          pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
          handlePagination={handlePagination}
        />
      </Box>
    </>
  );
}

export default ProductUnit;
