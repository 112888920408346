import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

interface ApiResponse {
  data: any;
  pages: any;
  meta: any;
  users: User[];
  totalPages: number;
  currentPage: number;
}

const useSupplierList = (
  params?: any,
  options?: UseInfiniteQueryOptions<ApiResponse>
): UseInfiniteQueryResult<ApiResponse> => {
  const api = useAxios();

  return useInfiniteQuery<ApiResponse>(
    "userList",
    ({ pageParam = 1 }) => {
      return api
        .get("suppliers", {
          params: {
            ...params,
          },
        })
        .then((res) => {
          const data = res.data as ApiResponse;
          return data;
          //DEBT: DOCS RESULT
          //   return {
          //     ...data,
          //     users: data.users.map((user) => ({
          //       // map the user properties to your own interface here
          //     })),
          //   };
        });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.currentPage < lastPage.totalPages) {
          return lastPage.currentPage + 1;
        }
      },
      retry: false,
      ...options,
    }
  );
};

export default useSupplierList;
