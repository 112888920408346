/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select } from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import useGetInvoiceList from "../../api/hooks/invoice/useGetInvoiceOrderList";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_YELLOW } from "../../Helper/theme";
import { permissionState } from "../../provider/atoms";

function InvoiceScreen() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState<string>();
  const [start, setStart] = useState<any>(
    moment(new Date()).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState<any>(
    moment(new Date()).endOf("month").format("YYYY-MM-DD")
  );
  const [dataPermission] = useRecoilState<any>(permissionState);
  const nav = useNavigate();
  const { data, refetch, isLoading } = useGetInvoiceList({
    page: currentPage,
    include: "user,spg",
    "filter[search]": search,
    "filter[has_delivery_order]": 0,
    "filter[has_sales_order]": filter,
    "filter[is_invoice]": true,
    sort: "-id",
  });
  console.log(data, "test");
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search, filter]);
  return (
    <div>
      {!dataPermission?.invoice_access?.invoice_access ? (
        <NoPermission />
      ) : (
        <>
          <div>
            <Header headerName={"Invoice Order"} back={false} />
            <div
              className="row  justify-between"
              style={{ marginBottom: "2rem", display: "flex" }}
            >
              <Input
                placeholder="Search Invoice"
                bg={"white"}
                w={"70%"}
                onChange={(event) => setSearch(event.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "300px",
                }}
              >
                <Select
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder="Filter"
                >
                  <option value={1}>Invoice Order</option>
                  <option value={0}>Quotation Order</option>
                </Select>
                <Button
                  bg={COLOR_FIGMA_LIGHT_BLUE}
                  color="white"
                  w={"120px"}
                  ml="10px"
                  onClick={() => nav("/invoice/order")}
                  isDisabled={!dataPermission?.invoice_access?.invoice_create}
                >
                  + Order
                </Button>
              </div>
            </div>
          </div>
          <div
            className="overflow-x-auto whitespace-nowrap"
            style={{ maxWidth: "100%", overflowX: "scroll" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 text-[12px]">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      SO Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Created by
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Payment
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className=" divide-y divide-gray-200 bg-[#e1eaf6] text-[12px]">
                  {dataList?.map((item: any) => (
                    <tr key={item.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.invoice_no}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.user?.name}{" "}
                        {`(${!!item?.spg?.name ? item?.spg?.name : "kasir"})`}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.reseller?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {moment(item?.created_at).format("D MMMM YYYY h:mm A")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatCurrency(item?.price)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            item?.payment_status === "paid"
                              ? "bg-green-400 text-white font-semibold items-center flex justify-center rounded-sm"
                              : item?.payment_status === "down_payment"
                              ? "bg-orange-400 font-semibold items-center flex justify-center rounded-sm"
                              : "bg-slate-500 text-white font-semibold items-center flex justify-center rounded-sm"
                          }
                        >
                          {item?.payment_status.toUpperCase()}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Button
                          onClick={() =>
                            nav("/invoice/show", { state: item.id })
                          }
                          size="xs"
                          bg={COLOR_YELLOW}
                          color="#fff"
                        >
                          See Detail
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </div>
  );
}

export default InvoiceScreen;
