import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";

export function DeleteGroup({ onClick }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);

  return (
    <>
      <Button
        ml={10}
        bg={"grey"}
        color={"#fff"}
        isDisabled={!dataPermission?.stock_access?.stock_print}
        onClick={() => {
          onOpen();
        }}
      >
        X
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Peringatan: Group akan didelete QR yang sudah terpasang sudah tidak
            valid untuk group ini, Lanjutkan?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button ml={"1rem"} bg={"red"} color={"#fff"} onClick={onClick}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
