import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Container, Flex, Image, Input, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStockOpnameDetails from "../../api/hooks/opnames/useStockOpnameDetail";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import { COLOR_PRIMARY } from "../../Helper/theme";

export default function StockOpnameDetails() {
  const nav = useNavigate();
  const location = useLocation();
  const params = location.state;
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useStockOpnameDetails({
    id: params,
  });
  const dataOpname = data?.pages?.flatMap((page) => page.data);
  return (
    <Container
      flex={1}
      maxW="container.sm"
      p={4}
      display="flex"
      h="100vh" // Set the height to 100% of the viewport height
      overflowY="scroll" // Enable vertical scrolling
      flexDirection="column"
    >
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Stock Opname
        </h3>
      </Flex>
      <Box>
        <Input mt={5} bg={"white"} placeholder="search opname" />
      </Box>
      {dataOpname?.map((e) => (
        <OpnameDetailCard data={e} />
      ))}
    </Container>
  );
}

const OpnameDetailCard = ({ data }: any) => {
  console.log(data, "check dalam card");
  const navigate = useNavigate();
  return (
    <Box
      as="button"
      onClick={() =>
        navigate("/stock-opname/details/scan", {
          state: {
            id: data?.stock_opname_id,
            detailId: data?.id,
            name: data?.stock_product_unit?.product_unit?.name,
          },
        })
      }
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      w="100%"
      bg="#fff"
      my={4}
      borderRadius={10}
      boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
    >
      <Flex borderBottomWidth={0.5} borderColor="#c4c4c4">
        <Box borderRadius={10} p={4} w={"70%"} bg="white" textAlign={"start"}>
          <Text fontSize={"0.9em"}>{moment().format("L")}</Text>
          <Text fontSize={"1em"} fontWeight="semibold">
            {data?.stock_product_unit?.product_unit?.name}
          </Text>
          <Text fontSize={"0.8em"} color={COLOR_PRIMARY} fontWeight="semibold">
            {data?.stock_product_unit?.product_unit?.code}
          </Text>
        </Box>
        <Box p={4} w={"30%"} justifyContent={"center"} alignSelf={"center"}>
          <Box ml="0.5rem" alignSelf={"flex-end"} justifyContent={"flex-end"}>
            <Text
              color={COLOR_PRIMARY}
              fontSize={"1em"}
              textAlign="center"
              fontWeight={"bold"}
            >
              {data?.scanned_qty} / {data?.qty}
            </Text>
            {/* <Text color={COLOR_PRIMARY} fontSize={"1em"} textAlign="center" fontWeight={'bold'} >              
                10.000 / 200.000
              </Text> */}
          </Box>
        </Box>
      </Flex>
      <Flex>
        <Box borderRadius={10} p={4} w={"80%"} bg="white" textAlign={"start"}>
          <Text fontSize={"0.8em"} fontWeight="semibold">
            Price :{" "}
            {formatCurrency(data?.stock_product_unit?.product_unit?.price)}
          </Text>
          <Text fontSize={"0.8em"}>
            Description : {data?.stock_product_unit?.product_unit?.description}
          </Text>
        </Box>
        <Box borderRadius={10} p={4} w={"30%"}>
          <Box bg={COLOR_PRIMARY} borderRadius={5} w="6rem">
            <Text color={"white"} fontSize={"1em"} textAlign="center">
              {
                data?.stock_product_unit?.product_unit?.product?.product_brand
                  ?.name
              }
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
