import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import useDeleteCategory from "../../../api/hooks/product/useDeleteCategory";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";

export function DeleteCategory({ refetch, item }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteCategory();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleDelete = ({ item }: any) => {
    mutateAsync({
      id: item?.id,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  return (
    <>
      {dataPermission?.product_category_access?.product_category_delete ? (
        <Button
          mr="1rem"
          ml="2rem"
          bg={"red"}
          color="#fff"
          size={"sm"}
          onClick={() => {
            onOpen();
          }}
        >
          Delete
        </Button>
      ) : null}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Peringatan: Category akan dihapus secara permanen. Lanjutkan?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => handleDelete({ item })}
              bg={"red"}
              color={"#fff"}
              ml={"1rem"}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
