/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR_GREEN } from "../../../Helper/theme";
import useDeliveryMobile from "../../../api/hooks/deliveryOrder/useDeliveryMobile";
import Boxx from "../../../assets/icon/roBox.png";
import { DashboardContainer } from "../../../components/atoms/dashboardContainer";
import Loading from "../../../components/atoms/loading";
import { DeliveryOrder } from "../../../components/molecules/modal/DeliveryOrder";
import { SelectCustomerList } from "../../../components/molecules/selectCustomerList";
import { SubmitOrder } from "../../../components/molecules/verify/submitOrder";

export default function DeliveryMobile() {
  const nav = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
    isFetchingNextPage,
  } = useDeliveryMobile({
    include: "salesOrder",
    "filter[invoice_no]": search,
    "filter[reseller_id]": selectedCustomer?.id,
  });

  const dataList = data?.pages.flatMap((page) => page.data);
  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;

    if (isAtBottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetchingNextPage]);
  const handleClick = (item: any) => {
    nav("/delivery/detail", {
      state: {
        id: item?.id,
        code: item?.reseller?.code,
        date: item?.created_at,
        customer: item?.reseller?.name,
        phone: item?.reseller?.phone,
        soNumber: item?.invoice_no,
        est: item?.shipment_estimation_datetime,
      },
    });
  };
  useEffect(() => {
    refetch();
  }, [search, selectedCustomer]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <DashboardContainer bg={"white"}>
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Delivery Order
        </h3>
      </Flex>
      <Box style={{ width: "100%" }} textAlign="center">
        <Input
          w={"95%"}
          bg="white"
          placeholder="Search here"
          onChange={(e) => setSearch(e.target.value)}
          mt={"0.5rem"}
        />
      </Box>
      <Flex justify={"space-between"} m={3}>
        <SelectCustomerList
          noLabel
          type="reseller"
          selected={selectedCustomer}
          setSelected={setSelectedCustomer}
        />
        <DeliveryOrder />
      </Flex>
      {dataList?.map((item: any) => (
        <>
          <Box style={{ width: "100%", alignSelf: "start" }}>
            <div
              style={{
                background: "#fff",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                borderRadius: "0.5rem",
                margin: "0.5rem 1rem 0.5rem 1rem ",
              }}
            >
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3
                    style={{
                      fontSize: "0.8em",
                      color: "#979797",
                      textAlign: "start",
                    }}
                  >
                    {moment(item?.created_at).format("L")}
                  </h3>
                  <Flex alignItems="center">
                    <h3
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        color: "#000",
                        marginTop: "0.3rem",
                        textAlign: "start",
                        textTransform: "uppercase",
                      }}
                    >
                      {item?.invoice_no}
                    </h3>
                    {item?.is_done ? (
                      <Box ml={4} bg={COLOR_GREEN} p={1} borderRadius={5}>
                        <Text color={"white"}>Completed</Text>
                      </Box>
                    ) : null}
                  </Flex>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <Image
                    justifyContent={"center"}
                    alignSelf={"center"}
                    src={Boxx}
                    boxSize={"8"}
                    mr="0.5rem"
                  />
                </div>
              </div>
              <div
                style={{
                  margin: "0rem 1rem 0rem 1rem",
                  height: "1px",
                  background: "#979797",
                }}
              ></div>
              <div style={{ padding: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <Text fontWeight={"semibold"}>{item?.reseller?.name}</Text>
                </div>
                <div style={{ display: "flex" }}>
                  <h3>Description :</h3>
                  <h3>&nbsp;{item?.description}</h3>
                </div>
                <div style={{ display: "flex" }}>
                  <h3>Est Delivery :</h3>
                  <h3>
                    &nbsp;
                    {moment(
                      item?.sales_order?.shipment_estimation_datetime
                    ).format("D MMMM YYYY")}
                  </h3>
                </div>
                <Flex py={2}>
                  <Button
                    mr={5}
                    size={"sm"}
                    onClick={(e) => {
                      handleClick(item);
                    }}
                  >
                    See detail
                  </Button>
                  {!item?.is_done ? <SubmitOrder data={item} /> : null}
                </Flex>
              </div>
            </div>
          </Box>
        </>
      ))}
      {hasNextPage && (
        <Box my={5} textAlign={"center"}>
          <Button onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? "Loading more..." : "Load more"}
          </Button>
        </Box>
      )}
    </DashboardContainer>
  );
}
