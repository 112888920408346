/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { COLOR_PRIMARY } from "../../Helper/theme";
import usePermissionsList from "../../api/hooks/permissions";
import useUpdateRolesList from "../../api/hooks/roles/useUpdateRolesList";
import Header from "../../components/molecules/header";
import Loading from "../../components/atoms/loading";

const EditRoles = () => {
  const location = useLocation();
  const dataProps = location.state;
  const [role, setRole] = useState(dataProps?.name);
  const { mutateAsync } = useUpdateRolesList();
  const { data, refetch } = usePermissionsList({
    per_page: 200,
    type: "data",
  });
  const {
    data: test,
    isFetched,
    isLoading,
  } = usePermissionsList({
    per_page: 200,
    "filter[role_id]": dataProps?.id,
    type: "permission",
    roleId: dataProps?.id,
  });
  const dataList = data?.pages?.[0]?.data;
  const permissionList = test?.pages?.[0]?.data;
  const [checkedItems, setCheckedItems] = useState<any>(permissionList);
  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (value: any) => {
    if (
      checkedItems.some(
        (checkedItem: { id: any }) => checkedItem.id === value.id
      )
    ) {
      setCheckedItems(checkedItems.filter((item: any) => item.id !== value.id));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };
  const handleAdd = () => {
    mutateAsync({
      id: dataProps.id,
      name: role,
      permission_ids: checkedItems?.map((e: any) => e.id),
    });
  };

  useEffect(() => {
    setCheckedItems(permissionList);
  }, [permissionList]);
  useEffect(() => {
    if (selectAll) {
      setCheckedItems(dataList);
    } else {
      setCheckedItems([]);
    }
  }, [selectAll]);
  const CheckBoxComponent = () => {
    return (
      <Box>
        <SimpleGrid columns={4} spacing={"10px"}>
          {dataList !== null &&
            dataList !== undefined &&
            dataList.map((item: any) => {
              return (
                <Box bg={"white"} p={5} borderWidth={0.5}>
                  {!!permissionList || !isLoading ? (
                    <Checkbox
                      isChecked={checkedItems.some(
                        (checkedItem: { id: any }) => checkedItem.id === item.id
                      )}
                      onChange={() => handleCheckboxChange(item)}
                    >
                      {item.name}
                    </Checkbox>
                  ) : (
                    <Loading />
                  )}
                </Box>
              );
            })}
        </SimpleGrid>
      </Box>
    );
  };
  return (
    <div>
      <Header headerName={"Edit Role"} back={true} />
      <div>
        <Text fontSize={"1em"} fontWeight={"bold"}>
          Role Name:
        </Text>
        <Input
          mt={"0.5rem"}
          bg={"white"}
          size={"md"}
          placeholder="The name of the role"
          value={role}
          onChange={(e) => setRole(e?.target?.value)}
        />
        <Box bg={"white"} w={"400px"} p={5} borderWidth={0.5} mt={10}>
          <Checkbox
            isChecked={selectAll}
            onChange={() => setSelectAll(!selectAll)}
            fontWeight="semibold"
          >
            Select All
          </Checkbox>
        </Box>
        <Box py={10}>
          <CheckBoxComponent />
        </Box>
        <Button
          mt="0.5rem"
          bg={COLOR_PRIMARY}
          color={"#fff"}
          size="lg"
          onClick={handleAdd}
          // isDisabled={checkedItems.length === 0}
        >
          Update Role
        </Button>
      </div>
    </div>
  );
};

export default EditRoles;
