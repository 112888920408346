import { Button } from "@chakra-ui/react";
import { debounce } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../../Helper/theme";
import DeliveryMobile from "../../../screens/Delivery/mobile";
import { SubmitOrder } from "../verify/submitOrder";

function TableDeliveryOrder({ data, header, refetch }: any) {
  const nav = useNavigate();
  const [shipNum, setShipNum] = useState("");
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const handleClick = (item: any) => {
    nav("/delivery/detail", {
      state: {
        warehouse_id: item?.warehouse_id,
        reseller_id: item?.reseller_id,
        id: item?.id,
        code: item?.reseller?.code,
        date: item?.created_at,
        customer: item?.reseller?.name,
        phone: item?.reseller?.phone,
        soNumber: item?.invoice_no,
        est: item?.shipment_estimation_datetime,
      },
    });
  };
  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setShipNum(searchTerm);
    handleSearch(searchTerm);
  };
  return (
    <div style={{ background: "#fff" }}>
      {MobileDevice ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-[12px]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  DO Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Created by
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Reseller
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Items
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Est Shipment
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200 text-[12px]"
              style={{ borderRadius: "10px 0 0 10px", background: "#e1eaf6" }}
            >
              {data?.map((item: any) => (
                <tr key={item.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.invoice_no}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.user?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.reseller?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(item?.created_at).format("L")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.details_count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(item?.shipment_estimation_datetime).format("L")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Button
                      onClick={() => handleClick(item)}
                      size="xs"
                      bg={COLOR_YELLOW}
                      color="#fff"
                      mr={3}
                    >
                      See Detail
                    </Button>
                    <SubmitOrder data={item} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <DeliveryMobile />
      )}
    </div>
  );
}

export default TableDeliveryOrder;
