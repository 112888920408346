import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { COLOR_YELLOW } from "../../../Helper/theme";
import useRepackStock from "../../../api/hooks/stocks/useRepackStock";
import { queryClient } from "../../../provider/query";
import { SelectStocks } from "../selectStocks";

export function RepackStock({ item }: any) {
  const [start, setStart] = useState("");
  const [selectedStocks, setSelectedStocks] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [quantity, setQuantity] = useState<any>(1);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync } = useRepackStock();

  const handleSubmit = () => {
    // console.log(selectedStocks, "check repack");
    setIsLoading(true);
    mutateAsync({
      id: item?.id,
      qty: quantity,
      created_at: start,
      stock_product_unit_id: selectedStocks?.id,
    })
      .then(() => {
        queryClient.invalidateQueries("ReceiveStockDetail");
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        bg={COLOR_YELLOW}
        color="#fff"
        size={"sm"}
        onClick={() => {
          onOpen();
        }}
      >
        Repack
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Repack </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <SelectStocks
                setSelected={(e: any) => setSelectedStocks(e)}
                // setSelected={setSelectedStocks}
                selected={selectedStocks}
              />
            </Box>
            <div style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "1",
                  marginBottom: "0.5rem",
                }}
              >
                Date
              </h3>
              <input
                type="date"
                id="start"
                name="start"
                value={start}
                className="form-control"
                placeholder="Start Month"
                onChange={(start) => setStart(start?.target?.value)}
              />
            </div>
            <div style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "1",
                  marginBottom: "0.5rem",
                }}
              >
                Quantity
              </h3>
              <NumberInput>
                <NumberInputField
                  placeholder="masukkan jumlahnya"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </NumberInput>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => handleSubmit()}
              isDisabled={
                quantity == 0
                  ? true
                  : selectedStocks?.id == undefined
                  ? true
                  : false
              }
              bg={COLOR_YELLOW}
              isLoading={isLoading}
              color={"#fff"}
              ml={"1rem"}
            >
              Repack
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
