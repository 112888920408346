import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useCreateProductMutation from "../../api/hooks/product/useCreateProductMutation";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";
import { SelectField } from "../molecules/select";
import { SelectBrandList } from "../molecules/selectBrandList";
import { SelectCategoryList } from "../molecules/selectCategoryList";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  description: Yup.string().required("this field is required"),
  brand: Yup.object().required("this selected must be required"),
  category: Yup.object().required("this selected must be required"),
});

function FormProduct() {
  const { mutateAsync } = useCreateProductMutation();
  const initialValues = {
    name: "",
    description: "",
    category: null,
    brand: null,
  };

  const handleSubmit = (values: any) => {
    mutateAsync({
      name: values.name,
      description: values.description,
      brand: values.brand.id,
      category: values.category.id,
    });
    // Replace this with your actual form submission logic
  };

  const options: any = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
    { label: "Option 3", value: "3" },
  ];

  return (
    <DashboardContainer>
      <Header back={true} headerName="New Product" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <FormControl isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Product Name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                bg="white"
                placeholder="Input supplier name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.description && touched.description)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Description
              </FormLabel>
              <Field
                as={Input}
                type="text"
                bg="white"
                name="description"
                placeholder="Input description here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>
            <Field name="brand">
              {({ field }: any) => (
                <>
                  <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                    Brand
                  </FormLabel>
                  <SelectBrandList
                    selectedBrand={field.value}
                    setSelectedBrand={(selectedBrand: any) =>
                      setFieldValue("brand", selectedBrand)
                    }
                  />
                </>
              )}
            </Field>
            <ErrorMessage name="brand" />
            <Field name="category">
              {({ field }: any) => (
                <>
                  <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                    Category
                  </FormLabel>
                  <SelectCategoryList
                    selectedCategory={field.value}
                    setSelectedCategory={(selectedCategory: any) =>
                      setFieldValue("category", selectedCategory)
                    }
                  />
                </>
              )}
            </Field>
            <ErrorMessage name="brand" />
            <Button
              type="submit"
              size={"lg"}
              bg={COLOR_PRIMARY}
              color="#fff"
              mt="0.5rem"
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormProduct;
