import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import useEditSOMutation from "../../api/hooks/salesOrder/useEditSOMutation";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import CounterComponent from "../../components/molecules/counter";
import Header from "../../components/molecules/header";
import { SelectCustomerList } from "../../components/molecules/selectCustomerList";
import { SelectProductList } from "../../components/molecules/selectProductList";
import { HistoryPrice } from "./newShipment";

function EditShipment() {
  const location = useLocation();
  const params = location.state;
  const nav = useNavigate();

  const [soNumber, setSONumber] = useState(params?.invoice_no);
  const [discount, setDiscount] = useState<any>(
    params?.additional_discount_percentage
  );

  const [customer, setCustomer] = useState({
    ...params?.reseller,
    label: params?.reseller?.name,
  });
  const [delivery, setDelivery] = useState<any>(params?.shipment_fee);
  const [estimatedDate, setEstimatedDate] = useState<any>();
  const [transactionDate, setTransactionDate] = useState<any>();

  const [selectedCustomer, setSelectedCustomer] = useState<any>(
    params?.reseller
  );
  const dataMap = params?.details?.map((e: any) => ({
    id: e.id,
    qty: e.qty,
    price: e.unit_price,
    packaging_id: e.packaging_id,
    packaging: {
      ...e.packaging,
    },
    product_unit: {
      ...e.product_unit,
      price: e.unit_price,
      discount: e.discount,
      packaging: {
        ...e.product_unit.packaging,
      },
    },
    ppn: e.tax === 11 ? true : false,
    warehouse: {
      id: e.warehouse.id,
    },
  }));
  const [checkedItems, setCheckedItems] = useState(
    params?.details?.map((item: any) =>
      item.packaging_id === null ? false : true
    )
  );

  const handleCheckboxChangePack = (event: any, index: number) => {
    setCheckedItems((prevCheckedItems: any) => {
      const updatedCheckedItems = [...prevCheckedItems];
      updatedCheckedItems[index] = event;
      return updatedCheckedItems;
    });
  };
  const handleUnitPrice = (event: any, index: number) => {
    const newPrice = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = prevItems.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            product_unit: {
              ...item.product_unit,
              price: !!newPrice
                ? newPrice
                : newPrice === 0
                ? 0
                : item.product_unit.price,
            },
          };
        }
        return item;
      });
      return updatedDiscounts;
    });
  };
  const [items, setItems] = useState<any>(dataMap || []);
  const [, setNextId] = useState(1);
  const [notes, setNotes] = useState<string>(params?.description);
  const calculateValue = (percentage: number, total: number) => {
    return (percentage / 100) * total;
  };
  const handleQtyChange = (event: any, index: number) => {
    const newQty = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = [...prevItems];
      updatedDiscounts[index] = { ...updatedDiscounts[index], qty: newQty };
      return updatedDiscounts;
    });
  };
  const handleDiscountChange = (event: any, index: number) => {
    const newDiscount = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = prevItems.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            product_unit: {
              ...item.product_unit,
              discount: newDiscount || 0,
            },
          };
        }
        return item;
      });
      return updatedDiscounts;
    });
  };
  const handleCountChange = (newCountValue: number, index: any): void => {
    handleQtyChange(newCountValue, index);
  };
  const handleAddItem = async (value: any) => {
    const data = {
      id: value?.id,
      qty: value.qty,
      price: value.price,
      product_unit: {
        id: value?.value,
        name: value.label,
        price: value.price,
        discount: value.discount,
      },
      ppn: false,
      warehouse: {
        id: value.warehouse_id,
      },
    };
    await setItems((prevItems: any): any => [...prevItems, data]);
  };
  console.log(params, "s");
  const handleCheckboxChange = (event: any, index: number) => {
    setItems((prevItems: any) => {
      const updatedPpn = [...prevItems];
      updatedPpn[index] = {
        ...updatedPpn[index],
        ppn: event,
      };
      return updatedPpn;
    });
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems: any[]) =>
      prevItems.filter((item: any) => item.id !== itemId)
    );

    if (items.length === 1) {
      setNextId(1); // Reset nextId to 1 when all items are deleted
    }
  };
  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);
  };
  const { mutateAsync } = useEditSOMutation();
  // const handleSubmit = () => {
  //   items.forEach((e: any, index: number) => {
  //     if (checkedItems[index]) {
  //       console.log(e.product_unit.packaging.id, "dpt apa ini");
  //     }
  //   });
  // };

  const handleSubmit = () => {
    mutateAsync({
      id: params?.id,
      description: notes,
      invoice_no: soNumber,
      shipment_fee: delivery,
      discount: discount,
      resellerId: customer?.id,
      warehouseId: params.warehouse_id,
      transactionDate: moment(
        transactionDate || params.transaction_date
      ).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(
        estimatedDate || params.shipment_estimation_datetime
      ).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.product_unit?.id || e.value,
        qty: e.qty,
        unit_price: e?.product_unit?.price,
        total_price:
          (e?.product_unit?.price || e.price || 0) * (!!e?.qty ? e?.qty : 1) -
          calculateValue(
            e?.product_unit?.discount || 0,
            (e?.product_unit?.price || e.price || 0) * (!!e?.qty ? e?.qty : 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                e?.product_unit?.price * (!!e?.qty ? e?.qty : 1) -
                  calculateValue(
                    e?.product_unit?.discount,
                    (e?.product_unit?.price || e.price) *
                      (!!e?.qty ? e?.qty : 1)
                  )
              )
            : 0),
        discount: e?.product_unit?.discount || 0,
        tax: !!e?.ppn ? e.ppn : false,
        packaging_id: checkedItems[index] ? e.product_unit.packaging.id : null,
        warehouse_id: e?.warehouse?.id || e?.warehouse_id,
      })),
    })
      .then((res) => nav("/sales"))
      .catch((err) => console.log(err));
  };
  let totalAmount = 0;
  let subTotal = 0;
  return (
    <div>
      <Header headerName={"Edit Sales Order"} back={true} navName={"/sales"} />
      <Box p={5}>
        <Text fontWeight={"medium"}>SO Number</Text>
        <Input
          placeholder="PAS/SO/01/23/01"
          mt={"0.5em"}
          bg={"#fff"}
          value={soNumber}
          onChange={(e) => setSONumber(e.target.value)}
        />
      </Box>
      <Box mx={5}>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Customer
        </h3>
        <SelectCustomerList
          noLabel
          type="reseller"
          selected={customer}
          setSelected={(e: any) => setCustomer({ ...e, id: e.value })}
        />
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr>
              <th />
              <th>Product Name</th>
              <th>Unit</th>
              <th>Packaging</th>
              <th>PPN 11%</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Sub Price</th>
              <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="dropable">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {items?.map((item: any, index: any) => {
                  const productUnitPrice = item?.product_unit?.price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue + ppnValue;
                  const total = totalValue - discountValue;
                  subTotal += totalValue;
                  totalAmount += total;
                  return (
                    <Draggable
                      key={item.label}
                      draggableId={item.label}
                      index={index}
                    >
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td {...provider.dragHandleProps}> = </td>
                          <td>
                            <Text>{item?.product_unit?.name}</Text>
                          </td>
                          <td>
                            <Text>{item?.product_unit?.uom?.name}</Text>
                          </td>
                          <td>
                            <Box>
                              <Checkbox
                                isChecked={checkedItems[index]}
                                onChange={(e) =>
                                  handleCheckboxChangePack(
                                    e.target.checked,
                                    index
                                  )
                                }
                                colorScheme="green"
                                size={"lg"}
                                bg={"white"}
                                defaultChecked={false}
                                isDisabled={
                                  item.packaging_id === null ? true : false
                                }
                              />
                            </Box>
                          </td>
                          <td>
                            <Box>
                              <Checkbox
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    event.target.checked,
                                    index
                                  )
                                }
                                colorScheme="green"
                                size={"lg"}
                                bg={"white"}
                                defaultChecked={false}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box display="flex">
                              <CounterComponent
                                onCountChange={(e) =>
                                  handleCountChange(e, index)
                                }
                                defaultValue={item.qty}
                                stockCount={item.stocks_count}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="black"
                                  fontSize="1em"
                                  children="Rp."
                                />
                                <Input
                                  defaultValue={
                                    item?.product_unit?.price !== undefined
                                      ? item.product_unit.price.toString()
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUnitPrice(e.target.value, index)
                                  }
                                  bg="white"
                                  type="number"
                                  inputMode="numeric"
                                />
                                <InputRightElement>
                                  <HistoryPrice
                                    resellerId={selectedCustomer?.value}
                                    productId={item?.value}
                                    handleUnitPrice={handleUnitPrice}
                                    index={index}
                                  />
                                </InputRightElement>
                              </InputGroup>
                            </Box>
                          </td>
                          <td>
                            <Text>{formatCurrency(totalValue)}</Text>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <Button
                                variant={"link"}
                                borderBottom={1}
                                borderBottomColor={"#01338C"}
                                borderBottomWidth={1}
                                color={"#01338C"}
                                onClick={() => handleRemoveItem(item.id)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(subTotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Box textAlign="center">
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          fontSize="1em"
                          children="Rp."
                          alignSelf={"center"}
                        />
                        <Input
                          placeholder="Delivery Price"
                          bg={"#fff"}
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                          type="number"
                        />
                      </InputGroup>
                    </Box>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Additional Discount</Text>
                  </td>
                  <td>
                    <NumberInput
                      maxW={20}
                      defaultValue={params?.additional_discount_percentage}
                      min={0}
                      max={100}
                    >
                      <InputGroup>
                        <InputRightElement color="black" children="%" />
                        <NumberInputField
                          onChange={(e) => setDiscount(e.target.value)}
                          value={discount}
                        />
                      </InputGroup>
                    </NumberInput>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(totalAmount + parseFloat(delivery))}
                    </Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
      <Box mx={5} mt={5}>
        <SelectProductList
          customerId={selectedCustomer?.id}
          selectedProducts={items}
          setSelectedProduct={(e: any) => handleAddItem(e)}
        />
      </Box>
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <Box my={"1rem"}>
          <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
            Estimated Date
          </FormLabel>
          <DatePicker
            border
            selected={estimatedDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setEstimatedDate(date)}
            placeholderText="Select a date"
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
      </div>
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <Box my={"1rem"}>
          <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
            Transaction Date
          </FormLabel>
          <DatePicker
            border
            selected={transactionDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setTransactionDate(date)}
            placeholderText="Select a date"
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
      </div>
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Notes
        </h3>
        <Textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          bg={"white"}
          placeholder="Input Notes"
        />
      </div>

      <Button
        onClick={handleSubmit}
        color="#fff"
        bg={"#01338C"}
        w="10rem"
        mt={"1rem"}
        ml="1.5rem"
      >
        Add
      </Button>
    </div>
  );
}

export default EditShipment;
