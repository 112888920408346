import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import useDeleteRoDetails from "../../../api/hooks/receiveOrder/useDeleteRoDetail";
import { DeleteIcon } from "@chakra-ui/icons";

export function DeleteDetails({ refetch, item, data }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteRoDetails();
  console.log(data, "check data dalam delete details");
  const handleDelete = ({ item }: any) => {
    mutateAsync({
      receiveId: data?.id,
      id: item?.id,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  return (
    <>
      <IconButton
        bg={"#800000"}
        ml="0.5rem"
        aria-label="Delete"
        size="md"
        icon={<DeleteIcon />}
        onClick={onOpen}
        color={"#fff"}
      />
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Peringatan: Product akan dihapus secara permanen. Lanjutkan?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => handleDelete({ item })}
              bg={"red"}
              color={"#fff"}
              ml={"1rem"}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
