import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  orderId: number;
  qrcode: string;
  salesOrderId: number;
};

const useScanOrder = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(({ orderId, qrcode, salesOrderId }: ParamType) => {
    return api
      .post(`delivery-orders/${orderId}/verification/${salesOrderId}`, {
        stock_id: qrcode,
      })
      .then((res) => {
        console.log(res);
        toast("Code berhasil di scan");
      })
      .catch((err) => {
        console.log(err, "error scan");
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useScanOrder;
