import { Button, Input, Select } from "@chakra-ui/react";
import Header from "../../components/molecules/header";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_PRIMARY } from "../../Helper/theme";
import { useState } from "react";
import TableNewReturn from "../../components/molecules/tables/tableNewReturn";
import { debounce } from "lodash";

function NewReturn() {
  const [shipNum, setShipNum] = useState("");
  const [text, setText] = useState("");
  const [items, setItems] = useState([{ id: 1 }]);
  const [nextId, setNextId] = useState(2);

  const handleAddItem = () => {
    const newItem = {
      id: nextId, // Use the next available ID
    };

    setItems((prevItems) => [...prevItems, newItem]);
    setNextId((prevNextId) => prevNextId + 1);
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

    if (items.length === 1) {
      setNextId(1); // Reset nextId to 1 when all items are deleted
    }
  };

  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  const handleReason = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setShipNum(searchTerm);
    handleSearch(searchTerm);
  };

  const handleInputChangeReason = (event: any) => {
    const searchTerm = event.target.value;
    setText(searchTerm);
    handleReason(searchTerm);
  };
  return (
    <div>
      <Header headerName={"Add Return"} back={true} navName={"/return"} />
      <div style={{ marginLeft: "1.5rem" }}>
        <h3 style={{ fontWeight: 500, fontSize: "1.2rem" }}>Return Number</h3>
        <Input
          mt={"0.5rem"}
          placeholder="Input here"
          size={"lg"}
          w={"25rem"}
          value={shipNum}
          onChange={handleInputChange}
        />
      </div>

      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <h3 style={{ fontWeight: 500, fontSize: "1.2rem" }}>
          Delivery Order Number
        </h3>
        <Select mt={"0.5rem"} w={"25rem"} placeholder="Select Here" size={"lg"}>
          <option value="option1">Acong</option>
          <option value="option2">Michael</option>
          <option value="option3">Bob</option>
        </Select>
      </div>

      {items?.map((item) => (
        <div
          style={{ marginLeft: "1.5rem", marginTop: "1rem", display: "flex" }}
        >
          <div>
            <h3 style={{ fontWeight: 500, fontSize: "1.2rem" }}>
              Items {item?.id}
            </h3>
            <Select
              mt={"0.5rem"}
              w={"25rem"}
              placeholder="Select Here"
              size={"lg"}
            >
              <option value="option1">Acong</option>
              <option value="option2">Michael</option>
              <option value="option3">Bob</option>
            </Select>
          </div>
          <Button
            ml={"1.5rem"}
            mt={"1rem"}
            variant={"link"}
            borderBottom={1}
            borderBottomColor={"#01338C"}
            borderBottomWidth={1}
            color={"#01338C"}
            onClick={() => handleRemoveItem(item.id)}
          >
            Remove Item
          </Button>
        </div>
      ))}
      <Button
        ml={"1.5rem"}
        mt={"1rem"}
        variant={"link"}
        borderBottom={1}
        borderBottomColor={"#01338C"}
        borderBottomWidth={1}
        color={"#01338C"}
        onClick={handleAddItem}
      >
        Add Item
      </Button>
      <div>
        <Button
          bg={COLOR_FIGMA_LIGHT_BLUE}
          ml={"1.5rem"}
          mt={"1rem"}
          color={"#fff"}
          fontWeight={"bold"}
          w={"10rem"}
          h={"3rem"}
          _hover={{ bg: COLOR_PRIMARY }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default NewReturn;
