import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  code: string;
  name: string;
  email: string;
  phone: number;
  address: string;
  description: string;
};

const useCreateSupplierMutation = () => {
  const api = useAxios();
  return useMutation(
    ({ email, code, name, phone, address, description }: ParamType) => {
      return api
        .post(`suppliers`, {
          code: code,
          name: name,
          email: email,
          phone: phone,
          address: address,
          description: description,
        })
        .then((res) => {
          toast("Supplier berhasil dibuat");
        })
        .catch(defaultErrorHandler);
    }
  );
};

export default useCreateSupplierMutation;
