import { debounce } from "lodash";
import moment from "moment";
import { useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import useGetInvoiceDetail from "../../api/hooks/invoice/useGetInvoiceDetail";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import Header from "../../components/molecules/header";
import PrintSalesOrder from "../../components/molecules/printSalesOrder";
import ConvertQuotationModal from "./components/ConvertQuotationModal";

function InvoiceDetailScreen({ header }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const SalesOrderId = location.state;
  const {
    data: rawData,
    refetch,
    isLoading,
  } = useGetInvoiceDetail({
    id: SalesOrderId,
    include: "voucher",
  });
  const data = rawData?.data;
  const [name, setName] = useState("");
  const shipementFee = data?.shipment_fee || 0;
  const handleSearch = debounce((searchTerm) => {
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  let totalAmount = 0;
  const handleNavigate = () => {
    const baseUrl = `${window.location.origin}/payments-detail`;
    const params = new URLSearchParams({
      param1: encodeURIComponent(JSON.stringify(data?.payments)),
    });

    const url = `${baseUrl}?${params.toString()}`;
    window.open(url, "_blank");
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg">
      <div className="py-5 pr-5">
        <Header
          headerName={data?.invoice_no}
          back={true}
          navName={"/sales/edit-shipment"}
          exportBtn={true}
          edit={false}
          id={data?.id}
          type={"sales-orders"}
          params={data}
        />
        <div>
          <Flex className="justify-between py-2">
            <h3></h3>
            <Button
              size={"sm"}
              w="100px"
              onClick={() => navigate("/invoice/edit", { state: data })}
            >
              Edit
            </Button>
          </Flex>
          <Flex className="justify-between py-2">
            <h3>Date</h3>
            <Text fontWeight="medium">
              {moment(data?.transaction_date).format("D MMMM YYYY")}
            </Text>
          </Flex>

          <Flex className="justify-between py-2">
            <h3>Customer</h3>
            <Text fontWeight="medium">{data?.reseller?.name}</Text>
          </Flex>

          <Flex className="justify-between py-2">
            <h3>Phone Number</h3>
            <Text fontWeight="medium">{data?.reseller?.phone}</Text>
          </Flex>
          <Flex className="justify-between py-2">
            <h3>Est. Shipment</h3>
            <Text fontWeight="medium">
              {moment(data?.shipment_estimation_datetime).format("D MMMM YYYY")}
            </Text>
          </Flex>
          <Flex className="justify-between py-2">
            <h3>Payment Status</h3>
            <button
              onClick={handleNavigate}
              className="bg-blue-500 rounded-lg py-1 px-3"
            >
              <Text fontWeight="medium" color="white">
                {data?.payments?.length > 0
                  ? "Paid - Click to see detail"
                  : "None"}
              </Text>
            </button>
          </Flex>
          <Flex className="justify-between py-2 ">
            <h3>Address</h3>
            <Text fontWeight="medium">{data?.reseller?.address}</Text>
          </Flex>
        </div>
      </div>
      <div
        className="overflow-x-auto whitespace-nowrap"
        style={{ maxWidth: "100%", overflowX: "scroll" }}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Item No
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Description
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Category
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Brand
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Qty
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Unit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Discount
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                PPN
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data?.details?.map((item: any) => {
              const total_price = item?.unit_price * item?.qty;
              const discount = (item?.discount / 100) * total_price;
              const price_with_discount = total_price - discount;
              const tax = item?.tax / 100;
              const price_before_tax = price_with_discount * tax;
              const price_after_tax = price_with_discount + price_before_tax;
              totalAmount += price_after_tax;
              return (
                <>
                  <tr key={item.id} style={{ background: "#E1EAF6" }}>
                    <td className="px-6 py-4 ">{item?.product_unit?.code}</td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.description}
                      {item?.packaging_id ? "*" : null}
                    </td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.product?.product_category?.name}
                    </td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.product?.product_brand?.name}
                    </td>
                    <td className="px-6 py-4 ">{item?.qty}</td>
                    <td className="px-6 py-4 ">
                      {item?.product_unit?.uom?.name}
                    </td>
                    <td className="px-6 py-4 ">{item?.discount}%</td>
                    <td className="px-6 py-4 ">
                      {item?.tax === 0 ? "-" : `${item?.tax}%`}
                    </td>
                    <td className="px-6 py-4 ">
                      {formatCurrency(item?.unit_price)}
                    </td>

                    <td className="px-6 py-4 ">
                      {formatCurrency(price_after_tax)}
                    </td>
                  </tr>
                  {item?.packaging_id !== null ? (
                    <tr key={item.id} style={{ background: "#ffffff" }}>
                      <td className="px-6 ">{item?.packaging?.code}</td>
                      <td className="px-6  ">{item?.packaging?.name}</td>
                      <td className="px-6 ">Packaging</td>
                      <td className="px-6 "></td>
                      <td className="px-6 ">{item?.qty}</td>
                      <td className="px-6 ">{item?.product_unit?.uom?.name}</td>
                      <td className="px-6 "></td>
                      <td className="px-6 "></td>
                      <td className="px-6 ">
                        {formatCurrency(item?.packaging?.price)}
                      </td>
                      <td className="px-6 ">
                        {formatCurrency(item?.packaging?.price)}
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex-row flex justify-between">
        <div className="w-[45%]  rounded-md border-2 p-2">
          <Text fontWeight={"medium"}>Notes: {data?.description}</Text>
        </div>
        <div className="w-[50%]">
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Subtotal</p>
            <p>{formatCurrency(totalAmount)}</p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Shipment Fee</p>
            <p> {formatCurrency(data?.shipment_fee)}</p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Discount Pameran</p>
            <p>
              {" "}
              {formatCurrency(data?.auto_discount_nominal)}{" "}
              {`(${data?.auto_discount}%)`}
            </p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Additional Discount</p>
            <p> {formatCurrency(data?.additional_discount)}</p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Voucher</p>
            <p className="text-[#228B22]">
              {!!data?.voucher_id
                ? `${data?.voucher_code} (${
                    data?.voucher_type === "percentage"
                      ? `${data?.voucher_value}%`
                      : `${formatCurrency(data?.voucher_value)}`
                  })`
                : "0"}
            </p>
          </div>
          <div className="bg-white flex-row flex border-b-2 pb-2 mt-5 justify-between">
            <p>Total</p>
            <p>{formatCurrency(data?.price)}</p>
          </div>
        </div>
      </div>
      {data?.whatsapp_url === "" ? (
        <ConvertQuotationModal data={data} />
      ) : (
        <PrintSalesOrder data={data} />
      )}
    </div>
  );
}

export default InvoiceDetailScreen;
