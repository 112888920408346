import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useImportMutation from "../../api/hooks/receiveOrder/useImportMutation";
import { useAxios } from "../../api/hooks/useApi";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { DashboardContainer } from "../atoms/dashboardContainer";
// const validationSchema = Yup.object().shape({
//   name: Yup.string()
//     .required("this field is required")
//     .min(5, "name must be more than 5 characters"),
//   description: Yup.string().required("this field is required"),
// });

function FormImport({ refetch, onClose }: any) {
  const api = useAxios();
  const { mutateAsync } = useImportMutation();
  // const initialValues = {
  //   name: "",
  //   description: "",
  //   date: new Date(),
  // };

  const initialValues = {
    date: new Date(),
  };
  const [file, setFile] = useState<any>();
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
  };
  const handleSubmit = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append(
      "receive_datetime",
      moment(values.selectedDate).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("file", file);
    return api
      .post(`receive-orders`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast("Receive order berhasil dibuat");
        refetch();
        onClose();
      })
      .catch((err) => {
        toast.warn(`${err?.axiosError?.response?.data?.message}`);
        console.log(err);
      })
      .finally(() => {
        console.log(formData);
      });
  };
  return (
    <DashboardContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        // validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            {/* <FormControl isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Input name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.description && touched.description)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                Description
              </FormLabel>
              <Field
                as={Input}
                type="text"
                name="description"
                placeholder="Input description here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl> */}

            <Field name="selectedDate" placeholder="ce">
              {({ field, form }: any) => (
                <Box my={"1rem"}>
                  <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                    Date
                  </FormLabel>
                  <DatePicker
                    {...field}
                    border
                    selected={field.value}
                    showIcon
                    showPopperArrow={false}
                    onChange={(date) => form.setFieldValue(field.name, date)}
                    placeholderText="Select a date"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Box>
              )}
            </Field>
            <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
              Upload file
            </FormLabel>
            <input type="file" onChange={handleFileInputChange} />
            <Button
              type="submit"
              size={"lg"}
              bg={COLOR_PRIMARY}
              color="#fff"
              mt="2rem"
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormImport;
