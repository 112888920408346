import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import useCreateSOMutation from "../../api/hooks/salesOrder/useCreateSOMutation";
import { useAxios } from "../../api/hooks/useApi";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import CounterComponent from "../../components/molecules/counter";
import Header from "../../components/molecules/header";
import { SelectCustomerList } from "../../components/molecules/selectCustomerList";
import { SelectProductListTest } from "../../components/molecules/selectProductListTest";
import { SelectWarehouseList } from "../../components/molecules/selectWarehouseList";

function NewShipment() {
  const [soNumber, setSONumber] = useState("");
  const [delivery, setDelivery] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(null);
  const [shipmentDate, setShipmentDate] = useState<any>();
  const [transactionDate, setTransactionDate] = useState<any>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [warehouse, setWarehouse] = useState<any>();
  const [items, setItems] = useState<any>([]);
  const [nextId, setNextId] = useState(1);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [notes, setNotes] = useState<string>(
    "#Barang yang sudah dibeli tidak dapat dikembalikan, Terimakasih"
  );
  const [showSelectProduct, setShowSelectProduct] = useState(false);

  const toggleSelectProduct = () => {
    setShowSelectProduct(!showSelectProduct);
  };
  const calculateValue = (percentage: number, total: number) => {
    return (percentage / 100) * total;
  };
  const handleQtyChange = (event: any, index: number) => {
    console.log(event, "aa");
    const newQty = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = [...prevItems];
      updatedDiscounts[index] = { ...updatedDiscounts[index], qty: newQty };
      return updatedDiscounts;
    });
  };
  const handleCountChange = (newCountValue: number, index: any): void => {
    handleQtyChange(newCountValue, index);
  };
  const handleDiscountChange = (event: any, index: number) => {
    const newDiscount = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = prevItems.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            product_unit: {
              ...item.product_unit,
              discount: newDiscount || 0,
            },
          };
        }
        return item;
      });
      return updatedDiscounts;
    });
  };
  const handleUnitPrice = (event: any, index: number) => {
    const newPrice = event === "" ? undefined : parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = prevItems.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            product_unit: {
              ...item.product_unit,
              price:
                typeof newPrice === "number"
                  ? newPrice
                  : item.product_unit.price,
            },
          };
        }
        return item;
      });
      return updatedDiscounts;
    });
  };

  const handleCheckboxChange = (event: any, index: number) => {
    setItems((prevItems: any) => {
      const updatedPpn = [...prevItems];
      updatedPpn[index] = {
        ...updatedPpn[index],
        ppn: event,
      };
      return updatedPpn;
    });
  };
  const handleCheckboxChangePack = (event: any, index: number) => {
    setCheckedItems((prevCheckedItems: any) => {
      const updatedCheckedItems = [...prevCheckedItems];
      updatedCheckedItems[index] = event;
      return updatedCheckedItems;
    });
  };

  const handleAddItem = async (value: any) => {
    await setItems((prevItems: any): any => [...prevItems, value]);
    setShowSelectProduct(false);
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems: any[]) =>
      prevItems.filter((item: any) => item.id !== itemId)
    );

    if (items.length === 1) {
      setNextId(1); // Reset nextId to 1 when all items are deleted
    }
  };

  const handleDragEnd = (e: any) => {
    console.log(e);
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);
  };
  const isButtonDisabled = () => {
    return (
      !shipmentDate ||
      !transactionDate ||
      !selectedCustomer ||
      !warehouse ||
      items.length === 0
    );
  };
  const { mutateAsync } = useCreateSOMutation();
  const format = (val: string) => `Rp ` + val;
  const parse = (val: string) => val.replace(/^\$/, "");
  const handleSubmit = (values: any) => {
    mutateAsync({
      invoice_no: soNumber,
      shipment_fee: delivery,
      description: notes,
      resellerId: selectedCustomer?.value,
      warehouseId: warehouse.value,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.value,
        qty: e?.qty || 1,
        unit_price: e?.product_unit?.price || 0,
        total_price:
          (e?.product_unit?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.product_unit?.discount !== null &&
              e?.product_unit?.discount !== undefined
              ? e.product_unit.discount
              : e?.discount || 0,
            (e?.product_unit?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.product_unit?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.product_unit?.discount !== null &&
                      e?.product_unit?.discount !== undefined
                      ? e.product_unit.discount
                      : e?.discount || 0,
                    (e?.product_unit?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),

        discount:
          e?.product_unit?.discount === undefined
            ? e?.discount
            : e?.product_unit?.discount,
        tax: !!e?.ppn ? e.ppn : false,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
    });
  };
  let totalAmount = 0;
  let subTotal = 0;
  return (
    <div className="w-full">
      <Header headerName={"New Sales Order"} back={true} navName={"/sales"} />
      <Box p={5}>
        <Text fontWeight={"medium"}>SO Number</Text>
        <Input
          placeholder="PAS/SO/01/23/01"
          mt={"0.5em"}
          bg={"#fff"}
          value={soNumber}
          onChange={(e) => setSONumber(e.target.value)}
        />
      </Box>
      <Box p={5}>
        <SelectCustomerList
          type="reseller"
          selected={selectedCustomer}
          setSelected={setSelectedCustomer}
        />
      </Box>

      <Box mx={5}>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Select Warehouse
        </h3>
        <SelectWarehouseList
          selectedWarehouse={warehouse}
          setSelectedWarehouse={setWarehouse}
        />
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr>
              <th />
              <th className="w-80">Product Name</th>
              <th>Unit</th>
              <th>Packaging</th>
              <th>PPN 11%</th>
              <th>Quantity</th>
              {/* <th>Discount</th> */}
              <th>Unit Price</th>
              <th>Sub Price</th>
              <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="dropable">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {items?.map((item: any, index: any) => {
                  const productUnitPrice = item?.product_unit?.price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue + ppnValue;
                  const total = totalValue - discountValue;
                  subTotal += totalValue;
                  totalAmount += total;
                  return (
                    <Draggable
                      key={item.label}
                      draggableId={item.label}
                      index={index}
                    >
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td {...provider.dragHandleProps}> = </td>
                          <td>
                            <Text>{item?.label}</Text>
                          </td>
                          <td>
                            <Text>{item?.uom}</Text>
                          </td>
                          <td>
                            <Box>
                              <Checkbox
                                isChecked={checkedItems[index]}
                                onChange={(e) =>
                                  handleCheckboxChangePack(
                                    e.target.checked,
                                    index
                                  )
                                }
                                colorScheme="green"
                                size={"lg"}
                                bg={"white"}
                                defaultChecked={false}
                                isDisabled={
                                  item.packaging_id === null ? true : false
                                }
                              />
                            </Box>
                          </td>
                          <td>
                            <Box>
                              <Checkbox
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    event.target.checked,
                                    index
                                  )
                                }
                                colorScheme="green"
                                size={"lg"}
                                bg={"white"}
                                defaultChecked={false}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box display="flex">
                              <CounterComponent
                                onCountChange={(e) =>
                                  handleCountChange(e, index)
                                }
                                stockCount={item.stocks_count}
                                value={!!item?.qty ? item?.qty : 0}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="black"
                                  fontSize="1em"
                                  children="Rp."
                                />
                                <Input
                                  defaultValue={
                                    item?.product_unit?.price !== undefined
                                      ? item.product_unit.price.toString()
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUnitPrice(e.target.value, index)
                                  }
                                  bg="white"
                                  type="number"
                                  inputMode="numeric"
                                />
                                <InputRightElement>
                                  <HistoryPrice
                                    resellerId={selectedCustomer?.value}
                                    productId={item?.value}
                                    handleUnitPrice={handleUnitPrice}
                                    index={index}
                                  />
                                </InputRightElement>
                              </InputGroup>
                            </Box>
                          </td>
                          <td>
                            <Text>{formatCurrency(totalValue)}</Text>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <Button
                                variant={"link"}
                                borderBottom={1}
                                borderBottomColor={"#01338C"}
                                borderBottomWidth={1}
                                color={"#01338C"}
                                onClick={() => handleRemoveItem(item.id)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {showSelectProduct ? (
                  <tr>
                    <td></td>
                    <td>
                      <SelectProductListTest
                        customerId={selectedCustomer?.value}
                        setSelectedProduct={(e: any) => handleAddItem(e)}
                        selectedProducts={items}
                        warehouse_id={warehouse.value}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        disabled={!selectedCustomer || !warehouse}
                        onClick={toggleSelectProduct}
                      >
                        <AddIcon boxSize={6} />
                      </button>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(subTotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Box textAlign="center">
                      {/* <NumberInput onChange={(valueString) => setDelivery(parse(valueString))}
                        value={format(delivery)}>
                        <NumberInputField />
                      </NumberInput> */}
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          fontSize="1em"
                          children="Rp."
                          alignSelf={"center"}
                        />

                        <Input
                          placeholder="Delivery Price"
                          bg={"#fff"}
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                          type="number"
                        />
                      </InputGroup>
                    </Box>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Additional Discount</Text>
                  </td>
                  <td>
                    <NumberInput maxW={20} defaultValue={0} min={0} max={100}>
                      <InputGroup>
                        <InputRightElement color="black" children="%" />
                        <NumberInputField
                          onChange={(e) => setDiscount(e.target.value)}
                          value={discount}
                        />
                      </InputGroup>
                    </NumberInput>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(totalAmount + parseFloat(delivery))}
                    </Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
      {/* <Box mx={5} mt={5}>
        <SelectProductList
          customerId={selectedCustomer?.value}
          setSelectedProduct={(e: any) => handleAddItem(e)}
          selectedProducts={items}
        />
      </Box> */}
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <Box my={"1rem"}>
          <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
            Shipment Date
          </FormLabel>
          <DatePicker
            border
            selected={shipmentDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setShipmentDate(date)}
            placeholderText="Select a date"
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
      </div>
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <Box my={"1rem"}>
          <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
            Transaction Date
          </FormLabel>
          <DatePicker
            border
            selected={transactionDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setTransactionDate(date)}
            placeholderText="Select a date"
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
      </div>
      <div style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Notes
        </h3>
        <Textarea
          onChange={(e) => setNotes(e.target.value)}
          bg={"white"}
          placeholder="Input Notes"
          value={notes}
        />
      </div>

      <Button
        onClick={handleSubmit}
        color="#fff"
        bg={"#01338C"}
        w="10rem"
        mt={"1rem"}
        ml="1.5rem"
        isDisabled={isButtonDisabled()}
      >
        Add
      </Button>
    </div>
  );
}

export const HistoryPrice = ({
  handleUnitPrice,
  index,
  productId,
  resellerId,
}: any) => {
  const [price, setPrice] = useState<any>();
  const api = useAxios();
  const getRecentData = () => {
    api
      .get(`product-units/${productId}/user-price/${resellerId}`)
      .then((res) => {
        setPrice(res.data.data);
      });
  };
  return (
    <Menu>
      <MenuButton
        onClick={() => getRecentData()}
        transition="all 0.2s"
        _hover={{ bg: "gray.400" }}
        _expanded={{ bg: "blue.400" }}
      >
        <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        {price?.length === 0 ? (
          <Text>No Data</Text>
        ) : (
          price?.map((e: any) => (
            <MenuItem onClick={() => handleUnitPrice(e.unit_price, index)}>
              <Box borderBottomWidth={0.5}>
                <Text>{formatCurrency(e.unit_price)}</Text>
                <Text color={"grey"}>
                  used in {moment(e.created_at).format("D MMMM YYYY")}
                </Text>
              </Box>
            </MenuItem>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

export default NewShipment;
