/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import usePrintRecordMutation from "../../api/hooks/stocks/usePrintRecordMutation";
import PrintableContent from "./printContent";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";

export default function PrintSelected({
  data,
  isActive,
  nameProduct,
  props,
  refetch,
  setSelectedRows,
}: any) {
  const printableContentRef = useRef<any>(null);
  const { mutateAsync } = usePrintRecordMutation();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState<number>();
  const printHandlerSmall = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      margin: 230px 55px 0px 48px !important;
    }    
    @media print {    
      html, body {
        height: initial !important;
        margin: 0px 19px 0px 25px !important;      
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        margin: 0px 19px 0px 25px !important;       
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          props?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: props?.id,
        stock_ids: data?.map((e: { id: any }) => e.id),
      }).then(() => {
        refetch();
        setSelectedRows([]);
        onClose();
      });
    },
  });

  // Print Barcode Gede
  const printHandlerBig = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      // margin: 665px 120px 0px 220px !important;
      margin: 283px 50px 0px 110px !important;
    }
    @media print {
      html, body {
        height: initial !important;
        // margin: 0px 19px 0px 25px !important;
        // padding: 0 !important;
        // overflow: hidden;
      }
      .page-break {
        // margin: 0px 19px 0px 25px !important;
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          props?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: props?.id,
        stock_ids: data?.map((e: { id: any }) => e.id),
      }).then(() => {
        refetch();
        setSelectedRows([]);
        onClose();
      });
    },
  });
  const printHandlerBigTest = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
    }
    @media print {
      html, body {
        height: initial !important;
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          props?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: props?.id,
        stock_ids: data?.map((e: { id: any }) => e.id),
      }).then(() => {
        refetch();
        setSelectedRows([]);
        onClose();
      });
    },
  });
  return (
    <>
      <div className="print-content">
        <PrintableContent
          ref={printableContentRef}
          data={data}
          multiple
          name={nameProduct}
          type={type}
        />
      </div>
      <Button
        isActive={isActive}
        w={"10rem"}
        ml={10}
        bg={"#F1C40F"}
        color={"#fff"}
        onClick={onOpen}
        isDisabled={!dataPermission?.stock_access?.stock_print}
      >
        Print Selected
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>QR Size</ModalHeader>
          <ModalCloseButton />
          <ModalBody justifyContent={"center"} alignSelf={"center"}>
            {/* <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(1);
                printHandlerSmall();
              }}
            >
              QR Kecil
            </Button> */}
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(2);
                printHandlerBig();
              }}
            >
              QR Besar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(2);
                printHandlerBigTest();
              }}
            >
              QR Besar Test
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
