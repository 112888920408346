/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useDeliveryOrder from "../../api/hooks/deliveryOrder/useDelveryOrder";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import { DeliveryOrder } from "../../components/molecules/modal/DeliveryOrder";
import Pagination from "../../components/molecules/pagination";
import TableDeliveryOrder from "../../components/molecules/tables/tableDeliveryOrder";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";
import NoPermission from "../../components/molecules/noPermission";
import moment from "moment";

function Delivery() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [start, setStart] = useState<any>(
    moment(new Date()).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState<any>(
    moment(new Date()).endOf("month").format("YYYY-MM-DD")
  );
  const [dataPermission] = useRecoilState<any>(permissionState);
  const {
    queries: [{ data: dataTable }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([
    useDeliveryOrder({
      page: currentPage,
      "filter[start_date]": start,
      "filter[end_date]": end,
      include: "salesOrder",
      sort: "-invoice_no",
      "filter[invoice_no]": search,
    }),
  ] as const);
  const countData = dataTable?.pages[0].meta.total;
  const dataList = dataTable?.pages.flatMap((page) => page.data);
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  return (
    <div>
      {!dataPermission?.delivery_order_access?.delivery_order_access ? (
        <NoPermission />
      ) : (
        <>
          {!!isLoading ? (
            <Loading />
          ) : (
            <>
              {MobileDevice ? (
                <div>
                  <Header headerName={"Delivery Order"} back={false} />
                  <div
                    className="row space-x-11"
                    style={{ marginBottom: "2rem", display: "flex" }}
                  >
                    <Input
                      placeholder="Search DO Number"
                      width="90%"
                      bg={"white"}
                      size="sm"
                      onChange={(event) => setSearch(event.target.value)}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="date"
                        id="start"
                        name="start"
                        value={start}
                        className="form-control"
                        placeholder="Start Month"
                        onChange={(start) => setStart(start?.target?.value)}
                      />
                      <div
                        className="input-group-addon"
                        style={{ margin: "0 10px" }}
                      >
                        to
                      </div>
                      <input
                        type="date"
                        id="end"
                        name="end"
                        value={end}
                        className="form-control"
                        placeholder="End Month"
                        onChange={(end) => setEnd(end?.target?.value)}
                        style={{ margin: "0" }}
                      />
                      <Button ml="1rem" onClick={() => refetch()}>
                        Filter
                      </Button>
                    </div>
                    <DeliveryOrder refetch={refetch} />
                  </div>
                </div>
              ) : null}
              <TableDeliveryOrder data={dataList} refetch={refetch} />
            </>
          )}
          {MobileDevice ? (
            <Pagination
              pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
              handlePagination={handlePagination}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default Delivery;
