import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import useSelectConvertMutation from "../../../api/hooks/uoms/useSelectConvertMutation";
import useUomsList from "../../../api/hooks/uoms/useUomsList";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import { useMediaQuery } from "react-responsive";

export function AdjustUnit({ data, refetch, selectedItem, isSelected }: any) {
  const [selected, setSelected] = useState<any>({});
  const [qty, setQty] = useState<any>();
  const { mutateAsync } = useSelectConvertMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });

  const handleSubmit = () => {
    if (MobileDevice) {
      mutateAsync({
        receiveId: data?.receive_order?.id,
        receiveOrderDetailId: data?.id,
        unitType: selected?.id || 1,
        qty: qty,
      }).then(() => {
        refetch();
        onClose();
      });
    } else {
      if (selectedItem) {
        mutateAsync({
          receiveId: selectedItem?.receive_order?.id,
          receiveOrderDetailId: selectedItem?.id,
          unitType: selected?.id || 1,
          qty: qty,
        }).then(() => {
          refetch();
          onClose();
        });
      }
    }
  };
  console.log(data, "haha");
  return (
    <>
      {MobileDevice ? (
        <Button
          color="white"
          backgroundColor={COLOR_PRIMARY}
          w={"8rem"}
          ml={3}
          onClick={() => {
            onOpen();
          }}
        >
          Adjust Unit
        </Button>
      ) : (
        isSelected && (
          <Button
            color="white"
            backgroundColor={COLOR_PRIMARY}
            w={"8rem"}
            ml={3}
            onClick={() => {
              onOpen();
            }}
          >
            Adjust Unit
          </Button>
        )
      )}
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conversion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"space-around"}>
              <Box>
                <Flex>
                  <Box>
                    <Text textAlign="center">Before</Text>
                    <Input
                      disabled={true}
                      placeholder={data?.qty}
                      size={"sm"}
                    />
                  </Box>
                  <Button marginTop={5}>{data?.item_unit}</Button>
                </Flex>
              </Box>
              <Box>
                <Flex>
                  <Box>
                    <Text textAlign="center">After</Text>
                    <Input
                      placeholder="Input value"
                      size={"sm"}
                      onChange={(val) => setQty(val.target.value)}
                    />
                  </Box>
                  <Button marginTop={5}>{data?.product_unit?.uom?.name}</Button>
                  {/* <UomPicker selected={selected} setSelected={setSelected} /> */}
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              bgColor={COLOR_PRIMARY}
              color="white"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const UomPicker = ({ selected, setSelected }: any) => {
  const { data } = useUomsList({});
  const dataUoms = data?.pages.flatMap((page) => page.data);
  return (
    <Menu>
      <MenuButton
        marginTop={5}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        disabled={true}
      >
        {/* {!selected ? "type" : selected?.name || "type" } */}
        Pack
      </MenuButton>
      {/* <MenuList>
        {dataUoms?.map((item) => (
          <MenuItem
            onClick={() => {
              setSelected(item);
            }}
          >
            {item?.name}
          </MenuItem>
        ))}
      </MenuList> */}
    </Menu>
  );
};
