import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useState } from "react";
import "../../../components/molecules/tables/tableStyle.css";
import Header from "../../../components/molecules/header";
import moment from "moment";
import { VerifyOrderModal } from "../../../components/molecules/modal/modalVerify";
import { AdjustUnit } from "../../../components/molecules/modal/modalAdjust";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import useVerifyOrder from "../../../api/hooks/receiveOrder/useVerifyOrder";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import useSelectConvertMutation from "../../../api/hooks/uoms/useSelectConvertMutation";

function MobileReceiveOrderDetail({
  data,
  header,
  date,
  SONumber,
  supplier,
  item,
  refetch,
  printId,
  exportXmlId,
}: any) {
  const [name, setName] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);
  const { mutateAsync } = useVerifyOrder();

  const handleSelect = (item: any) => {
    if (isSelectEnabled) {
      if (selectedItem === item) {
        // Deselect the item if it's already selected
        setSelectedItem(null);
      } else {
        setSelectedItem(item);
      }
    } else {
      setSelectedItem(null);
    }
  };
  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);
  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setName(searchTerm);
    handleSearch(searchTerm);
  };
  const toggleSelectEnabled = () => {
    setIsSelectEnabled((prevState) => !prevState);
  };

  const handleSubmit = () => {
    if (selectedItem) {
      mutateAsync({
        receiveId: selectedItem?.receive_order?.id,
        receiveOrderDetailId: selectedItem?.product_unit?.id,
        type: 1,
      }).then(() => {
        refetch();
        onClose();
      });
    }
  };

  const handleUnSubmit = () => {
    if (selectedItem) {
      mutateAsync({
        receiveId: selectedItem?.receive_order?.id,
        receiveOrderDetailId: selectedItem?.product_unit?.id,
        type: 0,
      }).then(() => {
        refetch();
      });
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ModalVerifs = () => {
    return (
      <>
        {selectedItem?.is_verified === true ? (
          <Button
            backgroundColor="#26de81"
            onClick={handleUnSubmit}
            color="white"
            w={"8rem"}
          >
            Verified
          </Button>
        ) : (
          <Button
            backgroundColor="#F1C40F"
            color="white"
            w={"8rem"}
            onClick={() => {
              onOpen();
            }}
          >
            Verify Order
          </Button>
        )}
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Verify Order</ModalHeader>
            <ModalCloseButton />
            <ModalBody>are you sure you want to verify this order?</ModalBody>
            <ModalFooter>
              <Button onClick={onClose} mx={5}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                bgColor={COLOR_PRIMARY}
                color="white"
              >
                Verify Order
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  console.log(data, "check data");
  return (
    <div style={{ background: "#fff" }}>
      <div>
        <div className="py-5 pr-5">
          <Header
            headerName={header}
            back={true}
            navName={"/ro"}
            exportBtn={false}
          />
          <div>
            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Date</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {moment(date).format("D MMMM YYYY")}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Order Number</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {SONumber}
                </h1>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Supplier</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {supplier}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Items</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {item}
                </h1>
              </div>
            </div>

            <Flex>
              <Button
                variant={"link"}
                ml={"1.5rem"}
                mb={"1rem"}
                color={"#000"}
                onClick={toggleSelectEnabled}
              >
                {isSelectEnabled ? "Unselect SKU" : "Select SKU"}
              </Button>
              <Spacer />
              {isSelectEnabled && (
                <Text>
                  <InfoOutlineIcon h={4} w={4} mb={1} /> Click Box to Select SKU
                </Text>
              )}
            </Flex>

            {data?.map((item: any) => (
              <div
                key={item?.id}
                style={{
                  background:
                    selectedItem === item ? "rgba(46,121,255, 0.5)" : "#fff",
                  borderRadius: 10,
                  width: "95%",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                onClick={() => handleSelect(item)}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      padding: "1rem",
                    }}
                  >
                    {/* <h3 style={{ fontWeight: "bold" }}>JPDF01 (hard code)</h3> */}
                    <h1 style={{ fontWeight: "bold" }}>
                      {item?.product_unit?.code}
                    </h1>
                    <h1>{item?.product_unit?.description}</h1>
                    <h1>
                      {item?.product_unit?.product?.product_category?.name} -{" "}
                      {item?.product_unit?.product?.product_brand?.name}
                    </h1>
                    <h1>
                      @{!!item?.adjust_qty ? item?.adjust_qty : item?.qty}{" "}
                      {item?.adjust_qty > 0
                        ? item?.product_unit?.uom?.name
                        : item?.item_unit}
                    </h1>
                  </div>
                  <div
                    style={{
                      padding: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    {selectedItem?.is_verified === true ? null : (
                      <AdjustUnit
                        refetch={refetch}
                        selectedItem={selectedItem}
                        isSelected={isSelectEnabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isSelectEnabled && (
              <div
                style={{
                  position: "sticky",
                  bottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalVerifs />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileReceiveOrderDetail;
