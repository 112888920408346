import React from "react";
import { Button, Input, Select } from "@chakra-ui/react";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_YELLOW } from "../../../Helper/theme";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import { debounce } from "lodash";

function TableReturn({ data, header, col1, col2, col3, col4, col5 }: any) {
  const nav = useNavigate();
  const [shipNum, setShipNum] = useState("");

  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setShipNum(searchTerm);
    handleSearch(searchTerm);
  };
  return (
    <div>
      <div>
        <Header headerName={header} back={false} />
        <div
          className="row space-x-11"
          style={{ marginBottom: "2rem", display: "flex" }}
        >
          <Input
            placeholder="Search Return Number"
            width="50rem"
            value={shipNum}
            onChange={handleInputChange}
            size={"lg"}
          />
          <Select placeholder="Customer" size={"lg"} w={"15rem"}>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <Button
            bg={COLOR_FIGMA_LIGHT_BLUE}
            color="white"
            size={"lg"}
            onClick={() => nav("/return/new-return")}
          >
            + Return
          </Button>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Return ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              DO Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Reseller ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              City
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Items
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Delivery Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.no}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.rn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.cn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.cn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.cn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.cn}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <Button
                  onClick={() => nav("/return/detail")}
                  size="sm"
                  bg={COLOR_YELLOW}
                  color="#fff"
                >
                  See Detail
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableReturn;
