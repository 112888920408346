import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router";
import useConvertQuotationMutation from "../../api/hooks/invoice/useConvertQuotationMutation";
import useEditInvoiceMutation from "../../api/hooks/invoice/useEditInvoiceMutation";
import usePreviewConvertQuotationMutation from "../../api/hooks/invoice/usePreviewConvertMutation";
import usePreviewEditInvoiceMutation from "../../api/hooks/invoice/usePreviewEditInvoiceMutation";
import { useAxios } from "../../api/hooks/useApi";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import CounterComponent from "../../components/molecules/counter";
import Header from "../../components/molecules/header";
import { SelectCustomerList } from "../../components/molecules/selectCustomerList";
import { SelectProductUnitEditList } from "../../components/molecules/selectProductUnitEdit";
import { SelectWarehouseList } from "../../components/molecules/selectWarehouseList";

function EditInvoiceScreen() {
  const location = useLocation();
  const params = location?.state;
  const [soNumber, setSONumber] = useState("");
  const [delivery, setDelivery] = useState<any>(params?.shipment_fee);
  const [discount, setDiscount] = useState<any>(
    params?.additional_discount_percentage
  );
  const [type, setType] = useState<any>({
    value: params.type,
    label: params.type,
  });
  const [existingCustomer, setExistingCustomer] = useState(true);
  const [voucher, setVoucher] = useState<string>(params?.voucher_code);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [shipmentDate, setShipmentDate] = useState<any>(
    new Date(params.shipment_estimation_datetime)
  );
  const [transactionDate, setTransactionDate] = useState<any>(
    new Date(params.transaction_date)
  );
  const [selectedCustomer, setSelectedCustomer] = useState<any>({
    label: `${params?.reseller?.name} - ${params?.reseller?.phone}`,
    value: params?.reseller?.id,
  });
  const [warehouse, setWarehouse] = useState<any>({
    label: params?.warehouse?.name,
    value: params?.warehouse?.id,
  });
  const [items, setItems] = useState<any>(
    params?.details?.map((e: any) => ({
      discount: e.discount,
      id: e.product_unit.id,
      label: e.product_unit.name,
      packaging_id: null,
      ppn: e.product_unit.is_ppn,
      price: e.product_unit.price,
      price_discount: 0,
      product_unit: {
        price: e.unit_price,
      },
      qty: e.qty,
      stocks_count: 9999,
      uom: e.product_unit.uom.name,
      value: e.qty,
      warehouse_id: warehouse.value,
    }))
  );
  const [nextId, setNextId] = useState(1);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [notes, setNotes] = useState<string>(params.description);
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenInvoice,
    onOpen: onOpenInvoice,
    onClose: onCloseInvoice,
  } = useDisclosure();
  const toggleSelectProduct = () => {
    setShowSelectProduct(!showSelectProduct);
  };
  const calculateValue = (percentage: number, total: number) => {
    return (percentage / 100) * total;
  };
  const handleQtyChange = (event: any, index: number) => {
    const newQty = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = [...prevItems];
      updatedDiscounts[index] = { ...updatedDiscounts[index], qty: newQty };
      return updatedDiscounts;
    });
  };
  const handleCountChange = (newCountValue: number, index: any): void => {
    handleQtyChange(newCountValue, index);
  };
  const handleUnitPrice = (event: any, index: number) => {
    const newPrice = event === "" ? undefined : parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = prevItems.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            product_unit: {
              ...item.product_unit,
              price:
                typeof newPrice === "number"
                  ? newPrice
                  : item.product_unit.price,
            },
          };
        }
        return item;
      });
      return updatedDiscounts;
    });
  };

  const handleCheckboxChange = (event: any, index: number) => {
    setItems((prevItems: any) => {
      const updatedPpn = [...prevItems];
      updatedPpn[index] = {
        ...updatedPpn[index],
        ppn: event,
      };
      return updatedPpn;
    });
  };

  const handleAddItem = async (value: any) => {
    await setItems((prevItems: any): any => [...prevItems, value]);
    setShowSelectProduct(false);
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems: any[]) =>
      prevItems.filter((item: any) => item.id !== itemId)
    );

    if (items.length === 1) {
      setNextId(1); // Reset nextId to 1 when all items are deleted
    }
  };

  const handleDragEnd = (e: any) => {
    console.log(e);
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);
  };
  const isButtonDisabled = () => {
    return (
      !shipmentDate || !transactionDate || !warehouse || items.length === 0
    );
  };
  const { mutateAsync } = useConvertQuotationMutation();
  const { mutateAsync: mutateEditInvoice } = useEditInvoiceMutation();
  const { mutateAsync: getPreview, data } =
    usePreviewConvertQuotationMutation();
  const { mutateAsync: getPreviewInvoice, data: previewInvoice } =
    usePreviewEditInvoiceMutation();
  const handlePreviewInvoice = () => {
    getPreviewInvoice({
      shipment_fee: delivery,
      description: notes,
      warehouseId: warehouse.value,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      is_additional_discount_percentage: "percentage",
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.id,
        qty: e?.qty || 1,
        unit_price: e?.product_unit?.price || 0,
        total_price:
          (e?.product_unit?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.product_unit?.discount !== null &&
              e?.product_unit?.discount !== undefined
              ? e.product_unit.discount
              : e?.discount || 0,
            (e?.product_unit?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.product_unit?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.product_unit?.discount !== null &&
                      e?.product_unit?.discount !== undefined
                      ? e.product_unit.discount
                      : e?.discount || 0,
                    (e?.product_unit?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount:
          e?.product_unit?.discount === undefined
            ? e?.discount
            : e?.product_unit?.discount,
        tax: e.ppn,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      orderId: params?.id,
      invoice_no: params?.invoice_no,
    }).then(() => onOpenInvoice());
  };
  const handlePreview = () => {
    getPreview({
      shipment_fee: delivery,
      description: notes,
      warehouseId: warehouse.value,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      is_additional_discount_percentage: "percentage",
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.id,
        qty: e?.qty || 1,
        unit_price: e?.product_unit?.price || 0,
        total_price:
          (e?.product_unit?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.product_unit?.discount !== null &&
              e?.product_unit?.discount !== undefined
              ? e.product_unit.discount
              : e?.discount || 0,
            (e?.product_unit?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.product_unit?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.product_unit?.discount !== null &&
                      e?.product_unit?.discount !== undefined
                      ? e.product_unit.discount
                      : e?.discount || 0,
                    (e?.product_unit?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount:
          e?.product_unit?.discount === undefined
            ? e?.discount
            : e?.product_unit?.discount,
        tax: e.ppn,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      orderId: params?.id,
    }).then(() => onOpen());
  };
  const handleSubmitInvoice = () => {
    mutateEditInvoice({
      shipment_fee: delivery,
      description: notes,
      warehouseId: warehouse.value,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.id,
        qty: e?.qty || 1,
        unit_price: e?.product_unit?.price || 0,
        total_price:
          (e?.product_unit?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.product_unit?.discount !== null &&
              e?.product_unit?.discount !== undefined
              ? e.product_unit.discount
              : e?.discount || 0,
            (e?.product_unit?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.product_unit?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.product_unit?.discount !== null &&
                      e?.product_unit?.discount !== undefined
                      ? e.product_unit.discount
                      : e?.discount || 0,
                    (e?.product_unit?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount:
          e?.product_unit?.discount === undefined
            ? e?.discount
            : e?.product_unit?.discount,
        tax: e.ppn,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      orderId: params?.id,
    });
  };
  console.log(items, "items");
  const handleSubmit = () => {
    mutateAsync({
      shipment_fee: delivery,
      description: notes,
      warehouseId: warehouse.value,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.id,
        qty: e?.qty || 1,
        unit_price: e?.product_unit?.price || 0,
        total_price:
          (e?.product_unit?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.product_unit?.discount !== null &&
              e?.product_unit?.discount !== undefined
              ? e.product_unit.discount
              : e?.discount || 0,
            (e?.product_unit?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.product_unit?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.product_unit?.discount !== null &&
                      e?.product_unit?.discount !== undefined
                      ? e.product_unit.discount
                      : e?.discount || 0,
                    (e?.product_unit?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount:
          e?.product_unit?.discount === undefined
            ? e?.discount
            : e?.product_unit?.discount,
        tax: e.ppn,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      orderId: params?.id,
    });
  };
  let totalAmount = 0;
  let totalConvert = 0;
  let totalInvoice = 0;
  let subTotal = 0;
  let previewConvertsubTotal = 0;
  let previewInvoiceSubtotal = 0;
  const api = useAxios();
  // useEffect(() => {
  //   if (warehouse) {
  //     api
  //       .get(`invoices/get-invoice-no?warehouse_id=${warehouse?.value}`)
  //       .then((res) => setSONumber(res.data));
  //   }
  // }, [warehouse]);
  return (
    <div className="w-full">
      <Header headerName={"Edit Order"} back={true} navName={"/sales"} />

      <Box mb={7}>
        <Text fontWeight={"medium"} mb={"0.5em"}>
          Select Warehouse
        </Text>
        <SelectWarehouseList
          selectedWarehouse={warehouse}
          setSelectedWarehouse={setWarehouse}
        />
      </Box>
      {/* <Box mb={7} borderBottomWidth={1} pb={8}>
        <Text mb={"0.5em"} fontWeight={"medium"}>
          SO Number
        </Text>
        <Input
          placeholder="PAS/SO/01/23/01"
          bg={"#fff"}
          value={soNumber}
          onChange={(e) => setSONumber(e.target.value)}
        />
      </Box> */}
      <Box mb={7}>
        <Text mb={"0.5em"} fontWeight={"medium"}>
          Customer Name
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input customer name"
          bg={"#fff"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box mb={7}>
        <Text mb={"0.5em"} fontWeight={"medium"}>
          Phone
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input customer phone number"
          bg={"#fff"}
          type="number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Box>
      <Box mb={7}>
        <Text mb={"0.5em"} fontWeight={"medium"}>
          Address
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input address customer ( optional )"
          bg={"#fff"}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <div className="mt-3">
          <Checkbox
            isChecked={existingCustomer}
            onChange={() => setExistingCustomer(!existingCustomer)}
          >
            <Text>Use existing customer ?</Text>
          </Checkbox>
        </div>
      </Box>
      {existingCustomer && (
        <Box mb={7}>
          <SelectCustomerList
            type="customer_event"
            selected={selectedCustomer}
            setSelected={setSelectedCustomer}
          />
        </Box>
      )}
      <Text fontWeight={"medium"} mb={"0.5em"}>
        Table Product
      </Text>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table>
          <thead>
            <tr className="bg-gray-200">
              <th />
              <th>
                <Text textAlign={"left"} fontWeight={"medium"}>
                  Product name
                </Text>
              </th>
              <th>
                <Text fontWeight={"medium"}>Unit</Text>
              </th>
              <th>{/* <Text fontWeight={"medium"}>Packaging</Text> */}</th>
              <th>
                <Text fontWeight={"medium"}>PPN </Text>
              </th>
              <th>
                <Text fontWeight={"medium"}>Quantity</Text>
              </th>
              {/* <th>Discount</th> */}
              <th>
                <Text fontWeight={"medium"}>Unit Price</Text>
              </th>
              <th>
                <Text fontWeight={"medium"}>Sub Price</Text>
              </th>
              <th>
                <Text fontWeight={"medium"}>Action</Text>
              </th>
            </tr>
          </thead>
          <Droppable droppableId="dropable">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {items?.map((item: any, index: any) => {
                  const productUnitPrice = item?.product_unit?.price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue + ppnValue;
                  const total = totalValue - discountValue;
                  subTotal += totalValue;
                  totalAmount += total;
                  return (
                    <Draggable
                      key={item.label}
                      draggableId={item.label}
                      index={index}
                    >
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td {...provider.dragHandleProps}> = </td>
                          <td>
                            <Text>{item?.label}</Text>
                          </td>
                          <td>
                            <Text>{item?.uom}</Text>
                          </td>
                          <td></td>
                          <td>
                            <Box>
                              <Checkbox
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    event.target.checked,
                                    index
                                  )
                                }
                                colorScheme="green"
                                size={"lg"}
                                bg={"white"}
                                defaultChecked={item.ppn === 1 ? true : false}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box display="flex">
                              <CounterComponent
                                onCountChange={(e) =>
                                  handleCountChange(e, index)
                                }
                                stockCount={item.stocks_count}
                                value={!!item?.qty ? item?.qty : 0}
                              />
                            </Box>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="black"
                                  fontSize="1em"
                                  children="Rp."
                                />
                                <Input
                                  defaultValue={
                                    item?.product_unit?.price !== undefined
                                      ? item.product_unit.price.toString()
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUnitPrice(e.target.value, index)
                                  }
                                  bg="white"
                                  type="number"
                                  inputMode="numeric"
                                />
                                <InputRightElement>
                                  {/* <HistoryPrice
                                    resellerId={selectedCustomer?.value}
                                    productId={item?.value}
                                    handleUnitPrice={handleUnitPrice}
                                    index={index}
                                  /> */}
                                </InputRightElement>
                              </InputGroup>
                            </Box>
                          </td>
                          <td>
                            <Text>{formatCurrency(totalValue)}</Text>
                          </td>
                          <td>
                            <Box textAlign="center">
                              <Button
                                variant={"link"}
                                borderBottom={1}
                                borderBottomColor={"#01338C"}
                                borderBottomWidth={1}
                                color={"#01338C"}
                                onClick={() => handleRemoveItem(item.id)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {showSelectProduct ? (
                  <tr>
                    <td></td>
                    <td>
                      <SelectProductUnitEditList
                        customerId={selectedCustomer?.value}
                        setSelectedProduct={(e: any) => handleAddItem(e)}
                        selectedProducts={items}
                        warehouse_id={warehouse.value}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        disabled={!warehouse}
                        onClick={toggleSelectProduct}
                      >
                        <AddIcon boxSize={6} />
                      </button>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(subTotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Box textAlign="center">
                      {/* <NumberInput onChange={(valueString) => setDelivery(parse(valueString))}
                        value={format(delivery)}>
                        <NumberInputField />
                      </NumberInput> */}
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children="Rp."
                          alignSelf={"center"}
                        />

                        <Input
                          placeholder="Delivery Price"
                          bg={"#fff"}
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                          type="number"
                        />
                      </InputGroup>
                    </Box>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Additional Discount</Text>
                  </td>
                  <td>
                    <NumberInput defaultValue={0} min={0} max={100}>
                      <InputGroup>
                        <InputRightElement color="black" children="%" />
                        <NumberInputField
                          onChange={(e) => setDiscount(e.target.value)}
                          value={discount}
                        />
                      </InputGroup>
                    </NumberInput>
                    {/* <NumberInputSwitcher /> */}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(totalAmount + parseFloat(delivery))}
                    </Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
      <div style={{ marginTop: "1rem" }}>
        <Box mb={7}>
          <Text mb={"0.5em"} fontWeight={"medium"}>
            Voucher
          </Text>
          <Flex className="justify-between">
            <div className="w-full">
              <Input
                placeholder="Input voucher code"
                bg={"#fff"}
                value={voucher}
                onChange={(item) => setVoucher(item.target.value)}
              />
            </div>
            {/* <Button fontSize={12}>Apply Voucher</Button> */}
          </Flex>
        </Box>
        <Box mb={7}>
          <Text mb={"0.5em"} fontWeight={"medium"}>
            Shipment Date
          </Text>
          <DatePicker
            border
            selected={shipmentDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setShipmentDate(date)}
            placeholderText="Select a date"
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <Box mb={7}>
          <Text mb={"0.5em"} fontWeight={"medium"}>
            Transaction Date
          </Text>

          <DatePicker
            border
            selected={transactionDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setTransactionDate(date)}
            placeholderText="Select a date"
            minDate={moment().toDate()} // Set minimum selectable date to today
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
        <Box mb={7}>
          <Text mb={"0.5em"} fontWeight={"medium"}>
            Order type
          </Text>
          <Select
            isMulti={false}
            name="colors"
            defaultValue={{
              value: "pickup",
              label: "pickup",
            }}
            options={[
              {
                value: "pickup",
                label: "pickup",
              },
              {
                value: "delivery",
                label: "delivery",
              },
              {
                value: "free",
                label: "free",
              },
            ]}
            placeholder="order type"
            closeMenuOnSelect={true}
            value={type}
            onChange={setType}
          />
        </Box>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <Text mb={"0.5em"} fontWeight={"medium"}>
          Notes
        </Text>
        <Textarea
          onChange={(e) => setNotes(e.target.value)}
          bg={"white"}
          placeholder="Input Notes"
          value={notes}
        />
      </div>
      {!params?.invoice_no ? (
        <Button
          my={5}
          onClick={handlePreview}
          color="#fff"
          bg={"#01338C"}
          className=" w-full"
          isDisabled={isButtonDisabled()}
        >
          Preview Edit Order
        </Button>
      ) : (
        <Button
          my={5}
          onClick={handlePreviewInvoice}
          color="#fff"
          bg={"#01338C"}
          className=" w-full"
          isDisabled={isButtonDisabled()}
        >
          Preview Edit Invoice
        </Button>
      )}

      <Modal isOpen={isOpen} size={"5xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <table>
              <thead>
                <tr className="bg-gray-200">
                  <th />
                  <th>
                    <Text textAlign={"left"} fontWeight={"medium"}>
                      Product name
                    </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Unit</Text>
                  </th>
                  <th>{/* <Text fontWeight={"medium"}>Packaging</Text> */}</th>
                  <th>
                    <Text fontWeight={"medium"}>PPN </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Quantity</Text>
                  </th>
                  {/* <th>Discount</th> */}
                  <th>
                    <Text fontWeight={"medium"}>Unit Price</Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Sub Price</Text>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="text-capitalize">
                {items?.map((item: any, index: any) => {
                  const productUnitPrice = item?.product_unit?.price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue + ppnValue;
                  const total = totalValue - discountValue;
                  previewConvertsubTotal += totalValue;
                  totalConvert += total;
                  return (
                    <tr>
                      <td></td>
                      <td>
                        <Text>{item?.label}</Text>
                      </td>
                      <td>
                        <Text>{item?.uom}</Text>
                      </td>
                      <td></td>
                      <td>
                        <Text>{!!item?.ppn ? "true" : "false"}</Text>
                      </td>
                      <td>
                        <Text>{!!item?.qty ? item?.qty : 0}</Text>
                      </td>
                      <td>{formatCurrency(item?.product_unit?.price)}</td>
                      <td>
                        <Text>{formatCurrency(totalValue)}</Text>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(previewConvertsubTotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(data?.shipment_fee)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Discount Pameran</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(data?.auto_discount_nominal)}{" "}
                      {`(${data?.auto_discount}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Voucher Discount</Text>
                  </td>
                  <td>
                    <Text color={"green.400"}>
                      {!!data?.voucher_id
                        ? `${data?.voucher_code} ${formatCurrency(
                            data?.voucher_value_nominal
                          )}(${
                            data?.voucher_type === "percentage"
                              ? `${data?.voucher_value}%`
                              : `${formatCurrency(data?.voucher_value)}`
                          })`
                        : "0"}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Additional Discount</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(data?.additional_discount)}{" "}
                      {`(${data?.additional_discount_percentage}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(data?.price)}</Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              my={5}
              onClick={handleSubmit}
              color="#fff"
              bg={"#01338C"}
              className=" w-full"
              isDisabled={isButtonDisabled()}
            >
              Convert Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenInvoice} size={"5xl"} onClose={onCloseInvoice}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <table>
              <thead>
                <tr className="bg-gray-200">
                  <th />
                  <th>
                    <Text textAlign={"left"} fontWeight={"medium"}>
                      Product name
                    </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Unit</Text>
                  </th>
                  <th>{/* <Text fontWeight={"medium"}>Packaging</Text> */}</th>
                  <th>
                    <Text fontWeight={"medium"}>PPN </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Quantity</Text>
                  </th>
                  {/* <th>Discount</th> */}
                  <th>
                    <Text fontWeight={"medium"}>Unit Price</Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Sub Price</Text>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="text-capitalize">
                {items?.map((item: any, index: any) => {
                  const productUnitPrice = item?.product_unit?.price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue + ppnValue;
                  const total = totalValue - discountValue;
                  previewInvoiceSubtotal += totalValue;
                  totalInvoice += total;
                  return (
                    <tr>
                      <td></td>
                      <td>
                        <Text>{item?.label}</Text>
                      </td>
                      <td>
                        <Text>{item?.uom}</Text>
                      </td>
                      <td></td>
                      <td>
                        <Text>{!!item?.ppn ? "true" : "false"}</Text>
                      </td>
                      <td>
                        <Text>{!!item?.qty ? item?.qty : 0}</Text>
                      </td>
                      <td>{formatCurrency(item?.product_unit?.price)}</td>
                      <td>
                        <Text>{formatCurrency(totalValue)}</Text>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(previewInvoiceSubtotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(previewInvoice?.shipment_fee)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Discount Pameran</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(previewInvoice?.auto_discount_nominal)}{" "}
                      {`(${previewInvoice?.auto_discount}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Voucher Discount</Text>
                  </td>
                  <td>
                    <Text color={"green.400"}>
                      {!!previewInvoice?.voucher_id
                        ? `${previewInvoice?.voucher_code} ${formatCurrency(
                            previewInvoice?.voucher_value_nominal
                          )}(${
                            previewInvoice?.voucher_type === "percentage"
                              ? `${previewInvoice?.voucher_value}%`
                              : `${formatCurrency(
                                  previewInvoice?.voucher_value
                                )}`
                          })`
                        : "0"}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Additional Discount</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(previewInvoice?.additional_discount)}{" "}
                      {`(${previewInvoice?.additional_discount_percentage}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(previewInvoice?.price)}</Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              my={5}
              onClick={handleSubmitInvoice}
              color="#fff"
              bg={"#01338C"}
              className=" w-full"
              isDisabled={isButtonDisabled()}
            >
              Edit Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export const HistoryPrice = ({
  handleUnitPrice,
  index,
  productId,
  resellerId,
}: any) => {
  const [price, setPrice] = useState<any>();
  const api = useAxios();
  const getRecentData = () => {
    api
      .get(`product-units/${productId}/user-price/${resellerId}`)
      .then((res) => {
        setPrice(res.data.data);
      });
  };
  return (
    <Menu>
      <MenuButton
        onClick={() => getRecentData()}
        transition="all 0.2s"
        _hover={{ bg: "gray.400" }}
        _expanded={{ bg: "blue.400" }}
      >
        <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        {price?.length === 0 ? (
          <Text>No Data</Text>
        ) : (
          price?.map((e: any) => (
            <MenuItem onClick={() => handleUnitPrice(e.unit_price, index)}>
              <Box borderBottomWidth={0.5}>
                <Text>{formatCurrency(e.unit_price)}</Text>
                <Text color={"grey"}>
                  used in {moment(e.created_at).format("D MMMM YYYY")}
                </Text>
              </Box>
            </MenuItem>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

export default EditInvoiceScreen;
