import { Button, Center, FormLabel, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../Helper/theme";
import useUpdateWarehouse from "../../api/hooks/warehouse/useUpdateWarehouse";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";

function FormUpdateWarehouse() {
  const { mutateAsync } = useUpdateWarehouse();
  const location = useLocation();
  const dataProps = location.state;
  const nav = useNavigate();
  const [name, setName] = useState<any>(dataProps?.name);
  const [code, setCode] = useState<any>(dataProps?.code);
  const [password, setPassword] = useState<any>("");
  const [roles, setRoles] = useState<[]>([]);
  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: name,
      code: code,
    }).then(() => {
      nav("/warehouse");
    });
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update Warehouse" />

      <FormLabel my={5} fontWeight={"bold"}>
        Name
      </FormLabel>
      <Input
        bg={"white"}
        placeholder="input name here"
        value={name}
        mt={"0.5rem"}
        onChange={(e) => setName(e?.target?.value)}
      />
      <FormLabel my={5} fontWeight={"bold"}>
        Code
      </FormLabel>
      <Input
        bg={"white"}
        placeholder="input name here"
        value={code}
        mt={"0.5rem"}
        onChange={(e) => setCode(e?.target?.value)}
      />

      <Center>
        <Button
          size={"md"}
          onClick={handleSubmit}
          mt={"0.5rem"}
          bg={COLOR_YELLOW}
          color="#fff"
        >
          Update
        </Button>
      </Center>
    </DashboardContainer>
  );
}

export default FormUpdateWarehouse;
