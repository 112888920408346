import {
  Box,
  Button,
  Checkbox,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { COLOR_PRIMARY } from "../../Helper/theme";
import usePermissionsList from "../../api/hooks/permissions";
import useCreateRolesList from "../../api/hooks/roles/useCreateRolesList";
import Header from "../../components/molecules/header";
import Loading from "../../components/atoms/loading";

const NewRoles = () => {
  const [role, setRole] = useState("");
  const { mutateAsync } = useCreateRolesList();
  const { data, refetch, isLoading } = usePermissionsList({
    per_page: 200,
  });
  const dataList = data?.pages?.[0].data;
  const [selectAll, setSelectAll] = useState(false);

  const [checkedItems, setCheckedItems] = useState<any>([]);
  const handleCheckboxChange = (value: any) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item: any) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };
  const handleAdd = () => {
    mutateAsync({
      name: role,
      permission_ids: checkedItems?.map((e: any) => e.id),
    })
      .then((res) => {
        console.log(res, "check res");
        // setRole("");
        // setCheckedItems([]);
      })
      .catch((err) => console.log(err, "ini error"));
  };

  const CheckBoxComponent = () => {
    return (
      <Box>
        <SimpleGrid columns={4} spacing={"10px"}>
          {dataList !== null &&
            dataList !== undefined &&
            dataList.map((item: any) => {
              return (
                <Box bg={"white"} p={5} borderWidth={0.5}>
                  <Checkbox
                    isChecked={checkedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                    fontWeight={item.parent_id === null ? "bold" : "normal"}
                  >
                    {item.name}
                  </Checkbox>
                </Box>
              );
            })}
        </SimpleGrid>
      </Box>
    );
  };
  useEffect(() => {
    if (selectAll) {
      setCheckedItems(dataList);
    } else {
      setCheckedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <Header headerName={"New Role"} back={true} />
      <div>
        <Text fontSize={"1em"} fontWeight={"bold"}>
          Role Name:
        </Text>
        <Input
          mt={"0.5rem"}
          bg="white"
          mb={5}
          size={"md"}
          placeholder="The name of the role"
          onChange={(e) => setRole(e?.target?.value)}
        />
        <Box bg={"white"} w={"400px"} p={5} borderWidth={0.5} mb={5}>
          <Checkbox
            isChecked={selectAll}
            onChange={() => setSelectAll(!selectAll)}
            fontWeight="semibold"
          >
            Select All
          </Checkbox>
        </Box>
        <CheckBoxComponent />
        <Button
          mt="0.5rem"
          bg={COLOR_PRIMARY}
          color={"#fff"}
          size="lg"
          onClick={handleAdd}
          isDisabled={checkedItems.length === 0}
        >
          Create Role
        </Button>
      </div>
    </div>
  );
};

export default NewRoles;
