import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useSubmitOpnameMutation from "../../api/hooks/opnames/useSubmitOpnameMutation";
import { COLOR_PRIMARY, COLOR_YELLOW } from "../../Helper/theme";

export default function OpnameCard({ data, refetch }: any) {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      w="100%"
      bg="#fff"
      my={4}
      borderRadius={10}
      boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
    >
      <Flex borderBottomWidth={0.5} borderColor="#c4c4c4">
        <Box borderRadius={10} p={4} w={"80%"} bg="white" textAlign={"start"}>
          <Text fontSize={"0.9em"}>{moment(data?.created_at).format("L")}</Text>
          <Text fontSize={"1em"} fontWeight="semibold">
            {data?.warehouse?.name}
          </Text>
          {/* <Text fontSize={10}>JKW10P</Text> */}
        </Box>
      </Flex>
      <Flex>
        <Box borderRadius={10} p={4} w={"80%"} bg="white" textAlign={"start"}>
          <Text fontSize={"0.8em"}>Created by : {data?.user?.name}</Text>
          <Text fontSize={"0.8em"}>Description : {data?.description}</Text>
        </Box>
        <Flex textAlign={"right"} m={3}>
          <SubmitOpname data={data} refetch={refetch} />
          <Button
            color={"white"}
            bg={COLOR_YELLOW}
            size={"sm"}
            onClick={() =>
              navigate("/stock-opname/details", {
                state: data?.id,
              })
            }
          >
            See Detail
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
const SubmitOpname = ({ data, refetch }: any) => {
  const { mutateAsync } = useSubmitOpnameMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = () => {
    mutateAsync({
      orderId: data.id,
    }).then(() => {
      onClose();
      // refetch();
    });
  };
  return (
    <>
      <Button
        bg={COLOR_PRIMARY}
        color="#fff"
        size={"sm"}
        mr={3}
        onClick={() => {
          onOpen();
        }}
      >
        Finish
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{"Are you sure want to finish this order ?"}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
