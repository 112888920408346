/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useReceiveOrderList from "../../api/hooks/receiveOrder/useReceiveOrderList";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import { ImportCSV } from "../../components/molecules/modal";
import Pagination from "../../components/molecules/pagination";
import TableSort from "../../components/molecules/tables/table";
import ReceiveOrderMobile from "./mobile";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";
import NoPermission from "../../components/molecules/noPermission";
import moment from "moment";

const ReceiveOrder = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [start, setStart] = useState<any>(
    moment(new Date()).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState<any>(
    moment(new Date()).endOf("month").format("YYYY-MM-DD")
  );
  const { data, refetch, isLoading } = useReceiveOrderList({
    "filter[start_date]": start,
    "filter[end_date]": end,
    include: "user",
    page: currentPage,
    "filter[invoice_no]": search,
  });
  console.log(search);
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  return (
    <div>
      {!dataPermission?.receive_order_access?.receive_order_access ? (
        <NoPermission />
      ) : (
        <>
          {MobileDevice ? (
            <div>
              <Header back={false} headerName="Receive Order" />
              <div style={{ display: "flex" }}>
                <Input
                  bg={"white"}
                  size="sm"
                  placeholder="Search RO number"
                  onChange={(event) => setSearch(event.target.value)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <input
                    type="date"
                    id="start"
                    name="start"
                    value={start}
                    className="form-control"
                    placeholder="Start Month"
                    onChange={(start) => setStart(start?.target?.value)}
                  />
                  <div
                    className="input-group-addon"
                    style={{ margin: "0 10px" }}
                  >
                    to
                  </div>
                  <input
                    type="date"
                    id="end"
                    name="end"
                    value={end}
                    className="form-control"
                    placeholder="End Month"
                    onChange={(end) => setEnd(end?.target?.value)}
                    style={{ margin: "0" }}
                  />
                  <Button ml="1rem" onClick={() => refetch()}>
                    Filter
                  </Button>
                </div>

                <ImportCSV refetch={() => refetch()} />
              </div>
              {!!isLoading ? (
                <Loading />
              ) : (
                <TableSort
                  data={dataList}
                  refetch={refetch}
                  Loading={isLoading}
                />
              )}
              <Pagination
                pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
                handlePagination={handlePagination}
              />
            </div>
          ) : (
            <ReceiveOrderMobile />
          )}
        </>
      )}
    </div>
  );
};

export default ReceiveOrder;
