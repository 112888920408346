import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import useProductCategory from "../../api/hooks/product/useProductCategory";

export const SelectCategoryList = ({
  selectedCategory,
  setSelectedCategory,
}: any) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useProductCategory({});
  const brand = data?.pages?.flatMap((page) => page.data);
  return (
    <Box>
      <Menu>
        <MenuButton
          textAlign={"start"}
          fontWeight="normal"
          bg={"white"}
          w="100%"
          borderWidth={1}
          color="grey"
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          {!selectedCategory ? "Select Category" : selectedCategory?.name}
        </MenuButton>
        <MenuList>
          {brand?.map((item: any) => (
            <MenuItem
              id={item?.id}
              key={item?.id}
              onClick={() => setSelectedCategory(item)}
            >
              {item?.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
