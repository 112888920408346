/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Text } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import useReceiveOrderDetail from "../../api/hooks/receiveOrder/useReceiveOrderDetail";
import Header from "../../components/molecules/header";
import Pagination from "../../components/molecules/pagination";
import { ResponsiveTable } from "../../components/molecules/tables/ReceiveOrderDetail";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { useMediaQuery } from "react-responsive";
import MobileReceiveOrderDetail from "./mobile/indexDetail";

function ReceiveOrderDetail(props: any) {
  const moment = require("moment");
  require("moment/locale/id"); // Load the Indonesian locale
  const [name, setName] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const receiveData = location.state;
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);
  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setName(searchTerm);
    handleSearch(searchTerm);
  };
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  const { data, refetch, isLoading } = useReceiveOrderDetail({
    page: currentPage,
    roId: receiveData?.id,
  });
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  useEffect(() => {
    refetch();
  }, [currentPage]);
  return (
    <div>
      {MobileDevice ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Header
            headerName={receiveData?.purchase_order_no}
            back={true}
            navName={"/shipment"}
          />
          <Flex justify="center" bg="white" py="4">
            <Box flex={1} h="100px">
              <Flex p={"1rem"} h="100%" flexDir="column">
                <Text>Date</Text>
                <Text color={COLOR_PRIMARY} fontWeight="bold">
                  {moment(receiveData?.receive_datetime).format("D MMMM YYYY")}
                </Text>
              </Flex>
            </Box>
            <Box flex={1} h="100px">
              <Flex p={"1rem"} h="100%" flexDir="column">
                <Text>Order Number</Text>
                <Text color={COLOR_PRIMARY} fontWeight="bold">
                  {receiveData?.purchase_order_no}
                </Text>
              </Flex>
            </Box>
            <Box flex={1} h="100px">
              <Flex p={"1rem"} h="100%" flexDir="column">
                <Text>Supplier</Text>
                <Text color={COLOR_PRIMARY} fontWeight="bold">
                  {receiveData?.supplier.name}
                </Text>
              </Flex>
            </Box>
            <Box flex={1} h="100px">
              <Flex p={"1rem"} h="100%" flexDir="column">
                <Text>items</Text>
                <Text color={COLOR_PRIMARY} fontWeight="bold">
                  {dataList?.length}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <ResponsiveTable
            data={dataList}
            refetch={refetch}
            receiveData={receiveData}
            isLoading={isLoading}
          />
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </div>
      ) : (
        <MobileReceiveOrderDetail
          header={receiveData?.purchase_order_no}
          data={dataList}
          date={receiveData?.receive_datetime}
          SONumber={receiveData?.purchase_order_no}
          supplier={receiveData?.supplier?.name}
          item={dataList?.length}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export default ReceiveOrderDetail;
