import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Container, Flex, Input } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useStockOpnameList from "../../api/hooks/opnames/useStockOpnameList";
import CreateOpname from "./createOpname";
import OpnameCard from "./opnameCard";

export default function StockOpname() {
  const nav = useNavigate();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useStockOpnameList({});
  const dataOpname = data?.pages?.flatMap((page) => page.data);
  return (
    <Container
      flex={1}
      maxW="container.sm"
      p={4}
      display="flex"
      h="100vh" // Set the height to 100% of the viewport height
      overflowY="scroll" // Enable vertical scrolling
      flexDirection="column"
    >
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Stock Opname
        </h3>
      </Flex>
      <Input mt={5} bg={"white"} placeholder="search opname" />
      <CreateOpname refetch={refetch} />
      <Box mt={10}>
        {dataOpname?.map((item) => (
          <OpnameCard data={item} />
        ))}
      </Box>
    </Container>
  );
}
