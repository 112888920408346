import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDeleteRole from "../../../api/hooks/roles/useDeleteRole";
function TableRoles({
  data,
  header,
  navName,
  btnName,
  btnNav,
  inpName,
  refetch,
}: any) {
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteRole();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const handleDelete = () => {
    if (selectedItem) {
      mutateAsync({
        id: selectedItem?.id,
      }).then(() => {
        refetch();
        onClose();
      });
    }
  };
  const selectItemAndOpenModal = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  const ModalDelete = ({ item }: any) => {
    return (
      <>
        <IconButton
          colorScheme="red"
          aria-label="Delete"
          icon={<DeleteIcon />}
          onClick={() => selectItemAndOpenModal(item)}
          ml="0.5rem"
          size={"sm"}
          color={"#fff"}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg={"transparent"} />
          <ModalContent>
            <ModalHeader>Delete Role</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Peringatan: User akan di hapus secara permanen. Lanjutkan?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={handleDelete}
                bg={"red"}
                color={"#fff"}
                ml={"1rem"}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Roles
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Created At
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Updated At
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item?.id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item?.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.created_at).format("L")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item.updated_at).format("L")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <IconButton
                  colorScheme={"yellow"}
                  aria-label="Edit"
                  icon={<EditIcon />}
                  size={"sm"}
                  color={"#fff"}
                  onClick={() => nav(navName, { state: item })}
                />
                {item?.id === 1 ? null : <ModalDelete item={item} />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableRoles;
