export const dataStock = [
  {
    date: "28 Februari 2023",
    name: "JPD Akafuji Floating M 5Kg",
    sku: "KMZWAY-XX",
    type: "Pelet Ikan",
    brand: "JPD",
    stock: 30,
  },
  {
    date: "28 Februari 2023",
    name: "JPD Akafuji Floating M 5Kg",
    sku: "KMZWAY-XX",
    type: "Pelet Ikan",
    brand: "JPD",
    stock: 20,
  },
  {
    date: "28 Februari 2023",
    name: "JPD Akafuji Floating M 5Kg",
    sku: "KMZWAY-XX",
    type: "Pelet Ikan",
    brand: "JPD",
    stock: 50,
  },
];

export const dataReturn = [
  {
    no: "1",
    date: "28 Februari 2023",
    rn: "SN12345",
    cn: "Michael",
    action: "JPD",
  },
  {
    no: "2",
    date: "28 Februari 2023",
    rn: "SN12345",
    cn: "Michael",
    action: "JPD",
  },
  {
    no: "3",
    date: "28 Februari 2023",
    rn: "SN12345",
    cn: "Michael",
    action: "JPD",
  },
];
