import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../Helper/theme";
import Header from "../molecules/header";
import useCreateCustomerMutation from "../../api/hooks/user/useUserCreateCustomer";
import { DashboardContainer } from "../atoms/dashboardContainer";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  phone: Yup.string().required("this field is required"),
  email: Yup.string().email("Invalid email"),
  address: Yup.string().required("this field is required"),
  code: Yup.string().required("this field is required"),
});

function FormCustomer() {
  const nav = useNavigate();
  const { mutateAsync } = useCreateCustomerMutation();
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    address: "",
    code: "",
  };

  const handleSubmit = (values: any) => {
    mutateAsync(values)
      .then(() => {
        nav("/customer");
      })
      .catch((err) => console.log(err));
    // Replace this with your actual form submission logic
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="New Customer" />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <FormControl isInvalid={!!(errors.code && touched.code)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Code
              </FormLabel>
              <Field
                as={Input}
                name="code"
                placeholder="Input code here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Customer name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Input customer name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.phone && touched.phone)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Phone Number
              </FormLabel>
              <div style={{ display: "flex" }}>
                <Select
                  placeholder="+62"
                  w={"6rem"}
                  size={"lg"}
                  disabled={true}
                >
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
                <Field
                  as={Input}
                  ml={"0.5rem"}
                  type="number"
                  name="phone"
                  placeholder="Input phone number here..."
                  size={"lg"}
                />
              </div>
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.email && touched.email)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Email
              </FormLabel>
              <Field
                as={Input}
                type="text"
                name="email"
                placeholder="Input Email here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.address && touched.address)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Address
              </FormLabel>
              <Field
                as={Input}
                type="text"
                name="address"
                placeholder="Input address here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.address}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              size={"lg"}
              bg={COLOR_PRIMARY}
              color="#fff"
              mt="0.5rem"
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormCustomer;
