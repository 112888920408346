import { atom } from "recoil";

export const collapsedState = atom({
  key: "collapsed", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const permissionState = atom({
  key: "permission", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
export const userState = atom({
  key: "userData", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});
