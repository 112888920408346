import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

interface ApiResponse {
  links: any;
  meta: any;
  data: any;
  pages: any;
  users: User[];
  totalPages: number;
  currentPage: number;
  map: any;
}

const useProductPriceList = (
  params?: any,
  options?: UseInfiniteQueryOptions<ApiResponse>
): UseInfiniteQueryResult<ApiResponse> => {
  const api = useAxios();

  return useInfiniteQuery<ApiResponse>(
    "productPriceList",
    ({ pageParam = 1 }) => {
      return api
        .get("sales-orders/product-units", {
          params: {
            ...params,
            page: pageParam,
            perPage: 10,
          },
        })
        .then((res) => {
          const data = res.data.data as ApiResponse;
          return data;
        })
        .catch((error) => {
          throw error; // Re-throw the error to be handled by React Query
        });
    },
    {
      enabled: false,
      // getNextPageParam: (lastQuery, pages) => {
      //   // Error
      //   if (lastQuery === undefined) {
      //     return false;
      //   }

      //   const currentPage = lastQuery.meta!.current_page;

      //   if (!lastQuery.links!.next) return false;
      //   return currentPage! + 1;
      // },
      // ...options,
    }
  );
};

export default useProductPriceList;
