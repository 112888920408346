import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

interface ApiResponse {
  links: any;
  meta: any;
  data: any;
  pages: any;
  users: User[];
  totalPages: number;
  currentPage: number;
  map: any;
}

const usePermissionsList = (
  params?: any,
  options?: UseInfiniteQueryOptions<any>
): UseInfiniteQueryResult<any> => {
  const api = useAxios();
  console.log(params, "param");
  return useInfiniteQuery<any>(
    `PermissionsList${params.type}${params?.roleId}`,
    ({ pageParam = 1 }) => {
      return api
        .get("permissions", {
          params: {
            ...params,
          },
        })
        .then((res) => {
          const data = res.data as any;
          return data;
        });
    },
    {
      // getNextPageParam: (lastQuery, pages) => {
      //   // Error
      //   if (lastQuery === undefined) {
      //     return false;
      //   }
      //   const currentPage = lastQuery.meta!.current_page;
      //   if (!lastQuery.links!.next) return false;
      //   return currentPage! + 1;
      // },
      // ...options,
    }
  );
};

export default usePermissionsList;
