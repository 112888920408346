import { Box, FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import useWarehouseList from "../../api/hooks/warehouse/useWarehouseList";

export const SelectWarehouseList = ({
  selectedWarehouse,
  setSelectedWarehouse,
  isMulti = false,
}: any) => {
  const [search, setSearch] = useState<string>("");
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useWarehouseList({
    "filter[name]": search,
  });
  useEffect(() => {
    refetch();
  }, [search]);
  const warehouse = data?.pages?.flatMap((page) => page.data);
  return (
    <Box>
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          isMulti={isMulti}
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={warehouse?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="Warehouse"
          closeMenuOnSelect={true}
          value={selectedWarehouse}
          onChange={setSelectedWarehouse}
        />
      </FormControl>
    </Box>
  );
};
