/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Html5QrcodeScanType } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStockShow from "../../api/hooks/stocks/useStockShow";
import Html5QrcodePlugin from "../../components/molecules/scanner";
import { COLOR_PRIMARY } from "../../Helper/theme";
import useDeleteStocks from "../../api/hooks/stocks/useDeleteStock";

type OnNewScanResult = (decodedText: string) => void;
const StockScanner = () => {
  const [decoded, setDecoded] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [product, setProduct] = useState<any>();
  const nav = useNavigate();
  const { data, isLoading, isFetching, refetch } = useStockShow({
    id: code,
  });

  console.log(product, "check data product");
  const onNewScanResult: OnNewScanResult = (decodedText) => {
    setDecoded(decodedText);
  };
  useEffect(() => {
    setProduct(data);
  }, [data]);
  useEffect(() => {
    if (decoded !== code) {
      setCode(decoded);
    }
  }, [decoded]);
  useEffect(() => {
    if (code) {
      refetch();
    }
    return;
  }, [code]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteStocks();
  const handleDelete = ({ item }: any) => {
    mutateAsync({
      stockId: item?.id,
    }).then(() => {
      refetch();
      setProduct(null);
      onClose();
    });
  };
  const ModalDelete = ({ item }: any) => {
    return (
      <>
        <Button
          // colorScheme='#800000'
          bg={"#800000"}
          mt="0.5rem"
          aria-label="Delete"
          size="sm"
          onClick={onOpen}
          color={"#fff"}
        >
          Delete Stock
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Stock</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Peringatan: Pesanan akan dihapus secara permanen. Lanjutkan?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={() => handleDelete({ item })}
                bg={"red"}
                color={"#fff"}
                ml={"1rem"}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  console.log(data?.data, "check data");

  return (
    <Container
      flex={1}
      maxW="container.sm"
      p={4}
      display="flex"
      flexDirection="column"
      h="100vh" // Set the height to 100% of the viewport height
      overflowY="scroll" // Enable vertical scrolling
    >
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Check Stock
        </h3>
      </Flex>
      <Box flex={1}>
        <Html5QrcodePlugin
          fps={5}
          qrbox={250}
          disableFlip={true}
          qrCodeSuccessCallback={onNewScanResult}
          supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
        />
        <Text textAlign={"center"} py={5} fontSize={"1.2em"}>
          Product History
        </Text>
        {!!data?.data ? (
          <Box h={"30%"} p={5}>
            <Text textAlign={"center"} fontSize={20} fontWeight="normal" mb={5}>
              {product?.data?.stock_product_unit?.product_unit?.name}
            </Text>
            <Text>
              Receive Number
              {product?.data?.receive_order_detail?.receive_order?.invoice_no}
            </Text>
            <Text>
              Code : {product?.data?.stock_product_unit?.product_unit?.code}
            </Text>
            <Text>
              Group Id :{" "}
              {product?.data?.parent_id === null
                ? ""
                : product?.data?.parent_id}
            </Text>
            <Text>Id : {product?.data?.id}</Text>
            <Text>
              Warehouse : {product?.data?.stock_product_unit?.warehouse?.name}
            </Text>
            <Text>Stock: {product?.data?.stock_product_unit?.qty}</Text>
            <Text>Print count : {product?.data?.scanned_count}</Text>
            <Text>Last scanned : {product?.data?.scanned_datetime}</Text>
            {<ModalDelete item={product?.data} />}
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};

export default StockScanner;
