import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import useCreateInvoiceSPGMutation from "../../../api/hooks/invoice/spg/useCreateInvoiceSPGMutation";
import usePreviewQuotationMutation from "../../../api/hooks/invoice/spg/usePreviewQuotationMutation";
import { formatCurrency } from "../../../components/atoms/formatCurrency";
import CounterComponent from "../../../components/molecules/counter";
import { SelectCustomerList } from "../../../components/molecules/selectCustomerList";
import { SelectProductQuotation } from "../../../components/molecules/selectProductUnitSPG";

function InvoiceCreateList() {
  const [soNumber, setSONumber] = useState("");
  const [delivery, setDelivery] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [type, setType] = useState<any>({
    value: "pickup",
    label: "pickup",
  });
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [voucher, setVoucher] = useState<string>("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [shipmentDate, setShipmentDate] = useState<any>(new Date());
  const [transactionDate, setTransactionDate] = useState<any>(new Date());
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [warehouse, setWarehouse] = useState<any>();
  const [items, setItems] = useState<any>([]);
  const [nextId, setNextId] = useState(1);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [notes, setNotes] = useState<string>("");
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const toggleSelectProduct = () => {
    setShowSelectProduct(!showSelectProduct);
  };
  const calculateValue = (percentage: number, total: number) => {
    return (percentage / 100) * total;
  };
  const handleQtyChange = (event: any, index: number) => {
    const newQty = parseInt(event);
    setItems((prevItems: any) => {
      const updatedDiscounts = [...prevItems];
      updatedDiscounts[index] = { ...updatedDiscounts[index], qty: newQty };
      return updatedDiscounts;
    });
  };
  const navigate = useNavigate();
  const handleCountChange = (newCountValue: number, index: any): void => {
    handleQtyChange(newCountValue, index);
  };
  const handleCheckboxChange = (event: any, index: number) => {
    setItems((prevItems: any) => {
      const updatedPpn = [...prevItems];
      updatedPpn[index] = {
        ...updatedPpn[index],
        ppn: event,
      };
      return updatedPpn;
    });
  };

  const handleAddItem = async (value: any) => {
    await setItems((prevItems: any): any => [...prevItems, value]);
    setShowSelectProduct(false);
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems: any[]) =>
      prevItems.filter((item: any) => item.id !== itemId)
    );

    if (items.length === 1) {
      setNextId(1); // Reset nextId to 1 when all items are deleted
    }
  };

  const handleDragEnd = (e: any) => {
    console.log(e);
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);
  };
  const isButtonDisabled = () => {
    return !shipmentDate || !transactionDate || items.length === 0;
  };
  const { mutateAsync } = useCreateInvoiceSPGMutation();
  const {
    mutateAsync: getPreview,
    data,
    isSuccess,
  } = usePreviewQuotationMutation();
  const handlePreview = (values: any) => {
    getPreview({
      shipment_fee: delivery,
      description: notes,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.value,
        qty: e?.qty || 1,
        unit_price: e?.price || 0,
        total_price:
          (e?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.discount !== null && e?.discount !== undefined
              ? e?.discount
              : e?.discount || 0,
            (e?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.discount !== null && e?.discount !== undefined
                      ? e?.discount
                      : e?.discount || 0,
                    (e?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount: e?.discount === undefined ? e?.discount : e?.discount,
        tax: e.ppn || false,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      preview: 1,
    });
  };
  console.log(data, "data");
  const handleSubmit = (values: any) => {
    mutateAsync({
      shipment_fee: delivery,
      description: notes,
      discount: !!discount ? discount : 0,
      transactionDate: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      estimatedDate: moment(shipmentDate).format("YYYY-MM-DD HH:mm:ss"),
      items: items.map((e: any, index: number) => ({
        product_unit_id: e?.value,
        qty: e?.qty || 1,
        unit_price: e?.price || 0,
        total_price:
          (e?.price || 0) * (e?.qty || 1) -
          calculateValue(
            e?.discount !== null && e?.discount !== undefined
              ? e?.discount
              : e?.discount || 0,
            (e?.price || 0) * (e?.qty || 1)
          ) +
          (e?.ppn === true
            ? calculateValue(
                11,
                (e?.price || 0) * (e?.qty || 1) -
                  calculateValue(
                    e?.discount !== null && e?.discount !== undefined
                      ? e?.discount
                      : e?.discount || 0,
                    (e?.price || 0) * (e?.qty || 1)
                  )
              )
            : 0),
        discount: e?.discount === undefined ? e?.discount : e?.discount,
        tax: e.ppn || false,
        packaging_id: checkedItems[index] ? e.packaging_id : null,
        warehouse_id: e.warehouse_id,
      })),
      name: name,
      address: address,
      phone: phone,
      voucher: voucher,
      selectedCustomerId: selectedCustomer?.value,
      type: type.value,
      preview: 0,
    });
  };
  let totalAmount = 0;
  let subTotal = 0;
  let previewSubtotal = 0;
  useEffect(() => {
    if (isSuccess) {
      onOpen();
    }
  }, [data]);
  return (
    <div className=" w-screen">
      <div className="z-10 bg-gradient-to-r from-[#01338C] to-[#00A3E0] h-15 py-3 flex items-center">
        <Button
          onClick={() => navigate(-1)}
          variant={"ghost"}
          _hover={{ bg: "#fff" }}
        >
          <ArrowBackIcon fontSize="xl" color="white" />
        </Button>
        <p className="text-lg font-medium text-white">Create Invoice</p>
      </div>
      <Box my={3} px="2">
        <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
          Customer Name
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input customer name"
          bg={"#fff"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box mb={3} px="2">
        <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
          Phone
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input customer phone number"
          bg={"#fff"}
          type="number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Box>
      <Box mb={3} px="2">
        <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
          Address
        </Text>
        <Input
          disabled={existingCustomer}
          placeholder="Input address customer ( optional )"
          bg={"#fff"}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <div className="mt-3">
          <Checkbox
            isChecked={existingCustomer}
            onChange={() => setExistingCustomer(!existingCustomer)}
          >
            <Text>Use existing customer ?</Text>
          </Checkbox>
        </div>
      </Box>
      {existingCustomer && (
        <Box mb={3} px="2">
          <SelectCustomerList
            type="customer_event"
            selected={selectedCustomer}
            setSelected={setSelectedCustomer}
          />
        </Box>
      )}
      <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
        Table Product
      </Text>
      <div
        className="overflow-x-auto whitespace-nowrap"
        style={{ maxWidth: "100%", overflowX: "scroll" }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <table>
            <thead>
              <tr className="bg-gray-200">
                <th>
                  <Text w="300px" textAlign={"left"} fontWeight={"medium"}>
                    Product name
                  </Text>
                </th>
                <th>
                  <Text fontWeight={"medium"}>Unit</Text>
                </th>
                <th>{/* <Text fontWeight={"medium"}>Packaging</Text> */}</th>
                <th>
                  <Text fontWeight={"medium"}>PPN </Text>
                </th>
                <th>
                  <Text w="100px" fontWeight={"medium"}>
                    Quantity
                  </Text>
                </th>
                {/* <th>Discount</th> */}
                <th>
                  <Text fontWeight={"medium"} w="150px">
                    Unit Price
                  </Text>
                </th>
                <th>
                  <Text fontWeight={"medium"} w="200px">
                    Sub Price
                  </Text>
                </th>
                <th>
                  <Text fontWeight={"medium"}>Action</Text>
                </th>
              </tr>
            </thead>
            <Droppable droppableId="dropable">
              {(provider) => (
                <tbody
                  className="text-capitalize"
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                >
                  {items?.map((item: any, index: any) => {
                    const productUnitPrice = item?.price || 0;
                    const quantity = item?.qty ? item.qty : 1;
                    const baseValue = productUnitPrice * quantity;
                    const discountValue = (discount / 100) * baseValue;
                    const ppnValue =
                      item?.ppn === true
                        ? calculateValue(11, baseValue - discountValue)
                        : 0;
                    const totalValue = baseValue + ppnValue;
                    const total = totalValue - discountValue;
                    subTotal += totalValue;
                    totalAmount += total;
                    return (
                      <Draggable
                        key={item.label}
                        draggableId={item.label}
                        index={index}
                      >
                        {(provider) => (
                          <tr
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            {/* <td  {...provider.dragHandleProps}> = </td> */}
                            <td>
                              <Text>{item?.label}</Text>
                            </td>
                            <td>
                              <Text>{item?.uom}</Text>
                            </td>
                            <td></td>
                            <td>
                              <Box>
                                <Checkbox
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event.target.checked,
                                      index
                                    )
                                  }
                                  colorScheme="green"
                                  size={"lg"}
                                  bg={"white"}
                                  defaultChecked={item.ppn === 1 ? true : false}
                                />
                              </Box>
                            </td>
                            <td>
                              <Box display="flex">
                                <CounterComponent
                                  onCountChange={(e) =>
                                    handleCountChange(e, index)
                                  }
                                  stockCount={item.stocks_count}
                                  value={!!item?.qty ? item?.qty : 0}
                                />
                              </Box>
                            </td>
                            <td>
                              <Box display="flex">
                                <Text>{formatCurrency(item?.price)}</Text>
                              </Box>
                            </td>
                            <td>
                              <Text>{formatCurrency(totalValue)}</Text>
                            </td>
                            <td>
                              <Box textAlign="center">
                                <Button
                                  variant={"link"}
                                  borderBottom={1}
                                  borderBottomColor={"#01338C"}
                                  borderBottomWidth={1}
                                  color={"#01338C"}
                                  onClick={() => handleRemoveItem(item.id)}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  <tr>
                    <td className="z-50">
                      <SelectProductQuotation
                        customerId={selectedCustomer?.value}
                        setSelectedProduct={(e: any) => handleAddItem(e)}
                        selectedProducts={items}
                        warehouse_id={1}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"bold"}>Sub Total</Text>
                    </td>
                    <td>
                      <Text>{formatCurrency(subTotal)}</Text>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"bold"}>Voucher</Text>
                    </td>
                    <td>
                      <Input
                        placeholder="Input voucher code"
                        bg={"#fff"}
                        value={voucher}
                        onChange={(item) => setVoucher(item.target.value)}
                      />{" "}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"bold"}>Delivery Fee</Text>
                    </td>
                    <td>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          children="Rp."
                          alignSelf={"center"}
                        />

                        <Input
                          placeholder="Delivery Price"
                          bg={"#fff"}
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                          type="number"
                        />
                      </InputGroup>
                    </td>
                    <td></td>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"bold"}>Total</Text>
                    </td>
                    <td>
                      <Text>
                        {formatCurrency(totalAmount + parseFloat(delivery))}
                      </Text>
                    </td>
                    <td></td>
                  </tr> */}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>

      <Box my={3} px="2">
        <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
          Shipment Date
        </Text>
        <DatePicker
          border
          selected={shipmentDate}
          showIcon
          showPopperArrow={false}
          onChange={(date) => setShipmentDate(date)}
          placeholderText="Select a date"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </Box>
      <div style={{ marginTop: "1rem" }}>
        <Box mb={3} px="2">
          <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
            Transaction Date
          </Text>

          <DatePicker
            border
            selected={transactionDate}
            showIcon
            showPopperArrow={false}
            onChange={(date) => setTransactionDate(date)}
            placeholderText="Select a date"
            minDate={moment().toDate()} // Set minimum selectable date to today
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </Box>
        <Box mb={3} px="2">
          <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
            Order type
          </Text>
          <Select
            isMulti={false}
            name="colors"
            defaultValue={{
              value: "pickup",
              label: "pickup",
            }}
            options={[
              {
                value: "pickup",
                label: "pickup",
              },
              {
                value: "delivery",
                label: "delivery",
              },
              {
                value: "free",
                label: "free",
              },
            ]}
            placeholder="order type"
            closeMenuOnSelect={true}
            value={type}
            onChange={setType}
          />
        </Box>
      </div>
      <Box mb={3} px="2">
        <Text fontWeight={"normal"} fontSize="sm" mb={"0.5em"}>
          Notes
        </Text>
        <Textarea
          onChange={(e) => setNotes(e.target.value)}
          bg={"white"}
          placeholder="Input Notes"
          value={notes}
        />
        <Button
          mt={10}
          onClick={handlePreview}
          color="#fff"
          bg={"#01338C"}
          width="full"
          isDisabled={isButtonDisabled()}
        >
          Create Order
        </Button>
      </Box>
      {/* Preview modal */}

      <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", minWidth: "800px" }}>
              <thead>
                <tr className="bg-gray-200">
                  <th />
                  <th>
                    <Text textAlign={"left"} fontWeight={"medium"}>
                      Product name
                    </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Unit</Text>
                  </th>
                  <th>{/* <Text fontWeight={"medium"}>Packaging</Text> */}</th>
                  <th>
                    <Text fontWeight={"medium"}>PPN </Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Quantity</Text>
                  </th>
                  {/* <th>Discount</th> */}
                  <th>
                    <Text fontWeight={"medium"}>Unit Price</Text>
                  </th>
                  <th>
                    <Text fontWeight={"medium"}>Sub Price</Text>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="text-capitalize">
                {data?.details?.map((item: any, index: any) => {
                  const productUnitPrice = item?.total_price || 0;
                  const quantity = item?.qty ? item.qty : 1;
                  const baseValue = productUnitPrice * quantity;
                  const discountValue = (discount / 100) * baseValue;
                  const ppnValue =
                    item?.ppn === true
                      ? calculateValue(11, baseValue - discountValue)
                      : 0;

                  const totalValue = baseValue;
                  const total = totalValue - discountValue;
                  previewSubtotal += item?.total_price;
                  totalAmount += total;
                  return (
                    <tr>
                      <td></td>
                      <td>
                        <Text>{item?.product_unit?.name}</Text>
                      </td>
                      <td>
                        <Text>{item?.uom}</Text>
                      </td>
                      <td></td>
                      <td>
                        <Text>{item?.tax === 11 ? "true" : "false"}</Text>
                      </td>
                      <td>
                        <Text>{!!item?.qty ? item?.qty : 0}</Text>
                      </td>
                      <td>{formatCurrency(item?.product_unit?.price)}</td>
                      <td>
                        <Text>{formatCurrency(item?.total_price)}</Text>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Sub Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(previewSubtotal)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Delivery Fee</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(data?.shipment_fee)}</Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Discount Pameran</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(data?.auto_discount_nominal)}{" "}
                      {`(${data?.auto_discount}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Voucher</Text>
                  </td>
                  <td>
                    <Text>
                      {!!data?.voucher_id
                        ? `${data?.voucher_code} (${
                            data?.voucher_type === "percentage"
                              ? `${data?.voucher_value}% - ${formatCurrency(
                                  data?.voucher_value_nominal
                                )}`
                              : `${formatCurrency(data?.voucher_value)}`
                          })`
                        : "0"}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                {/* <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"medium"}>Additional Discount</Text>
                  </td>
                  <td>
                    <Text>
                      {formatCurrency(data?.additional_discount)}{" "}
                      {`(${data?.additional_discount_percentage}%)`}
                    </Text>
                  </td>
                  <td></td>
                </tr> */}
                <tr>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text fontWeight={"bold"}>Total</Text>
                  </td>
                  <td>
                    <Text>{formatCurrency(data?.price)}</Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              my={5}
              onClick={handleSubmit}
              color="#fff"
              bg={"#01338C"}
              className=" w-full"
              isDisabled={isButtonDisabled()}
            >
              Confirm Orders
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
export default InvoiceCreateList;
