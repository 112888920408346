import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { COLOR_PRIMARY, COLOR_YELLOW } from "../../Helper/theme";
import Header from "../molecules/header";
import { DashboardContainer } from "../atoms/dashboardContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import useUpdateAdmin from "../../api/hooks/user/useUpdateAdmin";
import useUpdateCustomer from "../../api/hooks/user/useUpdateCustomer";

function FormUpdateCustomer() {
  const { mutateAsync } = useUpdateCustomer();
  const nav = useNavigate();
  const location = useLocation();
  const dataProps = location.state;
  const [name, setName] = useState<any>(dataProps?.name);
  const [email, setEmail] = useState<any>(dataProps?.email);
  const [password, setPassword] = useState<any>("");
  console.log(dataProps, "check data dari update screen");

  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: name,
      email: email,
    });
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update Customer" />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Name
      </Text>
      <Input
        placeholder="input name here"
        value={name}
        mt={"0.5rem"}
        onChange={(e) => setName(e?.target?.value)}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Email
      </Text>
      <Input
        placeholder="input name here"
        value={email}
        mt={"0.5rem"}
        onChange={(e) => setEmail(e?.target?.value)}
      />

      {/* <Text fontWeight={"bold"} fontSize={"1em"}>
        Password
      </Text>
      <Input
        placeholder="input name here"
        value={password}
        mt={"0.5rem"}
        onChange={(e) => setPassword(e?.target?.value)}
      /> */}

      <Button
        size={"md"}
        onClick={handleSubmit}
        mt={"0.5rem"}
        bg={COLOR_YELLOW}
        color="#fff"
      >
        Update
      </Button>
    </DashboardContainer>
  );
}

export default FormUpdateCustomer;
