/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text } from "@chakra-ui/react";
import {
  Html5QrcodeScanner,
  Html5Qrcode,
  Html5QrcodeScanType,
} from "html5-qrcode";
import { Html5QrcodeScannerConfig } from "html5-qrcode/esm/html5-qrcode-scanner";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface Html5QrcodePluginProps {
  fps?: number;
  qrbox?: number;
  aspectRatio?: number;
  disableFlip?: boolean;
  verbose?: boolean;
  qrCodeSuccessCallback: (decodedText: string) => void;
  qrCodeErrorCallback?: (errorMessage: string) => void;
  supportedScanTypes?: any[];
}

const qrcodeRegionId = "html5qr-code-full-region";

const createConfig = (props: Html5QrcodePluginProps) => {
  let config = {} as any;

  if (props.fps) {
    config.fps = props.fps;
  }

  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  if (props.supportedScanTypes) {
    config.scan = { supportedScanTypes: props.supportedScanTypes };
  }

  return config;
};

const Html5QrcodePlugin: React.FC<Html5QrcodePluginProps> = (props) => {
  const qrcodeRegionId = "qrcode-region";

  useEffect(() => {
    const config: Html5QrcodeScannerConfig = {
      // Set your desired configuration options here
      // Configuration options
      fps: 10, // Number of frames per second to process (default: 10)
      qrbox: 250, // The size of the QR code scanning box (default: 250)
      aspectRatio: 1, // The aspect ratio of the QR code scanning box (default: 1)
      disableFlip: false, // Whether to disable flipping the video element horizontally (default: false)
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      videoConstraints: {
        // The constraints for the video stream
        facingMode: "environment", // Specify the camera to use (default: 'environment')
        width: { min: 640, ideal: 1280, max: 1920 }, // Specify the width of the video stream (default: { ideal: 640 })
        height: { min: 480, ideal: 720, max: 1080 }, // Specify the height of the video stream (default: { ideal: 480 })
      },
    };

    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      props.verbose === true
    );

    html5QrcodeScanner.render(
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback
    );

    return () => {
      html5QrcodeScanner.clear().catch((error: Error) => {
        console.error("Failed to clear html5QrcodeScanner: ", error);
      });
    };
  }, []);

  useEffect(() => {
    const requestCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        // Camera permission granted
        toast("Camera permission granted");
        // You can use the `stream` object to access the camera stream
      } catch (error) {
        // Camera permission denied or error occurred
        console.error("Error requesting camera permission:", error);
        if (props.qrCodeErrorCallback) {
          toast("Error requesting camera permission");
          props.qrCodeErrorCallback("Error requesting camera permission");
        }
      }
    };

    requestCameraPermission();
  }, [props.qrCodeErrorCallback]);

  return (
    <div>
      <div id={qrcodeRegionId} />
    </div>
  );
};

export default Html5QrcodePlugin;
