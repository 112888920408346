import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import MobileSupplierDetail from "./mobile/indexDetail";
import useSalesOrderDetail from "../../api/hooks/salesOrder/useSalesOrderDetail";
import TableSalesOrderDetail from "../../components/molecules/tables/tableSalesOrderDetail";

function SalesOrderDetail() {
  const location = useLocation();
  const SalesOrderId = location.state;
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const { data, refetch, isLoading } = useSalesOrderDetail({
    id: SalesOrderId,
  });
  return (
    <div style={{ background: "#fff" }}>
      {MobileDevice ? (
        <TableSalesOrderDetail data={data?.data} />
      ) : (
        <MobileSupplierDetail
          data={data?.data}
          header={"Supplier Order Details"}
        />
      )}
    </div>
  );
}

export default SalesOrderDetail;
