/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import useProductBrands from "../../api/hooks/product/useProductBrands";
import useProductCategory from "../../api/hooks/product/useProductCategory";
import useReceiveStock from "../../api/hooks/stocks/useReceiveStock";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import { SelectWarehouseList } from "../../components/molecules/selectWarehouseList";
import TableStock from "../../components/molecules/tables";
import { permissionState } from "../../provider/atoms";

const Stock = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [warehouse, setWarehouse] = useState<any>();
  const [key, setKey] = useState<string>("");
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const { data, refetch, isLoading } = useReceiveStock({
    page: currentPage,
    "filter[product_unit]": key,
    "filter[warehouse_id]": warehouse?.value,
    sort: "-created_at",
  });
  // const {
  //   queries: [{ data: dataBrands }, { data: dataCategory }],
  //   meta: { isLoading },
  // } = useMultipleQueries([useProductBrands(), useProductCategory()] as const);
  if (!MobileDevice) {
    navigate("/mobile/home");
  }
  const dataList = data?.pages.flatMap((page) => page.data);

  const countData = data?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, key, warehouse]);
  return (
    <div>
      {!dataPermission?.stock_access?.stock_access ? (
        <NoPermission />
      ) : (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="py-0">
                <Header headerName={"Stock"} back={false} />
                <Flex>
                  <Input
                    onChange={(e) => setKey(e.target.value)}
                    placeholder="Search Product"
                    w="65%"
                    bg={"white"}
                    mr={"10px"}
                  />
                  <Flex>
                    <SelectWarehouseList
                      selectedWarehouse={warehouse}
                      setSelectedWarehouse={setWarehouse}
                    />
                    <Button
                      ml={"10px"}
                      bg={"#2E79FF"}
                      color="white"
                      onClick={() => {
                        if (
                          !dataPermission?.adjustment_request_access
                            ?.adjustment_request_access
                        ) {
                          toast("you dont have access");
                        } else {
                          navigate("/adjustment/request");
                        }
                      }}
                    >
                      Adjustment Req
                    </Button>
                  </Flex>
                </Flex>
              </div>

              <TableStock
                data={dataList}
                dataBrand={[]}
                dataCategory={[]}
                refetch={refetch}
              />
              <Pagination
                pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
                handlePagination={handlePagination}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Stock;
