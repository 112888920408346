import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useGetVoucherList from "../../../api/hooks/invoice/voucher/useGetVoucherList";
import useImportVoucherMutation from "../../../api/hooks/invoice/voucher/useImportVoucher";
import Header from "../../../components/molecules/header";
import Pagination from "../../../components/molecules/pagination";
import { SelectVoucherCategory } from "../../../components/molecules/selectVoucherCategory";
import { COLOR_PRIMARY } from "../../../Helper/theme";

export default function VoucherScreen() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [search, setSearch] = useState("")
  const { data, refetch, isLoading } = useGetVoucherList({
    page: currentPage,
    "filter[code]": search,
    include: "voucherGenerateBatch,category,salesOrder",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const countData = data?.pages[0].meta.total;
  const voucherList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];
    setFile(file);
  };
  const { mutateAsync, isSuccess } = useImportVoucherMutation();
  const handleImport = () => {
    mutateAsync({
      categoryId: category?.value,
      description: note,
      files: file,
    }).then(() => {
      onClose();
    });
  };
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  return (
    <div>
      <Header headerName={"Voucher"} back={true} />
      <div className="w-[100%] bg-white justify-between flex py-5">
        <Button
          onClick={onOpen}
          backgroundColor={COLOR_PRIMARY}
          color="white"
          className="w-[30%] "
        >
          Import
        </Button>
        <Button
          onClick={() => navigate("/voucher/batch")}
          color={COLOR_PRIMARY}
          bg="white"
          className="bg-white border-[2px] border-[#01338C]"
        >
          Batch & Category
        </Button>
      </div>
      <Input
            borderLeftWidth={0}
            borderRightWidth={0}
            rounded={0}
            placeholder="Search here..."
            onChange={(event) => setSearch(event.target.value)}
          />
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Release date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Batch
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Source
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {voucherList?.map((e: any) => (
            <tr>
              <th scope="col" className="px-6 py-3">
                <Text className="text-xs">{e?.code}</Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <Text className=" text-xs font-normal">
                  {moment(e?.created_at).format("dddd DD MMM YYYY")}
                </Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <Text className=" text-xs font-normal">
                  {`${e?.category?.name} (${e?.category?.discount_amount}%)`}
                </Text>
              </th>
              <th scope="col" className="px-6 py-3 w-[20%]">
                <Text className=" text-xs font-normal">
                  {e?.voucher_generate_batch?.description}
                </Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <Text className="uppercase text-xs">
                  {e?.voucher_generate_batch?.source === "upload" ? "G" : "I"}
                </Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <div
                  role="button"
                  onClick={() => {
                    !!e?.sales_order
                      ? navigate("/invoice/show", { state: e?.sales_order?.id })
                      : toast("there are no orders using this voucher");
                  }}
                  className={
                    !!e?.sales_order
                      ? "bg-[#379826] py-2  rounded-lg"
                      : "py-2 bg-slate-400 rounded-lg"
                  }
                >
                  <Text className="uppercase text-xs text-center text-white">
                    {!!e?.sales_order ? "used" : "available"}
                  </Text>
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
      <Modal isOpen={isOpen} size={"5xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Voucher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <SelectVoucherCategory
                selected={category}
                setSelected={setCategory}
              />
              <div className="mb-5" />
              <Textarea
                onChange={(e) => setNote(e.target.value)}
                bg={"white"}
                placeholder="Input Notes"
                value={note}
              />
              <div className="mb-5" />

              <input type="file" accept=".csv" onChange={handleFileChange} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              alignSelf={"center"}
              w={"100%"}
              bg={COLOR_PRIMARY}
              color={"#fff"}
              onClick={handleImport}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
