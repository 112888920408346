import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import FormImport from "../../forms/importCsvForm";

export function ImportCSV({ refetch }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        ml={5}
        px={10}
        bg={COLOR_PRIMARY}
        color="#fff"
        onClick={() => {
          onOpen();
        }}
      >
        + Import
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormImport refetch={refetch} onClose={onClose} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
