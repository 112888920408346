import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  receiveId: number;
  receiveOrderDetailId: number;
  type: number;
};

const useVerifyOrder = () => {
  const api = useAxios();
  return useMutation(({ receiveId, receiveOrderDetailId, type }: ParamType) => {
    return api
      .put(
        `receive-orders/${receiveId}/details/${receiveOrderDetailId}/verify`,
        {
          is_verified: type,
        }
      )
      .then(() => {
        toast(!!type ? "Order has been verified" : "unverified succesfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useVerifyOrder;
