import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

const useProductUnitList = (
  params?: any,
  options?: UseInfiniteQueryOptions<any>
): UseInfiniteQueryResult<any> => {
  const api = useAxios();

  return useInfiniteQuery<any>(
    ["ProductUnitLst", params],
    ({ pageParam = 1 }) => {
      return api
        .get("product-units", {
          params: {
            ...params,
          },
        })
        .then((res) => {
          const data = res.data as any;
          return data;
          //DEBT: DOCS RESULT
          //   return {
          //     ...data,
          //     users: data.users.map((user) => ({
          //       // map the user properties to your own interface here
          //     })),
          //   };
        });
    },
    {
      getNextPageParam: (lastQuery, pages) => {
        // Error
        if (lastQuery === undefined) {
          return false;
        }

        const currentPage = lastQuery.meta!.current_page;

        if (!lastQuery.links!.next) return false;
        return currentPage! + 1;
      },
      retry: false,
      ...options,
    }
  );
};

export default useProductUnitList;
