import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Text,
  Center,
} from "@chakra-ui/react";
import { COLOR_PRIMARY, COLOR_YELLOW } from "../../Helper/theme";
import Header from "../molecules/header";
import { DashboardContainer } from "../atoms/dashboardContainer";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import useUpdateAdmin from "../../api/hooks/user/useUpdateAdmin";
import { SelectRolesUser } from "../molecules/selectRolesUser";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  email: Yup.string().email("Invalid email").required("this field is required"),
  password: Yup.string().required("this field is required"),
});

function FromUpdateUser() {
  const { mutateAsync } = useUpdateAdmin();
  const location = useLocation();
  const dataProps = location.state;
  const [name, setName] = useState<any>(dataProps?.name);
  const [email, setEmail] = useState<any>(dataProps?.email);
  const [password, setPassword] = useState<any>("");
  const [roles, setRoles] = useState<[]>([]);
  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: name,
      email: email,
      password: password,
      roles: roles.map((e: any) => e.value),
    });
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update User" />

      <FormLabel my={5} fontWeight={"bold"}>
        Name
      </FormLabel>
      <Input
        bg={"white"}
        placeholder="input name here"
        value={name}
        mt={"0.5rem"}
        onChange={(e) => setName(e?.target?.value)}
      />
      <FormLabel my={5} fontWeight={"bold"}>
        Email
      </FormLabel>
      <Input
        bg={"white"}
        placeholder="input name here"
        value={email}
        mt={"0.5rem"}
        onChange={(e) => setEmail(e?.target?.value)}
      />

      <FormLabel my={5} fontWeight={"bold"}>
        Password
      </FormLabel>
      <Input
        bg={"white"}
        type="password"
        placeholder="input name here"
        value={password}
        mt={"0.5rem"}
        onChange={(e) => setPassword(e?.target?.value)}
      />
      <FormLabel my={5} fontWeight={"bold"}>
        Roles
      </FormLabel>
      <SelectRolesUser selectedRoles={roles} setSelectedRoles={setRoles} />

      <Center>
        <Button
          size={"md"}
          onClick={handleSubmit}
          mt={"0.5rem"}
          bg={COLOR_YELLOW}
          color="#fff"
        >
          Update
        </Button>
      </Center>
    </DashboardContainer>
  );
}

export default FromUpdateUser;
