import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../provider/auth";
import { useAxios } from "./useApi";

type ParamType = {
  email: string;
  password: string;
};

const useLoginMutation = () => {
  const api = useAxios();
  const { onLogin } = useAuth();
  return useMutation(({ email, password }: ParamType) => {
    return api
      .post(`auth/token`, {
        email: email,
        password: password,
      })
      .then((res) => {
        onLogin(res.data?.data?.token);
        toast("Berhasil Login");
      })
      .catch((err) => {
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useLoginMutation;
