import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  email: string;
  id: any;
};

const useUpdateCustomer = () => {
  const api = useAxios();
  return useMutation(({ email, name, id }: ParamType) => {
    return api
      .put(`users/${id}`, {
        name: name,
        email: email,
        type: "reseller",
      })
      .then((res) => {
        toast("Reseller berhasil diupdate");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useUpdateCustomer;
