import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/molecules/header";
import { COLOR_GREEN, COLOR_PRIMARY, COLOR_YELLOW } from "../../Helper/theme";

type OnNewScanResult = (decodedText: string) => void;
function AddStock() {
  const navigate = useNavigate();
  const [result, setResult] = useState<string>();
  const [supplier, setSupplier] = useState();
  const onNewScanResult: OnNewScanResult = (decodedText) => {
    setResult(decodedText);
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <Header headerName={"Add Stock"} back={true} />
      <div className="flex flex-row space-x-4">
        <div className="relative w-1/3">
          <Text className="py-2">Receive Order Id</Text>
          <Input placeholder="Receive Order Id" />
        </div>
        <div className="relative w-1/3">
          <Text className="py-2">Supplier</Text>
          <Menu>
            <MenuButton
              textAlign={"start"}
              fontWeight="normal"
              bg={"white"}
              w={"sm"}
              borderWidth={1}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Select Here
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => console.log("bom")}>
                Gustavo Fring
              </MenuItem>
              <MenuItem>Tuco Salamanca</MenuItem>
              <MenuItem onClick={() => navigate("/new-supplier")}>
                + Add Supplier
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <Button w="184px" size="md" bg={COLOR_PRIMARY} color="#fff">
          + Add
        </Button>
        <Button w="184px" size="md" bg={COLOR_YELLOW} color="#fff">
          + Print QR
        </Button>
        <Button w="184px" size="md" bg={COLOR_GREEN} color="#fff">
          + Add SKU
        </Button>
      </div>
    </div>
  );
}

export default AddStock;
