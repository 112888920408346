import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  product_unit_ids: any;
};

const useCreateProductBlacklist = () => {
  const api = useAxios();
  return useMutation(({ product_unit_ids }: ParamType) => {
    return api
      .post(`product-unit-blacklists`, {
        product_unit_ids: product_unit_ids,
      })
      .then((res) => {
        console.log(res);
        toast("Product Blacklist Unit berhasil dibuat");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  });
};

export default useCreateProductBlacklist;
