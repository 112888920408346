import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  productUnitId: number;
  desc: string;
  qty: number;
  warehouse_id: number;
  total_group: number;
  type: any;
};

const useGroupingMutation = () => {
  const api = useAxios();
  return useMutation(
    ({
      productUnitId,
      desc,
      qty,
      warehouse_id,
      total_group,
      type,
    }: ParamType) => {
      return api
        .post(`stocks/grouping`, {
          description: desc,
          qty: qty,
          warehouse_id: warehouse_id,
          total_group: total_group,
          [type]: productUnitId,
        })
        .then((res) => {
          console.log(res, "ahahay");
          toast("Group have been made");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useGroupingMutation;
