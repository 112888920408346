import { ArrowBackIcon } from "@chakra-ui/icons";
import { Image } from "@chakra-ui/react";
import { useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar as CustomSideBar,
  sidebarClasses,
  SubMenu,
} from "react-pro-sidebar";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import admin from "../../../assets/icon/Admin.png";
import register from "../../../assets/icon/Registrated.png";
import shipment from "../../../assets/icon/shipment.png";
import stock from "../../../assets/icon/Stock.png";
import store from "../../../assets/icon/store.png";
import IconReturn from "../../../assets/icon/return.png";
import platLogo from "../../../assets/logo/Logo-Gram-Full-White.png";
import { useAuth } from "../../../provider/auth";
import "./Sidebar.css";

const Sidebar = () => {
  const { onLogout } = useAuth();
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      {MobileDevice ? (
        <CustomSideBar
          collapsed={collapsed}
          onBackdropClick={() => console.log("check")}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: "#01338C",
            },
          }}
        >
          {/* <div className="flex justify-center items-center mb-4">
            <Image src={platLogo} boxSize={"40px"} alt="Platinum Logo" />
          </div> */}
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled, open }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    fontSize: 18,
                    "&:hover": {
                      backgroundColor: "#74b9ff",
                    },
                    color: open ? "white" : "white",
                    backgroundColor: active ? "#2E79FF" : "undefined",
                  };
                if (level === 1)
                  return {
                    fontSize: 18,
                    "&:hover": {
                      backgroundColor: "#74b9ff",
                    },
                    color: "white",
                    backgroundColor: active ? "#2E79FF" : "#01338C",
                  };
              },
            }}
          >
            <MenuItem
              className="my-5"
              icon={
                <Image src={platLogo} boxSize={"40px"} alt="Platinum Logo" />
              }
            >
              <p className="font-bold">Platinum</p>
              <p className="font-bold">Adi Sentosa</p>
            </MenuItem>
            <MenuItem
              onClick={() => setCollapsed(!collapsed)}
              icon={<ArrowBackIcon fontSize={"2rem"} />}
            >
              Minimize
            </MenuItem>
            <MenuItem
              icon={<Image src={register} boxSize={"25px"} alt="Stock Icon" />}
              active={window.location.pathname === "/ro"}
              component={<Link to="/ro" />}
            >
              Receive Order
            </MenuItem>
            <MenuItem
              active={window.location.pathname === "/"}
              icon={<Image src={stock} boxSize={"25px"} alt="Stock Icon" />}
              component={<Link to="/" />}
            >
              Stock
            </MenuItem>
            <MenuItem
              icon={
                <Image src={IconReturn} boxSize={"25px"} alt="Stock Icon" />
              }
              active={window.location.pathname === "/invoice"}
              component={<Link to="/invoice" />}
            >
              Invoice
            </MenuItem>
            <MenuItem
              icon={<Image src={store} boxSize={"25px"} alt="Stock Icon" />}
              active={window.location.pathname === "/master"}
              component={<Link to="/master" />}
            >
              Master
            </MenuItem>

            <SubMenu
              icon={<Image src={shipment} boxSize={"30px"} alt="Stock Icon" />}
              label="Shipment"
            >
              <MenuItem
                active={window.location.pathname === "/sales"}
                component={<Link to="/sales" />}
              >
                Sales Order
              </MenuItem>
              <MenuItem
                active={window.location.pathname === "/delivery"}
                component={<Link to="/delivery" />}
              >
                Delivery Order
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<Image src={admin} boxSize={"20px"} alt="Stock Icon" />}
              label="Admin"
            >
              <MenuItem
                active={window.location.pathname === "/users"}
                component={<Link to="/users" />}
              >
                Users
              </MenuItem>
              <MenuItem
                active={window.location.pathname === "/roles"}
                component={<Link to="/roles" />}
              >
                Roles
              </MenuItem>
            </SubMenu>
            <MenuItem onClick={() => onLogout()}> Logout </MenuItem>
          </Menu>
        </CustomSideBar>
      ) : null}
    </>
  );
};

export default Sidebar;
