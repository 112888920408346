/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSalesOrderList from "../../api/hooks/salesOrder/useSalesOrderList";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import Pagination from "../../components/molecules/pagination";
import TableSalesOrder from "../../components/molecules/tables/tableSalesOrder";
import { COLOR_FIGMA_LIGHT_BLUE } from "../../Helper/theme";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";
import NoPermission from "../../components/molecules/noPermission";
import moment from "moment";

function Sales() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [start, setStart] = useState<any>(
    moment(new Date()).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState<any>(
    moment(new Date()).endOf("month").format("YYYY-MM-DD")
  );
  const [dataPermission] = useRecoilState<any>(permissionState);
  const nav = useNavigate();
  console.log(start, "check data");
  const { data, refetch, isLoading } = useSalesOrderList({
    page: currentPage,
    include: "user",
    "filter[start_date]": start,
    "filter[end_date]": end,
    "filter[invoice_no]": search,
    "filter[has_delivery_order]": 0,
    "filter[is_invoice]": false,
    sort: "-invoice_no",
  });
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);

  return (
    <div>
      {!dataPermission?.sales_order_access?.sales_order_access ? (
        <NoPermission />
      ) : (
        <>
          <div>
            <Header headerName={"Sales Order"} back={false} />
            <div
              className="row space-x-11"
              style={{ marginBottom: "2rem", display: "flex" }}
            >
              <Input
                placeholder="Search SO Number"
                width="90%"
                bg={"white"}
                size="sm"
                onChange={(event) => setSearch(event.target.value)}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={start}
                  className="form-control"
                  placeholder="Start Month"
                  onChange={(start) => setStart(start?.target?.value)}
                />
                <div className="input-group-addon" style={{ margin: "0 10px" }}>
                  to
                </div>
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={end}
                  className="form-control"
                  placeholder="End Month"
                  onChange={(end) => setEnd(end?.target?.value)}
                  style={{ margin: "0" }}
                />
                <Button mx="10px" onClick={() => refetch()}>
                  Filter
                </Button>
                <Button
                  bg={COLOR_FIGMA_LIGHT_BLUE}
                  color="white"
                  onClick={() => nav("/sales/new-shipment")}
                  isDisabled={
                    !dataPermission?.sales_order_access?.sales_order_create
                  }
                >
                  + order
                </Button>
              </div>
            </div>
          </div>
          {!!isLoading ? <Loading /> : <TableSalesOrder data={dataList} />}
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </div>
  );
}

export default Sales;
