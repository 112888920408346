import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useCreateAdminMutation from "../../api/hooks/user/useUserCreateAdmin";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";
import { SelectRolesUser } from "../molecules/selectRolesUser";
import { useNavigate } from "react-router-dom";
import { SelectWarehouseList } from "../molecules/selectWarehouseList";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  email: Yup.string().email("Invalid email").required("this field is required"),
  password: Yup.string().required("this field is required"),
});

function FormUser() {
  const nav = useNavigate();
  const { mutateAsync } = useCreateAdminMutation();
  const initialValues = {
    name: "",
    email: "",
    password: "",
    roles: [],
    warehouse: null,
  };

  const handleSubmit = (values: any) => {
    mutateAsync({
      email: values.email,
      password: values.password,
      name: values.name,
      roles: values?.roles?.map((e: any) => e?.value),
      warehouse: values?.warehouse?.map((e: any) => e?.value),
    });
    nav("/users");
    // Replace this with your actual form submission logic
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="New User" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values, setValues }) => (
          <Form>
            <FormLabel fontWeight={"medium"} fontSize={14}>
              Name
            </FormLabel>
            <FormControl
              mt={5}
              bg={"white"}
              isInvalid={!!(errors.name && touched.name)}
            >
              <Field
                as={Input}
                name="name"
                placeholder="Input name here..."
                size={"lg"}
              />
            </FormControl>
            <FormErrorMessage>{errors.name}</FormErrorMessage>
            <FormLabel mt={5} fontWeight={"medium"} fontSize={14}>
              Email
            </FormLabel>
            <FormControl
              mt={5}
              bg={"white"}
              isInvalid={!!(errors.email && touched.email)}
            >
              <Field
                as={Input}
                name="email"
                placeholder="Input reseller email here..."
                size={"lg"}
              />
            </FormControl>
            <FormErrorMessage>{errors.email}</FormErrorMessage>

            <FormLabel mt={5} fontWeight={"medium"} fontSize={14}>
              Password
            </FormLabel>
            <FormControl
              bg={"white"}
              mt={5}
              isInvalid={!!(errors.password && touched.password)}
            >
              <Field
                as={Input}
                type="password"
                name="password"
                placeholder="Input password here..."
                size={"lg"}
              />
            </FormControl>
            <div className="mt-10">
              <FormLabel mt={5} fontWeight={"medium"} fontSize={14}>
                Warehouse
              </FormLabel>
              <SelectWarehouseList
                isMulti={true}
                selectedWarehouse={values.warehouse}
                setSelectedWarehouse={(warehouse: any) =>
                  setValues({ ...values, warehouse })
                } // Update only the 'warehouse' field
              />
            </div>
            <FormErrorMessage>{errors.password}</FormErrorMessage>
            <Field name="roles">
              {({ field, form }: any) => (
                <>
                  <FormLabel my={5} fontWeight={"medium"} fontSize={14}>
                    Roles
                  </FormLabel>
                  <SelectRolesUser
                    selectedRoles={field.values}
                    setSelectedRoles={(e: any) =>
                      form.setFieldValue(field.name, e)
                    }
                  />
                </>
              )}
            </Field>

            <Center mt={10}>
              <Button type="submit" size={"lg"} bg={COLOR_PRIMARY} color="#fff">
                Create Admin User
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormUser;
