/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@chakra-ui/react";
import useVerifyOrder from "../../api/hooks/receiveOrder/useVerifyOrder";

export default function UnverifiedButton({ props, refetch }: any) {
  console.log(props, "prips");
  const { mutateAsync } = useVerifyOrder();
  const handleSubmit = () => {
    mutateAsync({
      receiveId: props?.receive_order?.id,
      receiveOrderDetailId: props?.id,
      type: 0,
    }).then(() => {
      refetch();
    });
  };
  return (
    <>
      <Button
        backgroundColor="#26de81"
        onClick={handleSubmit}
        color="white"
        w={"8rem"}
      >
        Verified
      </Button>
    </>
  );
}
