import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  receiveId: number;
  receiveOrderDetailId: number;
  unitType: number;
  qty: string;
};

const useSelectConvertMutation = () => {
  const api = useAxios();
  return useMutation(
    ({ receiveId, receiveOrderDetailId, unitType, qty }: ParamType) => {
      return api
        .put(`receive-orders/${receiveId}/details/${receiveOrderDetailId}`, {
          adjust_qty: qty,
          uom_id: unitType,
        })
        .then((res) => {
          toast("Unit type berhasil diubah");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useSelectConvertMutation;
