import { Box, FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import useRoleList from "../../api/hooks/roles/useRoleList";

export const SelectRolesUser = ({ selectedRoles, setSelectedRoles }: any) => {
  const [search, setSearch] = useState<string>("");
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useRoleList({
    "filter[name]": search,
  });
  useEffect(() => {
    refetch();
  }, [search]);
  const Roles = data?.pages?.flatMap((page) => page.data);
  return (
    <Box>
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          isMulti
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={Roles?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="Select Roles"
          closeMenuOnSelect={false}
          value={selectedRoles}
          onChange={setSelectedRoles}
        />
      </FormControl>
    </Box>
  );
};
