import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  code: string;
  id: any;
};

const useUpdateWarehouse = () => {
  const api = useAxios();
  return useMutation(({ code, name, id }: ParamType) => {
    return api
      .put(`warehouses/${id}`, {
        name: name,
        code: code,
      })
      .then((res) => {
        toast("Warehouse berhasil diupdate");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useUpdateWarehouse;
