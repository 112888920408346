import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
};

const useSubmitFinishRO = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(({ id }: ParamType) => {
    return api
      .put(`receive-orders/${id}/done`, {
        is_done: 1,
      })
      .then((res) => {
        console.log(res);
        toast("Receive Order berhasil di submit");
      })
      .catch((err) => {
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useSubmitFinishRO;
