/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { COLOR_PRIMARY } from "../../Helper/theme";
import useApproveAdjust from "../../api/hooks/stocks/useApproveAdjustmentMutation";
import useStockAdjustmentList from "../../api/hooks/stocks/useStockAdjustmentList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Header from "../../components/molecules/header";
import Pagination from "../../components/molecules/pagination";
import { permissionState } from "../../provider/atoms";

export default function AdjustmentScreen() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, refetch, isLoading } = useStockAdjustmentList({
    page: currentPage,
    sort: "-created_at",
  });
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);

  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage]);
  return (
    <DashboardContainer>
      <Header back={true} headerName="Adjustment request" />
      <TableAdjust data={dataList} refetch={refetch} />
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
    </DashboardContainer>
  );
}
const TableAdjust = ({ data, refetch }: any) => {
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { mutateAsync, isLoading } = useApproveAdjust();
  const [note, setNote] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectItemAndOpenModal = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  return (
    <table className="styled-table">
      <thead style={{ backgroundColor: "#F8FBFF" }}>
        <th
          onClick={() => console.log("date")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "10rem",
          }}
        >
          Date
        </th>
        <th
          onClick={() => console.log("number")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "15rem",
          }}
        >
          Item No
        </th>
        <th
          onClick={() => console.log("number")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "15rem",
          }}
        >
          Name
        </th>
        <th
          onClick={() => console.log("items")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "10rem",
          }}
        >
          Adjustment
        </th>
        <th
          onClick={() => console.log("warehouse")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "15rem",
          }}
        >
          Notes
        </th>
        <th
          onClick={() => console.log("supplier")}
          style={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "1em",
            width: "15rem",
          }}
        >
          Action
        </th>
      </thead>
      <tbody>
        {data?.map((item: any) => {
          return (
            <tr key={item?.id} style={{ marginTop: "0.5rem" }}>
              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {moment(item?.created_at).format("DD MMMM YYYY")}
              </td>
              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {item?.stock_product_unit?.product_unit?.code}
              </td>
              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {item?.stock_product_unit?.product_unit?.name}
              </td>
              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {item?.is_increment === true ? "+" : "-"}
                {item?.value}
              </td>
              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {item?.description}
              </td>

              <td style={{ fontWeight: "500", fontSize: "1em" }}>
                {item?.is_approved === 1 ? (
                  <Button isDisabled size="sm" bg={"green"} color="white">
                    Approved
                  </Button>
                ) : item?.is_approved === 0 ? (
                  <Button isDisabled size="sm" bg={"red"} color="white">
                    Rejected
                  </Button>
                ) : (
                  <>
                    <Button
                      isDisabled={
                        !dataPermission?.adjustment_request_access
                          ?.adjustment_request_approve
                      }
                      bg={COLOR_PRIMARY}
                      color="#fff"
                      size={"sm"}
                      onClick={() => selectItemAndOpenModal(item)}
                    >
                      Approve
                    </Button>
                    <Modal isCentered isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Approved Adjustment Request</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          Warning: Are you sure want to Approved this?
                          <div>
                            <Text fontWeight={"bold"} mt={"1rem"}>
                              Reason:{" "}
                            </Text>
                            <Input
                              value={note}
                              onChange={(e) => setNote(e?.target?.value)}
                              placeholder="Put Your Note Here"
                              type="text"
                              mt={"0.5rem"}
                            />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            isLoading={isLoading}
                            onClick={() => {
                              if (selectedItem) {
                                mutateAsync({
                                  id: selectedItem?.id,
                                  is_approved: 1,
                                  reason: note,
                                }).then(() => {
                                  refetch();
                                  onClose();
                                });
                              }
                            }}
                            bg={"green"}
                            color={"#fff"}
                            ml={"1rem"}
                          >
                            Approves
                          </Button>
                          <Button
                            isLoading={isLoading}
                            disabled={isLoading}
                            onClick={() => {
                              if (selectedItem) {
                                mutateAsync({
                                  id: selectedItem?.id,
                                  is_approved: 0,
                                  reason: note,
                                }).then(() => {
                                  refetch();
                                  onClose();
                                });
                              }
                            }}
                            bg={"red"}
                            color={"#fff"}
                            ml={"1rem"}
                          >
                            Reject
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </>
                  // <Button
                  //   size="sm"
                  //   bg={COLOR_PRIMARY}
                  //   color="white"
                  // onClick={() =>
                  //   mutateAsync({
                  //     id: item?.id,
                  //   }).then(() => refetch())
                  // }
                  // >
                  //   Approve
                  // </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
