import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
};

const useCreateUoms = () => {
  const api = useAxios();
  return useMutation(({ name }: ParamType) => {
    return api
      .post(`uoms`, {
        name: name,
      })
      .then(() => {
        toast("Successfully create uoms");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useCreateUoms;
