import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  warehouseId: number;
  description: string;
};

const useCreateStockOpnameMutation = () => {
  const api = useAxios();
  return useMutation(({ warehouseId, description }: ParamType) => {
    return api
      .post(`stock-opnames`, {
        warehouse_id: warehouseId,
        description: description,
      })
      .then((res) => {
        toast("Stock Opname berhasil dibuat");
      })
      .catch((err) => {
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useCreateStockOpnameMutation;
