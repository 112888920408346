import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useProductPriceList from "../../api/hooks/salesOrder/useProductPriceList";

export const SelectProductListTest = ({
  selectedProduct,
  setSelectedProduct,
  customerId,
  selectedProducts = [],
  warehouse_id,
}: any) => {
  const [search, setSearch] = useState<string>("");

  const { data, refetch } = useProductPriceList({
    // customer_id: customerId,
    "filter[product_unit]": search,
    "filter[warehouse_id]": warehouse_id,
  });
  const dataList = data?.pages?.[0];
  // const handleProductSelect = (product: any) => {
  //   const isDuplicate = selectedProducts.some(
  //     (item: any) => item.value === product.value
  //   );

  //   if (isDuplicate) {
  //     toast.warn("Product already exists");
  //   } else {
  //     setSelectedProduct(product);
  //   }
  // };

  const handleProductSelect = (product: any) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    refetch();
    return;
  }, [search]);
  return (
    <Box>
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          autoFocus={true}
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={dataList?.map((item: any) => ({
            value: item.product_unit.id,
            label: item.product_unit.name,
            price: item.product_unit.price,
            uom: item.product_unit.uom.name,
            product_unit: {
              price: item.product_unit.price,
            },
            discount: item.product_unit.discount,
            price_discount: item.product_unit.price_discount,
            stocks_count: item.stocks_count,
            id: item.id,
            warehouse_id: item.warehouse.id,
            packaging_id: item.product_unit.packaging_id,
            ppn: item.product_unit.is_ppn,
          }))}
          placeholder="Select Product"
          closeMenuOnSelect={true}
          value={selectedProduct}
          onChange={handleProductSelect}
        />
      </FormControl>
    </Box>
  );
};

const oldMenu = ({
  selectedProduct,
  setSelectedProduct,
  dataList,
  setSearch,
  productList,
}: any) => {
  return (
    <Box>
      <Menu>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Select Product
        </h3>
        <MenuButton
          textAlign={"start"}
          fontWeight="normal"
          bg={"white"}
          borderWidth={1}
          as={Button}
          w={"100%"}
          rightIcon={<ChevronDownIcon />}
        >
          {!selectedProduct ? "Select Product" : selectedProduct?.name}
        </MenuButton>
        <MenuList>
          <Input
            borderLeftWidth={0}
            borderRightWidth={0}
            placeholder="Search here..."
            onChange={(event) => setSearch(event.target.value)}
          />
          {dataList?.map((item: any) => (
            <MenuItem
              id={item?.id}
              key={item?.id}
              onClick={() => {
                if (
                  productList.find(
                    (e: { product_unit: any; id: number }) =>
                      e.product_unit.id === item.product_unit.id
                  )
                ) {
                  toast.warn("Product already exist");
                } else {
                  setSelectedProduct(item);
                }
              }}
            >
              {item?.product_unit?.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
