import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  type: string;
  id: number;
  stock_ids?: any;
  is_printAll?: boolean;
};

const usePrintRecordMutation = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(({ type, id, stock_ids, is_printAll }: ParamType) => {
    return api
      .post(`stocks/record`, {
        [type]: id,
        stock_ids: stock_ids,
        is_print_all: !!is_printAll ? is_printAll : false,
      })
      .then((res) => {
        console.log(res, "sol");
      })
      .catch((err) => {
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default usePrintRecordMutation;
