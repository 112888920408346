import { Button } from "@chakra-ui/react";
import { COLOR_GREEN, COLOR_YELLOW } from "../../Helper/theme";
import useUpdateVerification from "../../api/hooks/stocks/useUpdateVerificationTempel";

import React, { useState } from "react";
import { queryClient } from "../../provider/query";

function ButtonUpdateVerification({ item }: any) {
  const { mutateAsync } = useUpdateVerification();

  const [isTempel, setIsTempel] = useState(item?.is_tempel || false);

  const handleSubmit = () => {
    mutateAsync({
      id: item?.id,
      is_tempel: !isTempel, // Toggle the value
    }).then(() => {
      queryClient.invalidateQueries("ReceiveStockDetail");
    });

    setIsTempel(!isTempel);
  };

  return (
    <>
      <Button
        color={"#fff"}
        size="sm"
        mx={3}
        bg={isTempel ? COLOR_GREEN : COLOR_YELLOW}
        onClick={handleSubmit}
      >
        {isTempel ? "discharged" : "discharge"}
      </Button>
    </>
  );
}

export default ButtonUpdateVerification;
