import {
  Badge,
  Button,
  Checkbox,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useRecoilState } from "recoil";
import usePrintRecordMutation from "../../../api/hooks/stocks/usePrintRecordMutation";
import { permissionState } from "../../../provider/atoms";
import ButtonUpdateVerification from "../../forms/VerificationTempel";
import { RepackStock } from "../modal/RepackStock";
import PrintableContent from "../printContent";
import PrintGroup from "../printContent/printGroup";
import "./print.css";

const TableStockDetail = ({
  data,
  props,
  selectedRows,
  setSelectedRows,
  name,
  refetch,
}: any) => {
  const printComponentRef = useRef<any>(null);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(data?.map((item: any) => item));
    } else {
      setSelectedRows([]);
    }
  };
  const handleSelectRow = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== id));
    }
  };
  const { mutateAsync } = usePrintRecordMutation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>
            <Checkbox borderRadius={5} onChange={handleSelectAllRows} />
          </Th>
          <Th>QR Code</Th>
          <Th>Name</Th>
          <Th>Printed Date</Th>
          <Th>Printed Times</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody bg={"#E1EAF6"}>
        {data?.map((item: any) => {
          return (
            <Tr key={item?.id}>
              <Td>
                <Checkbox
                  borderColor={"#2E79FF"}
                  borderRadius={5}
                  isChecked={selectedRows.includes(item)}
                  onChange={(event) => handleSelectRow(event, item)}
                />
              </Td>
              <Td>
                <Image src={item?.qr_code} w={"5rem"} />
                <div className="print-content">
                  <PrintableContent
                    ref={printComponentRef}
                    image={item?.qr_code}
                    name={
                      item?.description === null
                        ? props?.name
                        : item?.description
                    }
                  />
                </div>
              </Td>
              <Td>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {item?.description === null
                      ? props?.name
                      : item?.description}
                  </h3>
                  {item?.is_tempel == true ? (
                    <Badge variant="solid" colorScheme="green" ml={5}>
                      Terpasang
                    </Badge>
                  ) : null}
                </div>
                <h3
                  style={{
                    fontSize: 12,
                  }}
                >
                  ID:&nbsp;{item?.id}
                </h3>
                {item?.childs_count !== undefined &&
                item?.childs_count !== 0 ? (
                  <h3
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Quantity Group Child:&nbsp;{item?.childs_count}
                  </h3>
                ) : null}
              </Td>
              <Td>{!!item?.scanned_datetime ? item?.scanned_datetime : "-"}</Td>
              <Td>{item?.scanned_count}</Td>
              <Td>
                <ReactToPrint
                  onAfterPrint={() =>
                    mutateAsync({
                      type:
                        props?.type === "ro"
                          ? "receive_order_detail_id"
                          : "stock_product_unit_id",
                      id:
                        props?.type === "ro"
                          ? item?.receive_order_detail_id
                          : item?.stock_product_unit_id,
                      stock_ids: [item?.id],
                    }).then(() => refetch())
                  }
                  trigger={() => (
                    <Button
                      bg={"#2E79FF"}
                      color={"#fff"}
                      size="sm"
                      isDisabled={!dataPermission?.stock_access?.stock_print}
                    >
                      Print
                    </Button>
                  )}
                  content={() => printComponentRef.current}
                />
                {item?.childs_count !== undefined &&
                item?.childs_count !== 0 ? (
                  <PrintGroup
                    nameProduct={name}
                    groupId={item}
                    refetch={refetch}
                  />
                ) : null}
                <ButtonUpdateVerification item={item} />
                <RepackStock item={item} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default TableStockDetail;
