import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  invoice_no: string;
  warehouse_id: string;
  reseller_id: string;
  transaction_date: any;
  shipment_estimation_datetime: any;
};

const useUpdateDeliveryOrder = () => {
  const api = useAxios();
  return useMutation(
    ({
      id,
      invoice_no,
      reseller_id,
      shipment_estimation_datetime,
      transaction_date,
      warehouse_id,
    }: ParamType) => {
      return api
        .put(`delivery-orders/${id}`, {
          invoice_no: invoice_no,
          reseller_id: reseller_id,
          shipment_estimation_datetime: shipment_estimation_datetime,
          transaction_date: transaction_date,
          warehouse_id: warehouse_id,
        })
        .then((res) => {
          console.log(res, "check res");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useUpdateDeliveryOrder;
