/* eslint-disable jsx-a11y/img-redundant-alt */
import { Text } from "@chakra-ui/react";
import { Component } from "react";

interface PrintableContentProps {
  image?: string;
  data?: any;
  multiple?: boolean;
  name?: string;
  type?: number;
}

class PrintableContent extends Component<PrintableContentProps> {
  render() {
    const { image, data, multiple, name, type } = this.props;
    return (
      <div className="grid grid-cols-2">
        {!!multiple ? (
          data?.map((e: any) => (
            <div
              className="flex flex-col items-center p-2 page-break"
              style={{
                height: "118px",
              }}
            >
              {type === 1 ? (
                <div>
                  {/* yang kecil */}
                  <img
                    src={e?.qr_code}
                    alt="image"
                    style={{
                      width: "56px",
                    }}
                  />
                  <Text
                    alignSelf="center"
                    textAlign={"center"}
                    pt={1}
                    fontSize={7}
                    fontWeight={"bold"}
                    w="3.5rem"
                    noOfLines={2}
                  >
                    {!!e?.description ? e?.description : name}
                  </Text>
                </div>
              ) : type === 2 ? (
                <div>
                  {/* yang gede */}
                  <img src={e?.qr_code} alt="image" style={{ width: "75px" }} />
                  <Text
                    alignSelf="center"
                    textAlign={"center"}
                    pt={2}
                    fontSize={10}
                    w="5rem"
                    noOfLines={2}
                    fontWeight={"bold"}
                  >
                    {!!e?.description ? e?.description : name}
                  </Text>
                </div>
              ) : type === 3 ? (
                <div>
                  {/* yang gede */}
                  <img src={e?.qr_code} alt="image" style={{ width: "75px" }} />
                  <Text
                    alignSelf="center"
                    textAlign={"center"}
                    pt={2}
                    fontSize={10}
                    w="5rem"
                    noOfLines={2}
                    fontWeight={"bold"}
                  >
                    {!!e?.description ? e?.description : name}
                  </Text>
                </div>
              ) : null}
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center p-3">
            <img src={image} alt="image" />
            <Text
              alignSelf="center"
              textAlign={"center"}
              pt={2}
              fontSize={10}
              w="5rem"
            >
              {name}
            </Text>
          </div>
        )}
      </div>
    );
  }
}

export default PrintableContent;
