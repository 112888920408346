import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useCreateStockOpnameMutation from "../../api/hooks/opnames/useCreateStockOpnameMutation";
import { SelectWarehouseList } from "../../components/molecules/selectWarehouseList";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";

export default function CreateOpname({ refetch }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [warehouse, setWarehouse] = useState<any>();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [description, setDescription] = useState<string>("");
  const initialRef = React.useRef(null);
  const { mutateAsync } = useCreateStockOpnameMutation();
  const handleSubmit = () => {
    mutateAsync({
      warehouseId: warehouse?.value,
      description: description,
    }).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg={"#01338C"}
        color="white"
        mt={5}
        w={"100%"}
        isDisabled={!dataPermission?.stock_opname_access?.stock_opname_create}
      >
        + Add New
      </Button>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Stock Opname</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormLabel>warehouse</FormLabel>
            <SelectWarehouseList
              selectedWarehouse={warehouse}
              setSelectedWarehouse={setWarehouse}
            />
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
