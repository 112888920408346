import React from "react";
import { Button, Input, Select } from "@chakra-ui/react";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_YELLOW } from "../../../Helper/theme";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import { debounce } from "lodash";
function TableProduct({
  data,
  header,
  col1,
  col2,
  col3,
  col4,
  col5,
  navName,
  btnName,
  btnNav,
  btnName2,
  btnNav2,
  inpName,
  setKey,
  key,
}: any) {
  const nav = useNavigate();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              {col2}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              {col3}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              {col4}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              {col5}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item.product_category?.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.product_brand?.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <Button
                  onClick={() =>
                    nav(navName, {
                      state: item,
                    })
                  }
                  size="sm"
                  bg={COLOR_YELLOW}
                  color="#fff"
                >
                  See Detail
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableProduct;
