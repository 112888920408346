import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { COLOR_PRIMARY } from "../../Helper/theme";
import useCreateSupplierMutation from "../../api/hooks/supplier/useCreateSupplierMutation";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("This field is required")
    .min(5, "code must be more than 5 characters"),
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  phone: Yup.string().required("this field is required"),
  email: Yup.string().email("Invalid email").required("this field is required"),
  address: Yup.string().required("this field is required"),
  description: Yup.string().nullable(),
});

function FormSupplier() {
  const navigate = useNavigate();
  const { mutateAsync } = useCreateSupplierMutation();
  const initialValues = {
    code: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    description: "",
  };

  const handleSubmit = (values: any) => {
    // mutateAsync(values);
    mutateAsync({
      address: values.address,
      code: values.code,
      description: values.description,
      email: values.email,
      phone: values.phone,
      name: values.name,
    })
      .then((res) => navigate("/supplier"))
      .catch((err) => console.log(err));

    // Replace this with your actual form submission logic
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="New Supplier" />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <FormControl isInvalid={!!(errors.code && touched.code)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Code Supplier
              </FormLabel>
              <Field
                as={Input}
                name="code"
                placeholder="Input supplier name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.code}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Supplier name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Input supplier name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.phone && touched.phone)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Phone Number
              </FormLabel>
              <div style={{ display: "flex" }}>
                <Select
                  placeholder="+62"
                  w={"6rem"}
                  size={"lg"}
                  disabled={true}
                ></Select>
                <Field
                  as={Input}
                  ml={"0.5rem"}
                  type="number"
                  name="phone"
                  placeholder="Input phone number here..."
                  size={"lg"}
                />
              </div>
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.email && touched.email)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Email
              </FormLabel>
              <Field
                as={Input}
                type="text"
                name="email"
                placeholder="Input Email here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt={"1rem"}
              isInvalid={!!(errors.address && touched.address)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Address
              </FormLabel>
              <Field
                as={Input}
                type="text"
                name="address"
                placeholder="Input address here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.address}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              size={"lg"}
              bg={COLOR_PRIMARY}
              color="#fff"
              mt="0.5rem"
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormSupplier;
