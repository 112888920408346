import { Button, Icon } from "@chakra-ui/react";
import React from "react";
import { TbFileExport } from "react-icons/tb";
import { useAxios } from "../../../api/hooks/useApi";
import { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";

export default function ExportXML({ id, type, title }: any) {
  const api = useAxios();
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const [dataPermission] = useRecoilState<any>(permissionState);
  const downloadXMl = () => {
    api.get(`${type}/${id}/export-xml`).then((res) => {
      const file = new Blob([res.data], { type: "text/xml" });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = `${title}.xml`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  };
  return (
    <Button
      bg={"#2E79FF"}
      color={"#fff"}
      size="sm"
      w={MobileDevice ? "6rem" : "6rem"}
      onClick={downloadXMl}
      isDisabled={!dataPermission?.sales_order_access?.sales_order_export_xml}
    >
      <Icon color={"#fff"} as={TbFileExport} mr="0.5rem" size={"lg"} /> Export
    </Button>
  );
}
