/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import useProductUnitList from "../../api/hooks/product/useProductUnitList";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";

export const SelectPackagingProductUnit = ({
  selectedProduct,
  setSelectedProduct,
  selectedProducts = [],
}: any) => {
  const [search, setSearch] = useState<string>("");

  const {
    queries: [{ data: dataProduct }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([
    useProductUnitList({
      "filter[is_generate_qr]": 0,
    }),
  ] as const);
  const dataList = dataProduct?.pages?.flatMap((page) => page.data);
  const handleProductSelect = (product: any) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    refetch();

    return;
  }, [search]);
  return (
    <Box>
      <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
        Select Packaging
      </h3>
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={dataList?.map((item: any) => ({
            value: item.id,
            label: item.name,
            price: item.price,
            uom: item.uom.name,
            id: item.id,
          }))}
          placeholder="Select Product"
          closeMenuOnSelect={true}
          value={selectedProduct}
          onChange={handleProductSelect}
        />
      </FormControl>
    </Box>
  );
};
