import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useConvertQuotationMutation from "../../../api/hooks/invoice/useConvertQuotationMutation";
import usePreviewConvertQuotationMutation from "../../../api/hooks/invoice/usePreviewConvertMutation";
import { formatCurrency } from "../../../components/atoms/formatCurrency";
import { SelectWarehouseList } from "../../../components/molecules/selectWarehouseList";
import { COLOR_PRIMARY, COLOR_RED, COLOR_YELLOW } from "../../../Helper/theme";

export default function ConvertQuotationModal({ data }: any) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [warehouse, setWarehouse] = useState<any>();
  const { mutateAsync: getPreview, data: previewData } =
    usePreviewConvertQuotationMutation();
  const { mutateAsync } = useConvertQuotationMutation();
  const calculateValue = (percentage: number, total: number) => {
    return (percentage / 100) * total;
  };
  const handlePreview = () => {
    getPreview({
      shipment_fee: data.shipment_fee,
      description: data.description,
      warehouseId: warehouse.value,
      discount: data.additional_discount,
      transactionDate: moment(data.transaction_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      estimatedDate: moment(data.shipment_estimation_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      items: data.details.map((e: any, index: number) => ({
        product_unit_id: e?.product_unit?.id,
        qty: e?.qty,
        unit_price: e?.unit_price,
        total_price: e?.total_price,
        discount: e.discount,
        tax: e.tax,
        warehouse_id: warehouse.value,
      })),
      name: "",
      address: "",
      phone: "",
      voucher: data.voucher_code,
      selectedCustomerId: data.reseller.id,
      type: data.type,
      orderId: data.id,
      is_additional_discount_percentage: "",
    }).then(() => onOpen());
  };
  console.log(data.details, "ehek");

  const handleSubmit = () => {
    mutateAsync({
      shipment_fee: data.shipment_fee,
      description: data.description,
      warehouseId: warehouse.value,
      discount: data.additional_discount,
      transactionDate: moment(data.transaction_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      estimatedDate: moment(data.shipment_estimation_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      items: data.details.map((e: any, index: number) => ({
        product_unit_id: e?.product_unit?.id,
        qty: e?.qty,
        unit_price: e?.unit_price,
        total_price: e?.total_price,
        discount: e.discount,
        tax: e.tax,
        warehouse_id: warehouse.value,
      })),
      name: "",
      address: "",
      phone: "",
      voucher: data.voucher_code,
      selectedCustomerId: data.reseller.id,
      type: data.type,
      orderId: data.id,
    }).then(() => onOpen());
  };
  useEffect(() => {
    if (warehouse) {
      handlePreview();
    }
  }, [warehouse]);
  return (
    <div>
      <Button onClick={onOpen} bg={COLOR_PRIMARY} color="white" w={"100%"}>
        Convert quotation
      </Button>
      <Modal size={"5xl"} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="flex">
            <Text mr={2}>Convert Quotation</Text>
            <Button
              onClick={() =>
                getPreview({
                  shipment_fee: data.shipment_fee,
                  description: data.description,
                  warehouseId: warehouse.value,
                  discount: data.additional_discount,
                  transactionDate: moment(data.transaction_date).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  estimatedDate: moment(
                    data.shipment_estimation_datetime
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  items: data.details.map((e: any, index: number) => ({
                    product_unit_id: e?.product_unit?.id,
                    qty: e?.qty,
                    unit_price: e?.unit_price,
                    total_price: e?.total_price,
                    discount: e.discount,
                    tax: e.tax,
                    warehouse_id: warehouse.value,
                  })),
                  name: "",
                  address: "",
                  phone: "",
                  voucher: data.voucher_code,
                  selectedCustomerId: data.reseller.id,
                  type: data.type,
                  orderId: data.id,
                  is_additional_discount_percentage: "",
                })
              }
              size={"sm"}
            >
              Refresh
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h3 style={{ fontSize: "1" }}>Select Warehouse</h3>
            <SelectWarehouseList
              selectedWarehouse={warehouse}
              setSelectedWarehouse={setWarehouse}
            />
            {!!warehouse ? (
              <table className="mt-5">
                <thead>
                  <tr className="bg-gray-200">
                    <th />
                    <th>
                      <Text textAlign={"left"} fontWeight={"medium"}>
                        Product name
                      </Text>
                    </th>

                    <th>
                      <Text fontWeight={"medium"}>Code</Text>
                    </th>
                    <td></td>

                    <th>
                      <Text fontWeight={"medium"}>PPN </Text>
                    </th>
                    <th>
                      <Text fontWeight={"medium"} w={"100px"}>
                        Quantity
                      </Text>
                    </th>
                    {/* <th>Discount</th> */}
                    <th>
                      <Text fontWeight={"medium"}>Unit Price</Text>
                    </th>
                    <th>
                      <Text fontWeight={"medium"}>Sub Price</Text>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="text-capitalize">
                  {previewData?.details?.map((item: any, index: any) => {
                    const productUnitPrice = item?.product_unit?.price || 0;
                    const quantity = item?.qty ? item.qty : 1;
                    const baseValue = productUnitPrice * quantity;
                    const discountValue =
                      (previewData?.additional_discount / 100) * baseValue;
                    const ppnValue =
                      item?.ppn === true
                        ? calculateValue(11, baseValue - discountValue)
                        : 0;

                    const totalValue = baseValue + ppnValue;
                    const total = totalValue - discountValue;

                    return (
                      <tr>
                        <td></td>
                        <td>
                          <Text
                            fontWeight={"500"}
                            color={
                              item?.qty > item?.product_unit?.stock
                                ? "red"
                                : "black"
                            }
                          >
                            {item?.product_unit?.description}
                          </Text>
                        </td>
                        <td>
                          <Text>{item?.product_unit?.code}</Text>
                        </td>

                        <td></td>
                        <td>
                          <Text>{!!item?.ppn ? "true" : "false"}</Text>
                        </td>
                        <td>
                          <Text>
                            {item?.qty > item?.product_unit?.stock ? (
                              <div className="flex">
                                <Text mr={1}>{item?.qty}</Text>
                                <Text color={"red"}>{` (${
                                  item?.product_unit?.stock - item?.qty
                                })`}</Text>
                              </div>
                            ) : (
                              item?.qty
                            )}{" "}
                          </Text>
                        </td>
                        <td>{formatCurrency(item?.unit_price)}</td>
                        <td>
                          <Text>{formatCurrency(item?.total_price)}</Text>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"medium"}>Delivery Fee</Text>
                    </td>
                    <td>
                      <Text>{formatCurrency(previewData?.shipment_fee)}</Text>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"medium"}>Discount Pameran</Text>
                    </td>
                    <td>
                      <Text>
                        {formatCurrency(previewData?.auto_discount_nominal)}{" "}
                        {`(${previewData?.auto_discount}%)`}
                      </Text>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"medium"}>Voucher</Text>
                    </td>
                    <td>
                      <Text>
                        {!!previewData?.voucher_id
                          ? `${previewData?.voucher_code} (${
                              previewData?.voucher_type === "percentage"
                                ? `${previewData?.voucher_value}%`
                                : `${formatCurrency(
                                    previewData?.voucher_value
                                  )}`
                            })`
                          : "0"}
                      </Text>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"medium"}>Additional Discount</Text>
                    </td>
                    <td>
                      <Text>
                        {formatCurrency(previewData?.additional_discount)}{" "}
                        {`(${previewData?.additional_discount_percentage}%)`}
                      </Text>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Text fontWeight={"bold"}>Total</Text>
                    </td>
                    <td>
                      <Text>{formatCurrency(previewData?.price)}</Text>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </ModalBody>
          <div className=" justify-between flex p-10">
            <Button
              w={"45%"}
              color="white"
              bg={COLOR_PRIMARY}
              onClick={handleSubmit}
            >
              Convert
            </Button>
            <Button
              onClick={() =>
                navigate("/invoice/edit", {
                  state: {
                    ...data,
                    warehouse: {
                      name: warehouse?.label,
                      id: warehouse?.value,
                    },
                  },
                })
              }
              w={"45%"}
              bg={COLOR_YELLOW}
              color={"#fff"}
            >
              Edit
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}
