import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  qty: number;
  created_at: any;
  stock_product_unit_id: number;
};

const useRepackStock = () => {
  const api = useAxios();
  return useMutation(
    ({ id, qty, stock_product_unit_id, created_at }: ParamType) => {
      return api
        .post(`stocks/${id}/repack`, {
          qty: qty,
          created_at: created_at,
          stock_product_unit_id: stock_product_unit_id,
        })
        .then((res) => {
          console.log(res, "check res");
          toast("stocks berhasil di repack");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useRepackStock;
