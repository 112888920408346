import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import useDeleteBlacklist from "../../../api/hooks/product/useDeleteBlacklist";

export function DeleteProductBlacklist({ refetch, item }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteBlacklist();
  const handleDelete = ({ item }: any) => {
    mutateAsync({
      id: item?.product_unit_id,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  return (
    <>
      <Button
        mr="1rem"
        ml="2rem"
        bg={"red"}
        color="#fff"
        size={"sm"}
        onClick={() => {
          onOpen();
        }}
      >
        Delete
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product Blactlist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Peringatan: Product Blacklist akan dihapus secara permanen.
            Lanjutkan?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => handleDelete({ item })}
              bg={"red"}
              color={"#fff"}
              ml={"1rem"}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
