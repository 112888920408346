import { Box, Button } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import MobileDeliveryDetail from "../../../screens/Delivery/mobile/indexDetail";
import { formatCurrency } from "../../atoms/formatCurrency";
import PrintDeliveryOrder from "../downloadDO";
import Header from "../header";
import { ScanModal } from "../modal/scanModal";
import "./tableStyle.css";

function TableDeliveryOrderDetail({
  data,
  date,
  SONumber,
  customer,
  address,
  phone,
  item,
  est,
  props,
}: any) {
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  return (
    <div style={{ background: "#fff" }}>
      {MobileDevice ? (
        <Box w="100%" h="100vh">
          <div className="py-5 pr-5">
            <Header
              headerName={"Delivery Order Details"}
              back={true}
              navName={"/delivery/detail/update"}
              params={props}
              edit={true}
              exportBtn
              id={data?.[0]?.delivery_order_id}
              type={"delivery-orders"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                background: "#fff",
                height: "auto",
              }}
            >
              <div className="body">
                <h3 className="bodyTextH3">Date</h3>
                <h1 className="bodyTextH1" style={{ width: "100%" }}>
                  {date}
                </h1>
              </div>

              <div className="body" style={{ width: "10%" }}>
                <h3 className="bodyTextH3">Invoice Number</h3>
                <h1 className="bodyTextH1" style={{ width: "80%" }}>
                  {SONumber}
                </h1>
              </div>

              <div className="body" style={{ width: "10%" }}>
                <h3 className="bodyTextH3">Reseller</h3>
                <h1 className="bodyTextH1" style={{ width: "100%" }}>
                  {data?.[0]?.sales_order_detail?.sales_order?.reseller?.name}
                </h1>
              </div>

              <div className="body" style={{ width: "30%" }}>
                <h3 className="bodyTextH3">Address</h3>
                <h1 className="bodyTextH1" style={{ width: "100%" }}>
                  {
                    data?.[0]?.sales_order_detail?.sales_order?.reseller
                      ?.address
                  }
                </h1>
              </div>

              <div className="body">
                <h3 className="bodyTextH3">Phone Number</h3>
                <h1 className="bodyTextH1">
                  {" "}
                  {data?.[0]?.sales_order_detail?.sales_order?.reseller?.phone}
                </h1>
              </div>

              <div className="body">
                <h3 className="bodyTextH3">Items</h3>
                <h1 className="bodyTextH1">{item}</h1>
              </div>

              <div className="body">
                <h3 className="bodyTextH3">Est. Shipment</h3>
                <h1 className="bodyTextH1">{est}</h1>
              </div>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Brand
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Unit
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Verified
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                  style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.map((item: any, index: any) => (
                <tr key={item.id} style={{ background: "#E1EAF6" }}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.sales_order_detail?.product_unit?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatCurrency(item?.sales_order_detail?.total_price)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {
                      item?.sales_order_detail?.product_unit?.product
                        ?.product_category?.name
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {
                      item?.sales_order_detail?.product_unit?.product
                        ?.product_brand?.name
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.sales_order_detail?.qty}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.sales_order_detail?.product_unit?.uom?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.sales_order_detail?.fulfilled_qty}/
                    {item?.sales_order_detail?.qty}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item?.is_done !== false ? (
                      <>
                        <ScanModal
                          data={{
                            DO: data?.[0]?.id,
                            SO: item?.id,
                          }}
                        />
                      </>
                    ) : (
                      <Button bg={"green.300"}>Finished</Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div style={{ marginTop: "1rem" }}>
            <h3 style={{ fontSize: "1rem", fontWeight: "500" }}>Notes:</h3>
            <Input placeholder="Input here" type="text" />
          </div> */}
          <PrintDeliveryOrder data={data} props={props} />
        </Box>
      ) : (
        <MobileDeliveryDetail
          data={data}
          props={props}
          header={"Delivery Order Details"}
          date={date}
          SONumber={SONumber}
          customer={customer}
          address={address}
          phone={phone}
          item={item}
          est={est}
        />
      )}
    </div>
  );
}

export default TableDeliveryOrderDetail;
