import TableReturn from "../../components/molecules/tables/tableReturn";
import { dataReturn } from "../../Helper/MockData";
import Sidebar from "../Home/components/SideBar";

function ReturnScreen() {
  return (
    <div className="flex">
      <div className="flex-grow p-8">
        <TableReturn header={"Return"} data={dataReturn} />
      </div>
    </div>
  );
}

export default ReturnScreen;
