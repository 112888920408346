import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { COLOR_FIGMA_LIGHT_BLUE } from "../../Helper/theme";
import useMasterProductList from "../../api/hooks/product/useMasterProductList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import TableProduct from "../../components/molecules/tables/tableProduct";
import { permissionState } from "../../provider/atoms";

function ProductMaster() {
  const [key, setKey] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const nav = useNavigate();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useMasterProductList({
    "filter[name]": key,
    page: currentPage,
  });
  const [dataPermission] = useRecoilState<any>(permissionState);
  const productList = data?.pages.flatMap((page) => page.data);
  const countData = data?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, key]);
  return (
    <DashboardContainer>
      {!dataPermission?.product_access?.product_access ? (
        <NoPermission />
      ) : (
        <>
          <div>
            <Header headerName={"Product"} back={true} />
          </div>
          <div
            className="row space-x-11"
            style={{ marginBottom: "2rem", display: "flex" }}
          >
            <Input
              placeholder={"Search product"}
              w={"90%"}
              bg={"white"}
              value={key}
              onChange={(e) => setKey(e?.target?.value)}
            />
            <Button
              bg={COLOR_FIGMA_LIGHT_BLUE}
              color="white"
              _hover={{ bg: COLOR_FIGMA_LIGHT_BLUE }}
              onClick={() => nav("/add-product")}
            >
              New Product
            </Button>
            {dataPermission?.product_unit_blacklist_access
              ?.product_unit_blacklist_access ? (
              <Button
                bg={COLOR_FIGMA_LIGHT_BLUE}
                color="white"
                _hover={{ bg: "black" }}
                onClick={() => nav("/product/blacklist")}
              >
                Exclude list
              </Button>
            ) : null}
          </div>
          <TableProduct
            header={"Product"}
            data={productList}
            col2={"Name"}
            col3={"Category"}
            col4={"Brand"}
            col5={"Action"}
            inpName="Search Product name"
            btnName="New Product"
            btnNav="/add-product"
            btnName2="Exclude List"
            btnNav2="/product/blacklist"
            navName="/product/detail"
          />
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </DashboardContainer>
  );
}

export default ProductMaster;
