import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_YELLOW } from "../../Helper/theme";
import { useAxios } from "../../api/hooks/useApi";
import useWarehouseList from "../../api/hooks/warehouse/useWarehouseList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import { permissionState } from "../../provider/atoms";
import { queryClient } from "../../provider/query";

export default function Warehouse() {
  const [search, setSearch] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [name, setName] = useState<any>("");
  const [code, setCode] = useState<any>("");
  const [dealer, setDealer] = useState<any>("");
  const api = useAxios();
  const handleSubmit = async () => {
    try {
      const res = await api.post(`warehouses`, {
        name: name,
        code: code,
        company_name: dealer,
      });
      queryClient.invalidateQueries("WarehouseList");
      toast("Warehouse berhasil dibuat");
      onClose();
      setName("");
      setCode("");
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useWarehouseList({
    "filter[name]": search,
  });
  useEffect(() => {
    refetch();
  }, [search]);
  const warehouse = data?.pages?.flatMap((page) => page.data);
  return (
    <DashboardContainer>
      {!dataPermission?.warehouse_access?.warehouse_access ? (
        <NoPermission />
      ) : (
        <>
          <Header headerName="Warehouse" back={true} />
          <div
            className="row space-x-11"
            style={{ marginBottom: "2rem", display: "flex" }}
          >
            <Input
              placeholder={"Search Warehouse"}
              w={"90%"}
              bg={"white"}
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
            <Button
              bg={COLOR_FIGMA_LIGHT_BLUE}
              color="white"
              _hover={{ bg: COLOR_FIGMA_LIGHT_BLUE }}
              onClick={() => onOpen()}
            >
              + Warehouse
            </Button>
          </div>
          <WarehouseTable data={warehouse} />
          <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>New Warehouse</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text my={2}>Warehouse Name</Text>
                <Input
                  placeholder="input name warehouse"
                  value={name}
                  mt={"0.5rem"}
                  onChange={(e) => setName(e?.target?.value)}
                />

                <Text my={2}>Code</Text>
                <Input
                  placeholder="input code warehouse"
                  value={code}
                  mt={"0.5rem"}
                  onChange={(e) => setCode(e?.target?.value)}
                />
                <Text my={2}>Dealer Name</Text>
                <Input
                  placeholder="input code warehouse"
                  value={dealer}
                  mt={"0.5rem"}
                  onChange={(e) => setDealer(e?.target?.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  size={"md"}
                  onClick={handleSubmit}
                  mt={"0.5rem"}
                  bg={COLOR_FIGMA_LIGHT_BLUE}
                  color="#fff"
                >
                  Create warehouse
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </DashboardContainer>
  );
}

const WarehouseTable = ({ data }: any) => {
  const nav = useNavigate();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Warehouse Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Created at
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => {
            console.log(item, "check data item nya");
            return (
              <tr key={item.id}>
                <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.code}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {moment(item?.created_at).format("L")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Flex>
                    <Button
                      mr={5}
                      size="sm"
                      bg={COLOR_YELLOW}
                      color="#fff"
                      onClick={() =>
                        nav("/warehouse/update-warehouse", {
                          state: {
                            id: item?.id,
                            name: item?.name,
                            code: item?.code,
                          },
                        })
                      }
                    >
                      Edit
                    </Button>
                    {/* <Button size="sm" bg={"#d63031"} color="#fff">
                    Delete
                  </Button> */}
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
