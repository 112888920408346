import React, { useState } from "react";
import "./table.css";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useDeleteRO from "../../../api/hooks/receiveOrder/useDeleteRO";
import moment from "moment";
import { toast } from "react-toastify";
import { useAxios } from "../../../api/hooks/useApi";
import { SubmitReceive } from "../verify/submitReceive";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";

const TableSort = ({ data, refetch, Loading }: any) => {
  const [realData, setRealData] = useState(data);
  const [order, setOrder] = useState("ASC");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const api = useAxios();
  const { mutateAsync } = useDeleteRO();
  const handleDelete = () => {
    if (selectedItem) {
      // console.log(selectedItem.id, "check id delete");
      // onClose()
      mutateAsync({
        receiveId: selectedItem?.id,
      }).then(() => {
        refetch();
        onClose();
      });
    }
  };
  const selectItemAndOpenModal = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  const ModalDelete = ({ item }: any) => {
    return (
      <>
        <IconButton
          // colorScheme='#800000'
          bg={"#800000"}
          ml="0.5rem"
          aria-label="Delete"
          size="xs"
          icon={<DeleteIcon />}
          onClick={() => selectItemAndOpenModal(item)}
          color={"#fff"}
        />

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Receive Order</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Peringatan: Pesanan akan dihapus secara permanen. Lanjutkan?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={handleDelete}
                bg={"red"}
                color={"#fff"}
                ml={"1rem"}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  const handleClick = (item: any) => {
    navigate("/ro-detail", { state: item });
  };
  const sorting = (col: any) => {
    if (order === "ASC") {
      const sorted = [...realData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRealData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...realData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRealData(sorted);
      setOrder("DSC");
    }
  };

  return (
    <div>
      <table className="table-auto">
        <thead className="text-[10px] text-white h-[50px] uppercase bg-[#F8FBFF]  ">
          <th
            onClick={() => sorting("date")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "10rem",
            }}
          >
            Date
          </th>
          <th
            onClick={() => sorting("number")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "15rem",
            }}
          >
            RO Number
          </th>
          <th
            onClick={() => sorting("number")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "15rem",
            }}
          >
            Created by
          </th>
          <th
            onClick={() => sorting("items")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "10rem",
            }}
          >
            Items
          </th>
          <th
            onClick={() => sorting("warehouse")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "15rem",
            }}
          >
            Warehouse
          </th>
          <th
            onClick={() => sorting("supplier")}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "15rem",
            }}
          >
            Supplier
          </th>
          <th
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "1em",
              width: "10rem",
            }}
          >
            Status
          </th>
          <th style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>
            Action
          </th>
        </thead>
        <tbody className="text-[12px]">
          {data?.map((item: any) => {
            return (
              <tr key={item?.id} style={{ marginTop: "0.5rem" }}>
                <td>{moment(item?.receive_datetime).format("DD MMMM YYYY")}</td>
                <td>{item?.invoice_no}</td>
                <td>{item?.user?.name}</td>
                <td>{item?.details_count}</td>
                <td>{item?.warehouse_string_id}</td>
                <td>{item?.supplier?.name}</td>
                <td>
                  <Badge
                    colorScheme={item?.is_done === false ? "yellow" : "green"}
                    variant={"solid"}
                    size={"lg"}
                    fontSize={"1em"}
                  >
                    {item?.is_done === false ? "Pending" : "Complete"}
                  </Badge>
                </td>
                <td className="min-w-[200px]">
                  <Box textAlign="left">
                    {item?.is_done === false ? (
                      <SubmitReceive
                        data={item}
                        refetch={refetch}
                        isLoading={Loading}
                      />
                    ) : null}
                    <IconButton
                      ml={"0.5rem"}
                      colorScheme="yellow"
                      aria-label="View"
                      size="xs"
                      icon={<ViewIcon />}
                      onClick={() => handleClick(item)}
                      color="#fff"
                    />
                    {/* <Button size={"sm"} onClick={() => handleClick(item)}>
                      See Detail
                    </Button> */}
                    {item?.is_done === false ? <ModalDelete item={item} /> : ""}
                  </Box>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableSort;
