import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  opnameDetailId: number;
  qrcode: string;
  opnameId: number;
};

const useScanOpnameMutation = () => {
  const api = useAxios();
  return useMutation(({ opnameDetailId, qrcode, opnameId }: ParamType) => {
    return api
      .put(`stock-opnames/${opnameId}/details/${opnameDetailId}/scan `, {
        stock_id: qrcode,
      })
      .then((res) => {
        toast("Code berhasil di scan");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useScanOpnameMutation;
