import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import "./App.css";
import { AuthProvider } from "./provider/auth";
import { queryClient } from "./provider/query";
import { router } from "./routes";

function App() {
  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        <AuthProvider>
          <RecoilRoot>
            <RouterProvider router={router} />
            <ToastContainer
              autoClose={3000}
              position="bottom-right"
              theme="light"
              progressStyle={{ backgroundColor: "black" }}
            />
          </RecoilRoot>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
