import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../../Helper/theme";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import useDeleteCustomer from "../../../api/hooks/user/useDeleteCustomer";
import { useState } from "react";
function TableCustomer({
  data,
  navName,
  edit = false,
  del = false,
  refetch,
}: any) {
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteCustomer();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const handleDelete = () => {
    if (selectedItem) {
      // console.log(selectedItem.id, "check id delete");
      // onClose()
      mutateAsync({
        id: selectedItem?.id,
      }).then(() => {
        refetch();
        onClose();
      });
    }
  };
  const selectItemAndOpenModal = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  const ModalDelete = ({ item }: any) => {
    return (
      <>
        <IconButton
          colorScheme="red"
          aria-label="Delete"
          icon={<DeleteIcon />}
          onClick={() => selectItemAndOpenModal(item)}
          ml="0.5rem"
          size={"sm"}
          color={"#fff"}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg={"transparent"} />
          <ModalContent>
            <ModalHeader>Delete Customer</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Peringatan: Customer akan di hapus secara permanen. Lanjutkan?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                // onClick={() => handleDelete({ item })}
                onClick={handleDelete}
                bg={"red"}
                color={"#fff"}
                ml={"1rem"}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              No.
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Customer Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Email
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.code}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.phone}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <IconButton
                  ml={"0.5rem"}
                  colorScheme="yellow"
                  aria-label="View"
                  size="sm"
                  icon={<ViewIcon />}
                  onClick={() =>
                    nav("/customer/update-customer", { state: item })
                  }
                  color="#fff"
                />
                <ModalDelete item={item} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableCustomer;
