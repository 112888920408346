import { debounce } from "lodash";
import moment from "moment";
import { useState } from "react";
import { formatCurrency } from "../../atoms/formatCurrency";
import Header from "../header";
import PrintSalesOrder from "../printSalesOrder";
import "./tableStyle.css";
import { Flex, Text } from "@chakra-ui/react";

function TableSalesOrderDetail({ data, header }: any) {
  const [name, setName] = useState("");
  const shipementFee = data?.shipment_fee || 0;
  const handleSearch = debounce((searchTerm) => {
    console.log(`Searching for ${searchTerm}...`);
  }, 500);

  let totalAmount = 0;
  console.log(data, "e");
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg">
      <div className="py-5 pr-5">
        <Header
          headerName={data?.invoice_no}
          back={true}
          navName={"/sales/edit-shipment"}
          exportBtn={true}
          edit={true}
          id={data?.id}
          type={"sales-orders"}
          params={data}
        />
        <div>
          <Flex className="justify-between py-2">
            <h3>Date</h3>
            <h2 className="font-medium">
              {moment(data?.transaction_date).format("D MMMM YYYY")}
            </h2>
          </Flex>

          <Flex className="justify-between py-2">
            <h3>Customer</h3>
            <h2 className="font-medium">{data?.reseller?.name}</h2>
          </Flex>

          <Flex className="justify-between py-2">
            <h3>Phone Number</h3>
            <h2 className="font-medium">{data?.reseller?.phone}</h2>
          </Flex>

          <Flex className="justify-between py-2">
            <h3>Items</h3>
            <h2 className="font-medium">{data?.details?.length}</h2>
          </Flex>
          <Flex className="justify-between py-2">
            <h3>Est. Shipment</h3>
            <h2 className="font-medium">
              {moment(data?.shipment_estimation_datetime).format("D MMMM YYYY")}
            </h2>
          </Flex>
          <Flex className="justify-between py-2 ">
            <h3>Address</h3>
            <h2 className="text-right font-medium w-[50%] ">
              {data?.reseller?.address}
            </h2>
          </Flex>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Item No
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Brand
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Qty
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Unit
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Discount
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              PPN
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Price
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.details?.map((item: any) => {
            const total_price = item?.unit_price * item?.qty;
            const discount = (item?.discount / 100) * total_price;
            const price_with_discount = total_price - discount;
            const tax = item?.tax / 100;
            const price_before_tax = price_with_discount * tax;
            const price_after_tax = price_with_discount + price_before_tax;
            totalAmount += price_after_tax;
            return (
              <>
                <tr key={item.id} style={{ background: "#E1EAF6" }}>
                  <td className="px-6 py-4 ">{item?.product_unit?.code}</td>
                  <td className="px-6 py-4 ">
                    {item?.product_unit?.description}
                    {item?.packaging_id ? "*" : null}
                  </td>
                  <td className="px-6 py-4 ">
                    {item?.product_unit?.product?.product_category?.name}
                  </td>
                  <td className="px-6 py-4 ">
                    {item?.product_unit?.product?.product_brand?.name}
                  </td>
                  <td className="px-6 py-4 ">{item?.qty}</td>
                  <td className="px-6 py-4 ">
                    {item?.product_unit?.uom?.name}
                  </td>
                  <td className="px-6 py-4 ">{item?.discount}%</td>
                  <td className="px-6 py-4 ">
                    {item?.tax === 0 ? "-" : `${item?.tax}%`}
                  </td>
                  <td className="px-6 py-4 ">
                    {formatCurrency(item?.unit_price)}
                  </td>

                  <td className="px-6 py-4 ">
                    {formatCurrency(price_after_tax)}
                  </td>
                </tr>
                {item?.packaging_id !== null ? (
                  <tr key={item.id} style={{ background: "#ffffff" }}>
                    <td className="px-6 ">{item?.packaging?.code}</td>
                    <td className="px-6  ">{item?.packaging?.name}</td>
                    <td className="px-6 ">Packaging</td>
                    <td className="px-6 "></td>
                    <td className="px-6 ">{item?.qty}</td>
                    <td className="px-6 ">{item?.product_unit?.uom?.name}</td>
                    <td className="px-6 "></td>
                    <td className="px-6 "></td>
                    <td className="px-6 ">
                      {formatCurrency(item?.packaging?.price)}
                    </td>
                    <td className="px-6 ">
                      {formatCurrency(item?.packaging?.price)}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
          <tr style={{ background: "#C1CBDA" }}>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              Sub Total:
            </td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              {formatCurrency(totalAmount)}
            </td>
          </tr>
          <tr style={{ background: "#C1CBDA" }}>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              Delivery Fee:
            </td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              {formatCurrency(data?.shipment_fee)}
            </td>
          </tr>
          <tr style={{ background: "#C1CBDA" }}>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              Voucher:
            </td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              {formatCurrency(data?.additional_discount)}
            </td>
          </tr>
          <tr style={{ background: "#C1CBDA" }}>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 "></td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              Total:
            </td>
            <td className="px-6 py-4 " style={{ fontWeight: "medium" }}>
              {formatCurrency(data?.price)}
            </td>
            {/* <td
              className="px-6 py-4 "
              style={{ fontWeight: "medium" }}
            >
              {formatCurrency(totalPrice)}
            </td> */}
          </tr>
        </tbody>
      </table>
      <div>
        <Text fontWeight={"medium"} fontSize={"1em"}>
          Notes:
        </Text>
        <Text fontSize={"1em"}>{data?.description}</Text>
      </div>
      <PrintSalesOrder data={data} />
    </div>
  );
}

export default TableSalesOrderDetail;
