import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  product: any;
};

const useAttachProductMutation = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(({ product, id }: ParamType) => {
    return api
      .post(`delivery-orders/${id}/attach`, {
        sales_order_detail_ids: product,
      })
      .then((res) => {
        toast("Product berhasil ditambah");
      })
      .catch((err) => {
        console.log(err, "test");
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useAttachProductMutation;
