import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import useGroupingMutation from "../../../api/hooks/uoms/useGroupingMutation";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";

export function GroupingStock({ refetch, data, type }: any) {
  const [desc, setDesc] = useState("");
  const [qty, setQty] = useState<any>(0);
  const [totalGroup, setTotalGroup] = useState<any>(0);
  const { mutateAsync } = useGroupingMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleSubmit = (val: any) => {
    mutateAsync({
      productUnitId: data?.id,
      desc: desc,
      qty: parseInt(qty),
      warehouse_id: data?.warehouse_id,
      total_group: totalGroup,
      type: type,
    }).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <>
      <Button
        w={"10rem"}
        ml="2rem"
        bg={"#2E79FF"}
        color="#fff"
        // size={"lg"}
        onClick={() => {
          onOpen();
        }}
        isDisabled={!dataPermission?.stock_access?.stock_grouping}
      >
        Grouping
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How many do you want to grouping?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div style={{ padding: "0.5rem" }}>
                <h3 style={{ fontSize: "1em", fontWeight: "bold" }}>Group</h3>
                <Input
                  mt="0.5rem"
                  placeholder="Input here"
                  inputMode="text"
                  onChange={(val) => setDesc(val?.target?.value)}
                />
              </div>

              <div style={{ padding: "0.5rem" }}>
                <h3 style={{ fontSize: "1em", fontWeight: "bold" }}>
                  Qty/Group
                </h3>
                <Input
                  mt="0.5rem"
                  placeholder="Input here"
                  inputMode="numeric"
                  onChange={(val) => setQty(val?.target?.value)}
                />
              </div>
              <div style={{ padding: "0.5rem" }}>
                <h3 style={{ fontSize: "1em", fontWeight: "bold" }}>
                  Total Group
                </h3>
                <Input
                  mt="0.5rem"
                  placeholder="Input here"
                  inputMode="numeric"
                  onChange={(val) => setTotalGroup(val?.target?.value)}
                />
              </div>

              <div style={{ padding: "0.5rem" }}>
                <h3
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    width: "10rem",
                  }}
                >
                  Required Stock
                </h3>
                <Input
                  mt="0.5rem"
                  placeholder="Input here"
                  inputMode="numeric"
                  disabled={true}
                  value={qty * totalGroup}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "0.5rem",
              }}
            >
              <Button
                alignSelf={"center"}
                w="8rem"
                color="#fff"
                bg="#2E79FF"
                onClick={handleSubmit}
              >
                Create Group
              </Button>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
