import { Box, Button } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import PrintDeliveryOrder from "../../../components/molecules/downloadDO";
import Header from "../../../components/molecules/header";
import "../../../components/molecules/tables/tableStyle.css";
import moment from "moment";

function MobileSupplierDetail({ data, header }: any) {
  const [name, setName] = useState("");
  const location = useLocation();
  const nav = useNavigate();
  const handleSearch = debounce((searchTerm) => {
    // Do something with the search value, e.g. send a request to an API
    console.log(`Searching for ${searchTerm}...`);
  }, 500);
  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setName(searchTerm);
    handleSearch(searchTerm);
  };
  return (
    <div style={{ background: "#fff" }}>
      <div>
        <div className="py-5 pr-5">
          <Header
            headerName={header}
            back={true}
            navName={"/sales-order"}
            exportBtn={true}
            id={data?.id}
            type={"sales-orders"}
          />
          <div>
            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Date</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {moment(data?.created_at).format("YYYY-MM-DD")}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">SO Number</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {data?.invoice_no}
                </h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Reseller</h3>
                <h1 className="bodyTextH1Mobile" style={{ width: "100%" }}>
                  {data?.reseller?.name}
                </h1>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="bodys"
                style={{
                  width: "50%",
                }}
              >
                <h3 className="bodyTextH3Mobile">Address</h3>
                <h1 className="bodyTextH1Mobile">{data?.reseller?.address}</h1>
              </div>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Phone Number</h3>
                <h1 className="bodyTextH1Mobile">{data?.reseller?.phone}</h1>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Items</h3>
                <h1 className="bodyTextH1Mobile">{data?.details_count}</h1>
              </div>

              <div className="bodys">
                <h3 className="bodyTextH3Mobile">Est. Shipment</h3>
                <h1 className="bodyTextH1Mobile">
                  {moment(data?.shipment_estimation_datetime).format(
                    "YYYY-MM-DD"
                  )}
                </h1>
              </div>
            </div>
          </div>
          <Box p={10}>
            {data?.details.map((item: any) => (
              <div
                style={{
                  background: "#fff",
                  borderRadius: 10,
                  marginBottom: "1rem",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      padding: "1rem",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      {item?.product_unit?.code}
                    </h1>
                    <h1>{item?.product_unit?.name}</h1>
                    <h1>
                      {item?.product_unit?.product?.product_brand?.name}
                      &nbsp;-&nbsp;
                      {item?.product_unit?.product?.product_category?.name}
                    </h1>
                    <h1>
                      @{item?.qty}&nbsp;
                      {item?.product_unit?.uom?.name.toUpperCase()}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default MobileSupplierDetail;
