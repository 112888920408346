import { useLocation } from "react-router-dom";
import useDeliveryOrderDetail from "../../api/hooks/deliveryOrder/useDeliveryOrderDetail";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import TableDeliveryOrder from "../../components/molecules/tables/tableDeliveryOrder";
import TableDeliveryOrderDetail from "../../components/molecules/tables/tableDeliveryOrderDetail";
import { dataReturn } from "../Helper/MockData";
import { useState } from "react";
import moment from "moment";

function DeliveryDetail() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const dataProps = location.state;
  const {
    queries: [{ data: dataTable }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([
    useDeliveryOrderDetail({
      id: dataProps?.id,
    }),
  ] as const);
  const dataList = dataTable?.pages.flatMap((page) => page.data);
  return (
    <div className="py-5 pr-5">
      <TableDeliveryOrderDetail
        data={dataList}
        props={dataProps}
        header={dataProps?.code}
        date={moment(dataProps?.date).format("L")}
        SONumber={dataProps?.soNumber}
        customer={dataProps?.customer}
        address={dataProps?.address}
        phone={dataProps?.phone}
        item={dataProps?.code}
        est={moment(dataProps?.est).format("L")}
      />
    </div>
  );
}

export default DeliveryDetail;
