import { Button, Input, Select, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../Helper/theme";
import useUpdateSupplier from "../../api/hooks/supplier/useUpdateSupplier";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";

function FormUpdateSupplier() {
  const { mutateAsync } = useUpdateSupplier();
  const nav = useNavigate();
  const location = useLocation();
  const dataProps = location.state;
  const [name, setName] = useState<string>(dataProps?.name);
  const [email, setEmail] = useState<string>(dataProps?.email);
  const [phone, setPhone] = useState<string>(dataProps?.phone);
  const [description, setDescription] = useState<string>(
    dataProps?.description
  );
  const [code, setCode] = useState<string>(dataProps?.code);
  const [address, setAddress] = useState<string>(dataProps?.address);

  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: name,
      email: email,
      phone: phone,
      address: address,
      code: code,
      description: description,
    });
    nav("/supplier");
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update Supplier" />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Name
      </Text>
      <Input
        placeholder="input name here"
        value={name}
        mt={"0.5rem"}
        onChange={(e) => setName(e?.target?.value)}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Email
      </Text>
      <Input
        placeholder="input email here"
        value={email}
        mt={"0.5rem"}
        onChange={(e) => setEmail(e?.target?.value)}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Phone
      </Text>
      <div style={{ display: "flex" }}>
        <Select
          placeholder="+62"
          w={"6rem"}
          size={"lg"}
          disabled={true}
        ></Select>
        <Input
          placeholder="input phone here"
          value={phone}
          ml={"0.5rem"}
          size={"lg"}
          onChange={(e) => setPhone(e?.target?.value)}
        />
      </div>

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Code
      </Text>
      <Input
        placeholder="input address here"
        value={code}
        mt={"0.5rem"}
        onChange={(e) => setCode(e?.target?.value)}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Address
      </Text>
      <Input
        placeholder="input address here"
        value={address}
        mt={"0.5rem"}
        onChange={(e) => setAddress(e?.target?.value)}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Description
      </Text>
      <Input
        placeholder="input description here"
        value={description}
        mt={"0.5rem"}
        onChange={(e) => setDescription(e?.target?.value)}
      />

      <Button
        size={"md"}
        onClick={handleSubmit}
        mt={"0.5rem"}
        bg={COLOR_YELLOW}
        color="#fff"
      >
        Update
      </Button>
    </DashboardContainer>
  );
}

export default FormUpdateSupplier;
