import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  description: string;
  resellerId: string;
  invoice_no: string;
  shipment_fee: number;
  warehouseId: string;
  transactionDate: any;
  estimatedDate: any;
  items: any;
  discount: number;
};

const useCreateSOMutation = () => {
  const api = useAxios();
  const nav = useNavigate();

  return useMutation(
    ({
      description,
      resellerId,
      warehouseId,
      transactionDate,
      invoice_no,
      shipment_fee,
      estimatedDate,
      items,
      discount,
    }: ParamType) => {
      return api
        .post(`sales-orders`, {
          invoice_no: invoice_no,
          shipment_fee: shipment_fee,
          description: description,
          reseller_id: resellerId,
          warehouse_id: warehouseId,
          transaction_date: transactionDate,
          shipment_estimation_datetime: estimatedDate,
          items: items,
          additional_discount: discount,
        })
        .then((res) => {
          console.log(res, "tto");
          nav(-1);
          toast("Sales Order berhasil dibuat");
        })
        .catch((err) => {
          console.log(err, "err");
          toast("Something went wrong");
        });
    }
  );
};

export default useCreateSOMutation;
