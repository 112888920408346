import { Center, Box, Image, Text } from "@chakra-ui/react";

export default function NoPermission() {
  return (
    <Center h="100vh">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Image
          src={require("../../../assets/icon/NoAccess.png")}
          h={200}
          w={200}
        />
        <Text fontWeight="bold" fontSize={18}>
          Anda tidak memiliki akses layar ini
        </Text>
      </Box>
    </Center>
  );
}
