import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  description: string;
  date: any;
};

const useImportMutation = () => {
  const api = useAxios();
  return useMutation(({ name, description, date }: ParamType) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("receive_datetime", date);
    return api
      .post(`receive-orders`, formData)
      .then((res) => {
        toast("Reseller berhasil dibuat");
      })
      .catch((err) => {
        console.log(err, "error");
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useImportMutation;
