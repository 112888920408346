import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import useCreateDOMutation from "../../../api/hooks/deliveryOrder/useCreateDOMutation";
import { permissionState } from "../../../provider/atoms";
import { SelectCustomerList } from "../selectCustomerList";
import { SelectWarehouseList } from "../selectWarehouseList";

export function DeliveryOrder({ refetch, data }: any) {
  const [code, setCode] = useState("");
  const [desc, setDesc] = useState<any>();
  const [invoice, setInvoice] = useState<any>();
  const [pin, setPin] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [estimatedDate, setEstimatedDate] = useState<any>();
  const [warehouse, setWarehouse] = useState<any>();
  const [transactionDate, setTransactionDate] = useState<any>();
  const { mutateAsync } = useCreateDOMutation();
  const initialPin = "";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handlePinChange = (value: any, index: any) => {
    const newPin = pin.split("");
    newPin[index] = value;
    setPin(newPin.join(""));
  };
  const handleSubmit = (val: any) => {
    setIsSubmitting(true);
    mutateAsync({
      invoice_no: invoice,
      warehouse_id: warehouse?.value,
      reseller_id: selectedCustomer?.value,
      shipment_estimation_datetime: moment(estimatedDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      transaction_date: moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      description: desc,
    }).then(() => {
      refetch();
      onClose();
    });

    setCode("");
    setInvoice(undefined);
    setDesc(undefined);
    setPin(initialPin);
    setIsSubmitting(false);
  };
  return (
    <>
      {MobileDevice ? (
        <>
          <Button
            w={"10rem"}
            ml="2rem"
            bg={"#2E79FF"}
            size="sm"
            color="#fff"
            isDisabled={
              !dataPermission?.delivery_order_access?.delivery_order_create
            }
            // size={"lg"}
            onClick={() => {
              onOpen();
            }}
          >
            + Delivery Order
          </Button>
          <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>New Delivery Order</ModalHeader>
              <ModalCloseButton />

              <ModalBody>
                <div style={{ marginBottom: "0.5rem" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: "1",
                      marginBottom: "1rem",
                    }}
                  >
                    Deliver No
                  </h3>
                  <Input
                    placeholder="PAS/DO/11/23/01...."
                    onChange={(e) => setInvoice(e.target.value)}
                  />
                </div>
                <SelectCustomerList
                  type="reseller"
                  selected={selectedCustomer}
                  setSelected={setSelectedCustomer}
                />
                <h3
                  style={{
                    fontWeight: 500,
                    fontSize: "1",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Warehouse
                </h3>
                <SelectWarehouseList
                  selectedWarehouse={warehouse}
                  setSelectedWarehouse={setWarehouse}
                />
                <Box my={"1rem"}>
                  <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                    Estimated Date
                  </FormLabel>
                  <DatePicker
                    border
                    selected={estimatedDate}
                    showIcon
                    showPopperArrow={false}
                    onChange={(date) => setEstimatedDate(date)}
                    placeholderText="Select a date"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Box>
                <Box my={"1rem"}>
                  <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                    Transaction Date
                  </FormLabel>
                  <DatePicker
                    border
                    selected={transactionDate}
                    showIcon
                    showPopperArrow={false}
                    onChange={(date) => setTransactionDate(date)}
                    placeholderText="Select a date"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Box>
                <div style={{ marginTop: "0.5rem" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: "1",
                      marginBottom: "1rem",
                    }}
                  >
                    Description
                  </h3>
                  <Textarea
                    placeholder="Input here...."
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0.5rem",
                  }}
                >
                  <Button
                    alignSelf={"center"}
                    w="8rem"
                    color="#fff"
                    bg="#01338C"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Add"}
                  </Button>
                </div>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <>
          <Button
            w={"10rem"}
            bg={"#2E79FF"}
            color="#fff"
            // size={"lg"}
            isDisabled={
              !dataPermission?.delivery_order_access?.delivery_order_create
            }
            onClick={() => {
              onOpen();
            }}
          >
            + Delivery Order
          </Button>
          <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>New Delivery Order</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div style={{ marginTop: "0.5rem" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: "1",
                      marginBottom: "1rem",
                    }}
                  >
                    Deliver No
                  </h3>
                  <Input
                    placeholder="PAS/DO/11/23/01...."
                    onChange={(e) => setInvoice(e.target.value)}
                  />
                </div>
                <SelectCustomerList
                  type="reseller"
                  selected={selectedCustomer}
                  setSelected={setSelectedCustomer}
                />
                <h3
                  style={{
                    fontWeight: 500,
                    fontSize: "1",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Warehouse
                </h3>
                <SelectWarehouseList
                  selectedWarehouse={warehouse}
                  setSelectedWarehouse={setWarehouse}
                />
                <Box my={"1rem"}>
                  <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                    Estimated Date
                  </FormLabel>
                  <DatePicker
                    border
                    selected={estimatedDate}
                    showIcon
                    showPopperArrow={false}
                    onChange={(date) => setEstimatedDate(date)}
                    placeholderText="Select a date"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Box>
                <Box my={"1rem"}>
                  <FormLabel fontWeight={"medium"} fontSize={"1rem"}>
                    Transaction Date
                  </FormLabel>
                  <DatePicker
                    border
                    selected={transactionDate}
                    showIcon
                    showPopperArrow={false}
                    onChange={(date) => setTransactionDate(date)}
                    placeholderText="Select a date"
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Box>
                <div style={{ marginTop: "0.5rem" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: "1",
                      marginBottom: "1rem",
                    }}
                  >
                    Description
                  </h3>
                  <Textarea
                    placeholder="Input here...."
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0.5rem",
                  }}
                >
                  <Button
                    alignSelf={"center"}
                    w="8rem"
                    color="#fff"
                    bg="#01338C"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Add"}
                  </Button>
                </div>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
