import React from "react";
import Header from "../../components/molecules/header";
import { Button } from "@chakra-ui/react";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import useMasterBlackList from "../../api/hooks/product/useMasterBlacklist";
import { DeleteProductBlacklist } from "../../components/molecules/modal/DeleteProductBlacklist";

const ProductBlackList = () => {
  const {
    queries: [{ data: dataTable }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([useMasterBlackList()] as const);
  const paginatedData = dataTable?.pages?.flatMap((page) => page.data);
  return (
    <div>
      <Header
        back={true}
        headerName={"Product Blacklist"}
        blacklist={true}
        refetch={refetch}
      />
      <TableProductBlacklist data={paginatedData} refetch={refetch} />
    </div>
  );
};

const TableProductBlacklist = ({ data, refetch, item }: any) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Item No.
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Description
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Category
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Brand
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Unit
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody
        className="bg-white divide-y divide-gray-200"
        style={{ borderRadius: "10px 0 0 10px", background: "#e1eaf6" }}
      >
        {data?.map((item: any) => (
          <tr key={item?.id}>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product_unit?.code}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product_unit?.description}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product_unit?.product?.product_category?.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product_unit?.product?.product_brand?.name}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product_unit?.uom?.name}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
              <DeleteProductBlacklist refetch={refetch} item={item} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductBlackList;
