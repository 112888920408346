import "./tableStyle.css";
import Header from "../header";

function TableReturnDetail({ data, col1, col2, col3, col4, col5, col6 }: any) {
  return (
    <div>
      <div className="py-5 pr-5">
        <Header headerName={"RID12345"} back={true} navName={"/return"} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            background: "#fff",
            height: "5rem",
          }}
        >
          <div className="body">
            <h3 className="bodyTextH3">Date</h3>
            <h1 className="bodyTextH1">28 Februari 2022</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">DO Number</h3>
            <h1 className="bodyTextH1">SN12345</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">Customer</h3>
            <h1 className="bodyTextH1">Jakarta Koi</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">Address</h3>
            <h1 className="bodyTextH1">Jakarta Koi</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">Phone Number</h3>
            <h1 className="bodyTextH1">Jakarta Koi</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">Items</h3>
            <h1 className="bodyTextH1">Jakarta Koi</h1>
          </div>

          <div className="body">
            <h3 className="bodyTextH3">Delivery</h3>
            <h1 className="bodyTextH1">50</h1>
          </div>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Item No
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Brand
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Qty
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Unit
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              QR ID
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">{item.no}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.rn}</td>
              <td className="px-6 py-4 whitespace-nowrap">Fish Feed</td>
              <td className="px-6 py-4 whitespace-nowrap">JPD</td>
              <td className="px-6 py-4 whitespace-nowrap">10</td>
              <td className="px-6 py-4 whitespace-nowrap">10</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <h3 style={{ fontWeight: "500", fontSize: "1.5rem" }}>Reason:</h3>
        <h2 style={{ fontSize: "1rem" }}>Sudah sudah</h2>
      </div>
    </div>
  );
}

export default TableReturnDetail;
