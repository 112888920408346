import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  name: string;
  description: string;
};

const useUpdateBrand = () => {
  const api = useAxios();
  return useMutation(({ id, name, description }: ParamType) => {
    return api
      .put(`product-brands/${id}`, {
        name: name,
        description: description,
      })
      .then((res) => {
        console.log(res, "check res");
        toast("Product brand berhasil di update");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useUpdateBrand;
