/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useProductPriceList from "../../api/hooks/salesOrder/useProductPriceList";
import { toast } from "react-toastify";

export const SelectProductBlacklist = ({
  selectedProduct,
  setSelectedProduct,
  setProductList,
  productList,
  customerId,
}: any) => {
  const [search, setSearch] = useState<string>("");
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
    isSuccess,
  } = useProductPriceList({
    "filter[product_unit]": search,
  });
  const dataList = data?.pages?.[0];
  useEffect(() => {
    refetch();
    return;
  }, [customerId, search]);
  return (
    <Box>
      <Menu>
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Select Product
        </h3>
        <MenuButton
          textAlign={"start"}
          fontWeight="normal"
          bg={"white"}
          borderWidth={1}
          as={Button}
          w={"100%"}
          rightIcon={<ChevronDownIcon />}
        >
          {!selectedProduct
            ? "Select Product"
            : selectedProduct?.product_unit?.name}
        </MenuButton>
        <MenuList>
          <Input
            borderLeftWidth={0}
            borderRightWidth={0}
            placeholder="Search here..."
            onChange={(event) => setSearch(event.target.value)}
          />
          {dataList?.map((item: any) => (
            <MenuItem
              id={item?.id}
              key={item?.id}
              onClick={() => {
                if (productList.find((e: { id: number }) => e.id === item.id)) {
                  toast.warn("Product already exist");
                } else {
                  setSelectedProduct(item);
                }
              }}
            >
              {item?.product_unit?.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
