import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import useDeleteUser from "../../../api/hooks/user/useDeleteUser";
import { permissionState } from "../../../provider/atoms";

function TableUser({
  data,
  header,
  navName,
  btnName,
  btnNav,
  inpName,
  refetch,
}: any) {
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useDeleteUser();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleDelete = () => {
    if (selectedItem) {
      // console.log(selectedItem.id, "check id delete");
      // onClose()
      mutateAsync({
        id: selectedItem?.id,
      }).then(() => {
        refetch();
        onClose();
      });
    }
  };
  const selectItemAndOpenModal = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  const ModalDelete = ({ item }: any) => {
    return (
      <>
        <IconButton
          colorScheme="red"
          aria-label="Delete"
          icon={<DeleteIcon />}
          onClick={() => selectItemAndOpenModal(item)}
          ml="0.5rem"
          size={"sm"}
          color={"#fff"}
          isDisabled={!dataPermission?.user_access?.user_delete}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg={"transparent"} />
          <ModalContent>
            <ModalHeader>Delete User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Peringatan: User akan di hapus secara permanen. Lanjutkan?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={handleDelete}
                bg={"red"}
                color={"#fff"}
                ml={"1rem"}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <div>
      <table className="min-w-fit  divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              ID
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Name
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Email
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Warehouse
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Roles
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Created At
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase "
              style={{ color: "#000", fontWeight: "bold", fontSize: "12" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item: any, index: any) => {
            return (
              <tr key={item.id}>
                <td className="px-6 py-4 text-center ">{item?.id}</td>
                <td className="px-6 py-4 text-center ">{item.name}</td>
                <td className="px-6 py-4 text-center ">{item.email}</td>
                <td className="px-6 py-4 text-center ">
                  {item?.warehouses?.map((e: any) => (
                    <div className="bg-[#c4c4c4] text-sm mb-3 rounded-md text-center">
                      {e.name}
                    </div>
                  ))}
                </td>
                <td className="text-center ">
                  {item?.roles?.map((e: any) => (
                    <div className="bg-[#01338C] w-[7rem] rounded-md ">
                      <span className=" p-2 text-sm text-white mb-3 text-center">
                        {e.name}
                      </span>
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4 text-center ">
                  {moment(item.created_at).format("DD MMMM YYYY")}
                </td>

                <td className="px-6 py-4 text-center ">
                  {item?.type === 1 ? (
                    <IconButton
                      colorScheme={"yellow"}
                      aria-label="Edit"
                      icon={<EditIcon />}
                      size={"sm"}
                      color={"#fff"}
                      isDisabled={!dataPermission?.user_access?.user_edit}
                      onClick={() => nav("/users/update-user", { state: item })}
                    />
                  ) : null}
                  {item?.type === 1 ? null : <ModalDelete item={item} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableUser;
