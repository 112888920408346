import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { DashboardContainer } from "../atoms/dashboardContainer";
import useCreateProductCategoryMutation from "../../api/hooks/product/useCreateProductCategoryMutation";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  desc: Yup.string().min(10, "description must be more then 10 characters"),
});

function FormProductCategory({ refetch, onClose }: any) {
  const { mutateAsync } = useCreateProductCategoryMutation();
  const initialValues = {
    name: "",
    desc: "",
  };

  const handleSubmit = (values: any) => {
    mutateAsync({
      name: values.name,
      description: values.desc,
    }).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <DashboardContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <FormControl isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Input name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!(errors.desc && touched.desc)}>
              <FormLabel fontWeight={"medium"} fontSize={"1.5rem"}>
                Description
              </FormLabel>
              <Field
                as={Input}
                name="desc"
                placeholder="Input description here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.desc}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              size={"sm"}
              bg={COLOR_PRIMARY}
              color="#fff"
              mt="0.5rem"
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </DashboardContainer>
  );
}

export default FormProductCategory;
