import {
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AdjustUnit } from "../modal/modalAdjust";
import { VerifyOrderModal } from "../modal/modalVerify";
import UnverifiedButton from "../unverifiedOrder";
import { DeleteDetails } from "../modal/DeleteDetails";

export function ResponsiveTable({
  data,
  refetch,
  handleClick,
  sorting,
  receiveData,
  isLoading,
}: any) {
  const navigate = useNavigate();
  const seeDetail = (item: any) => {
    navigate("/stock-product", {
      state: {
        id: item?.id,
        name: item?.product_unit?.name,
        category: item?.product_unit?.product?.product_category?.name,
        brand: item?.product_unit?.product?.product_brand?.name,
        qty: item?.qty,
        adjust_qty: item?.adjust_qty,
        warehouse_id: item?.receive_order?.warehouse_id,
        type: "ro",
      },
    });
  };
  return (
    <Stack direction={["column", "column", "row"]} spacing={4}>
      <Table className="styled-table" size="sm">
        <Thead style={{ backgroundColor: "#F8FBFF" }}>
          <Tr>
            <Th
              onClick={() => sorting("date")}
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Item No
            </Th>
            <Th
              onClick={() => sorting("number")}
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Name
            </Th>
            <Th
              onClick={() => sorting("items")}
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Category
            </Th>
            <Th
              onClick={() => sorting("warehouse")}
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Brand
            </Th>
            <Th
              onClick={() => sorting("supplier")}
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Qty
            </Th>
            <Th style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>
              Unit
            </Th>
            <Th style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>
              Adjust
            </Th>
            <Th style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>
              Printed
            </Th>
            <Th style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>
              Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item: any) => {
            console.log(item, "check item ro");
            return (
              <Tr key={item?.id} style={{ marginTop: "1rem" }}>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.product_unit?.code}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.product_unit?.name}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.product_unit?.product?.product_category?.name}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.product_unit?.product?.product_brand?.name}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.qty}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {item?.item_unit}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  {!!item?.is_verified
                    ? !!item?.adjust_qty
                      ? `${item?.adjust_qty} ${item?.product_unit?.uom?.name}`
                      : `${item?.qty} ${item?.product_unit?.uom?.name}`
                    : "-"}
                </Td>
                <Td style={{ fontWeight: "500", fontSize: "1em" }}>
                  0/{!!item?.adjust_qty ? item?.adjust_qty : item?.qty}
                </Td>

                <Td>
                  {!!item?.is_verified ? (
                    <Flex>
                      <UnverifiedButton props={item} refetch={refetch} />
                      <Button w="8rem" ml={3} onClick={() => seeDetail(item)}>
                        detail
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <VerifyOrderModal
                        data={item}
                        refetch={refetch}
                        isLoading={isLoading}
                      />
                      <AdjustUnit data={item} refetch={refetch} />
                      <DeleteDetails
                        item={item}
                        refetch={refetch}
                        data={receiveData}
                      />
                    </Flex>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Stack>
  );
}
