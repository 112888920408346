import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useProductUnitList from "../../api/hooks/product/useProductUnitList";
import useProductPriceList from "../../api/hooks/salesOrder/useProductPriceList";

export const SelectProductQuotation = ({
  selectedProduct,
  setSelectedProduct,
  customerId,
  selectedProducts = [],
  warehouse_id,
}: any) => {
  const [search, setSearch] = useState<string>("");

  const { data, refetch } = useProductUnitList({
    "filter[name]": search,
  });
  const dataList = data?.pages?.[0];
  // const handleProductSelect = (product: any) => {
  //   const isDuplicate = selectedProducts.some(
  //     (item: any) => item.value === product.value
  //   );

  //   if (isDuplicate) {
  //     toast.warn("Product already exists");
  //   } else {
  //     setSelectedProduct(product);
  //   }
  // };
  const handleProductSelect = (product: any) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    refetch();
    return;
  }, [search]);
  return (
    <Box>
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          autoFocus={true}
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={dataList?.data?.map((item: any) => ({
            value: item.id,
            label: item.name,
            price: item.price,
            uom: item.uom.name,
            discount: 0,
            price_discount: 0,
            stocks_count: 9999,
            id: item.id,
            ppn: item.is_ppn,
          }))}
          placeholder="Select Product"
          closeMenuOnSelect={true}
          value={selectedProduct}
          onChange={handleProductSelect}
        />
      </FormControl>
    </Box>
  );
};
