import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  invoice_no: string;
  warehouse_id: string;
  reseller_id: string;
  description: string;
  transaction_date: any;
  shipment_estimation_datetime: any;
};

const useCreateDOMutation = () => {
  const api = useAxios();
  return useMutation(
    ({
      invoice_no,
      warehouse_id,
      reseller_id,
      description,
      transaction_date,
      shipment_estimation_datetime,
    }: ParamType) => {
      return api
        .post(`delivery-orders`, {
          invoice_no: invoice_no,
          warehouse_id: warehouse_id,
          reseller_id: reseller_id,
          transaction_date: transaction_date,
          shipment_estimation_datetime: shipment_estimation_datetime,
          description: description,
        })
        .then((res) => {
          console.log(res);
          toast("Delivery Order berhasil dibuat");
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  );
};

export default useCreateDOMutation;
