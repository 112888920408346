import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  email: string;
  phone: string;
  code: string;
  description: string;
  address: string;
  id: any;
};

const useUpdateSupplier = () => {
  const api = useAxios();
  return useMutation(
    ({ email, name, phone, description, address, code, id }: ParamType) => {
      return api
        .put(`suppliers/${id}`, {
          name: name,
          email: email,
          phone: phone,
          code: code,
          description: description,
          address: address,
        })
        .then((res) => {
          toast("Supplier berhasil diupdate");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useUpdateSupplier;
