/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { COLOR_FIGMA_LIGHT_BLUE } from "../../Helper/theme";
import useSupplierList from "../../api/hooks/supplier/useSupplierList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import TableStore from "../../components/molecules/tables/tableStore";
import { permissionState } from "../../provider/atoms";

function Supplier() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const nav = useNavigate();
  const [search, setSearch] = useState<string>();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useSupplierList({
    "filter[search]": search,
    page: currentPage,
  });
  const countData = data?.pages[0].meta.total;
  const supplier = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  return (
    <DashboardContainer>
      {!dataPermission?.supplier_access?.supplier_access ? (
        <NoPermission />
      ) : (
        <>
          {!!isLoading ? (
            <Loading />
          ) : (
            <>
              <div>
                <Header headerName={"Supplier"} back={true} />
                <div
                  className="row space-x-11"
                  style={{ marginBottom: "2rem", display: "flex" }}
                >
                  <Input
                    placeholder={"search supplier"}
                    bg={"white"}
                    onChange={(event) => setSearch(event.target.value)}
                  />

                  <Button
                    bg={COLOR_FIGMA_LIGHT_BLUE}
                    color="white"
                    _hover={{ bg: COLOR_FIGMA_LIGHT_BLUE }}
                    onClick={() => nav("/new-supplier")}
                    isDisabled={
                      !dataPermission?.supplier_access?.supplier_create
                    }
                  >
                    Add Supplier
                  </Button>
                </div>
              </div>
              <TableStore
                header={"Supplier"}
                data={supplier}
                col1={"No."}
                col2={"Supplier Name"}
                col3={"Email"}
                col4={"Phone Number"}
                col5={"Action"}
                btnName="New Supplier"
                inpName="Search supplier name"
                btnNav="/new-supplier"
                edit={true}
                del={true}
                refetch={refetch}
              />
            </>
          )}
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </DashboardContainer>
  );
}

export default Supplier;
