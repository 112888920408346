/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from "@chakra-ui/react";
import { Html5QrcodeScanType } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useScanOrder from "../../api/hooks/deliveryOrder/useScanOrder";
import Html5QrcodePlugin from "../../components/molecules/scanner";
import CustomScanner from "../../components/molecules/scanner/customScanner";
import { queryClient } from "../../provider/query";

type OnNewScanResult = (decodedText: string) => void;
const QRMobile = () => {
  const [decoded, setDecoded] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const { mutateAsync } = useScanOrder();
  const location = useLocation();
  const params = location.state;
  const handleSubmit = (decodedText: string) => {
    mutateAsync({
      orderId: params?.DO,
      qrcode: decoded,
      salesOrderId: params?.SO,
    }).then(() => {
      queryClient.invalidateQueries("DeliveryOrderDetail");
    });
  };
  console.log(params);
  const onNewScanResult: OnNewScanResult = (decodedText) => {
    setDecoded(decodedText);
  };
  useEffect(() => {
    if (decoded !== code) {
      handleSubmit(decoded);
      setCode(decoded);
    }
  }, [decoded]);
  return (
    <Container h={"100%"} bg={"white"}>
      <Box h={10} />
      <Html5QrcodePlugin
        fps={5}
        qrbox={250}
        disableFlip={true}
        qrCodeSuccessCallback={onNewScanResult}
        supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
      />
    </Container>
  );
};

export default QRMobile;
