import { Box, Button, Container, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGetUserMe } from "../../../api/hooks/user/useGetUserMe";
import platLogo from "../../../assets/logo/coloredLogo.png";
import salesOrder from "../../../assets/logo/customer.png";
import opname from "../../../assets/logo/inventory.png";
import receiveOrder from "../../../assets/logo/package.png";
import Brand from "../../../assets/logo/brand.png";
import checkStock from "../../../assets/logo/packages.png";
import delivery from "../../../assets/logo/partnership.png";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import { permissionState } from "../../../provider/atoms";
import { useAuth } from "../../../provider/auth";

export default function HomeMobile() {
  const navigate = useNavigate();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const { onLogout } = useAuth();
  const { data, isLoading, isFetching, refetch } = useGetUserMe({});
  useEffect(() => {
    if (data?.data?.type === "Spg") {
      navigate("/mobile/invoice-order", { replace: true });
    }
    return;
  }, [data]);
  return (
    <>
      <Container
        flex={1}
        maxW="container.sm"
        p={4}
        h="100vh"
        display="flex"
        flexDirection="column"
      >
        <div className="flex justify-center items-center mb-10 border-b-4 border-slate-200 pb-3">
          <Image src={platLogo} boxSize={"50px"} alt="Platinum Logo" />
          <div className="ml-5">
            <p className="font-bold text-md">PLATINUM</p>
            <p className="font-bold text-md">WAREHOUSE</p>
          </div>
        </div>
        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          onClick={() => navigate("/invoice")}
          mb={5}
          borderRadius={10}
          w="100%"
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={Brand}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Pameran 2024
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Invoice Event Pameran 2024
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          onClick={() => navigate("/mobile/stock")}
          mb={5}
          borderRadius={10}
          w="100%"
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={Brand}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Stock Product
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Check stock, adjust stock and review movement product
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          onClick={() => navigate("/ro")}
          mb={5}
          borderRadius={10}
          w="100%"
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={receiveOrder}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Receive Order
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Input Order, Adjust unit/quantity and add to stock
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          mb={5}
          borderRadius={10}
          w="100%"
          onClick={() => navigate("/sales-order")}
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={salesOrder}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Sales Order
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Create Sales order, scan product qr and download report
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          mb={5}
          borderRadius={10}
          w="100%"
          onClick={() => navigate("/delivery")}
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={delivery}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Delivery Order
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Create delivery order, scan product qr and download report
              </Text>
            </Box>
          </Flex>
        </Box>

        {!dataPermission?.stock_opname_access ? null : (
          <Box
            boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
            as="button"
            borderRadius={10}
            w="100%"
            mb={5}
            onClick={() => navigate("/stock-opname")}
            display="flex"
            bg={"white"}
            p={5}
            borderWidth={0.5}
          >
            <Flex align={"center"}>
              <img
                src={opname}
                alt="Platinum Logo"
                className="w-[30px] h-[30px]"
              />
              <Box>
                <Text
                  color={COLOR_PRIMARY}
                  ml={5}
                  fontWeight="semibold"
                  textAlign="left"
                >
                  Stock Opname
                </Text>
                <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                  Create stock opname, edit quantity stock and create opname
                  report{" "}
                </Text>
              </Box>
            </Flex>
          </Box>
        )}

        <Box
          boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
          as="button"
          borderRadius={10}
          w="100%"
          mb={5}
          onClick={() => navigate("/stock-scanner")}
          display="flex"
          bg={"white"}
          p={5}
          borderWidth={0.5}
        >
          <Flex align={"center"}>
            <img
              src={checkStock}
              alt="Platinum Logo"
              className="w-[30px] h-[30px]"
            />
            <Box>
              <Text
                color={COLOR_PRIMARY}
                ml={5}
                fontWeight="semibold"
                textAlign="left"
              >
                Check Stock
              </Text>
              <Text ml={5} color="#c4c4c4" fontSize={12} textAlign="left">
                Check Stock: Warehouse-only inventory verification.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Button
          onClick={() => {
            onLogout();
          }}
          bg={COLOR_PRIMARY}
          color={"#fff"}
        >
          Logout
        </Button>
      </Container>
    </>
  );
}
