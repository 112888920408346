import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import useVerifyOrder from "../../../api/hooks/receiveOrder/useVerifyOrder";
import { COLOR_PRIMARY } from "../../../Helper/theme";

export function VerifyOrderModal({ data, refetch, isLoading }: any) {
  const { mutateAsync } = useVerifyOrder();
  const handleSubmit = () => {
    mutateAsync({
      receiveId: data?.receive_order?.id,
      receiveOrderDetailId: data?.id,
      type: 1,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        backgroundColor="#F1C40F"
        color="white"
        w={"8rem"}
        onClick={() => {
          onOpen();
        }}
      >
        Verify Order
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>are you sure you want to verify this order?</ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mx={5}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bgColor={COLOR_PRIMARY}
              color="white"
              isLoading={isLoading}
            >
              Verify Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
