import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSalesOrderList from "../../api/hooks/salesOrder/useSalesOrderList";

export const SelectSalesOrderList = ({
  selected,
  setSelected,
  resellerId,
}: any) => {
  const [searchValue, setSearchValue] = useState<string>();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useSalesOrderList({
    include: "user",
    "filter[reseller_id]": resellerId,
  });
  useEffect(() => {
    refetch();
  }, [searchValue]);
  const users = data?.pages?.flatMap((page) => page.data);
  return (
    <>
      <Box mt={5}>
        <h3>Select Sales Order</h3>
        <Menu>
          <MenuButton
            textAlign={"start"}
            fontWeight="normal"
            bg={"white"}
            w={"100%"}
            mt={"1rem"}
            borderWidth={1}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {!selected ? "Select Sales Order" : selected?.invoice_no}
          </MenuButton>
          <MenuList>
            <Input
              borderLeftWidth={0}
              borderRightWidth={0}
              placeholder="Search here..."
              onChange={(event) => setSearchValue(event.target.value)}
            />
            {users?.map((item: any) => (
              <MenuItem
                style={{ color: "#000" }}
                id={item?.id}
                key={item?.id}
                onClick={() => setSelected(item)}
              >
                {item?.invoice_no}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};
