import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGetUserMe } from "../../api/hooks/user/useGetUserMe";
import { permissionState } from "../../provider/atoms";
import { useAuth } from "../../provider/auth";
import Sidebar from "./components/SideBar";
import "./home.css";
function Home() {
  const { data } = useAuth();
  const navigate = useNavigate();
  const [dataPermission] = useRecoilState(permissionState);
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  if (!data.jwt) {
    navigate("/login");
  }
  const { data: permission, isLoading, isFetching, refetch } = useGetUserMe({});
  useEffect(() => {
    if (data.jwt || dataPermission === null) {
      refetch();
    }
    return;
  }, []);
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className={MobileDevice ? "flex-grow p-8" : "flex-grow"}>
        <Outlet />
      </div>
    </div>
  );
}

export default Home;
