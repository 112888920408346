import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetQuotationList from "../../../api/hooks/invoice/spg/useGetQuotationList";
import { formatCurrency } from "../../../components/atoms/formatCurrency";
import Pagination from "../../../components/molecules/pagination";
import { COLOR_YELLOW } from "../../../Helper/theme";

export default function InvoiceListScreen() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const { data, refetch, isLoading } = useGetQuotationList({
    page: currentPage,
    include: "user,reseller",
    sort: "-id",
  });
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage]);
  return (
    <>
      {/* header */}
      <div className="z-10 bg-gradient-to-r from-[#01338C] to-[#00A3E0] h-15 py-3 flex items-center">
        <Button
          onClick={() => navigate(-1)}
          variant={"ghost"}
          _hover={{ bg: "#fff" }}
        >
          <ArrowBackIcon fontSize="xl" color="white" />
        </Button>
        <p className="text-lg font-medium text-white">List Invoice</p>
      </div>
      {/* table */}
      <div className="mt-0 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 text-[12px]">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                Name
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                Payment
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1em",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200 bg-[#e1eaf6] text-[12px]">
            {dataList?.map((item: any) => (
              <tr key={item.id}>
                <td className="px-4 py-4">{item?.reseller?.name}</td>
                <td className="px-6 py-4">
                  {moment(item?.created_at).format("DD MMMM, hh:mm")}
                </td>
                <td className="px-6 py-4">{formatCurrency(item?.price)}</td>
                <td className="px-6 py-4">
                  <div
                    className={
                      item?.payment_status === "paid"
                        ? "bg-green-400 text-white font-semibold items-center flex justify-center rounded-sm"
                        : item?.payment_status === "down_payment"
                        ? "bg-orange-400 font-semibold items-center flex justify-center rounded-sm"
                        : "bg-slate-500 text-white font-semibold items-center flex justify-center rounded-sm"
                    }
                  >
                    {item?.payment_status}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <Button
                    onClick={() =>
                      navigate("/mobile/invoice-order/detail", {
                        state: item.id,
                      })
                    }
                    size="xs"
                    bg={COLOR_YELLOW}
                    color="#fff"
                  >
                    See Detail
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
    </>
  );
}
