import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  email: string;
  password: string;
  id: any;
  roles: any;
};

const useUpdateAdmin = () => {
  const api = useAxios();
  return useMutation(({ email, name, password, id, roles }: ParamType) => {
    return api
      .put(`users/${id}`, {
        name: name,
        email: email,
        password: password,
        role_ids: roles,
        type: "admin",
      })
      .then((res) => {
        toast("Admin berhasil diupdate");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useUpdateAdmin;
