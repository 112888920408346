import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { COLOR_FIGMA_LIGHT_BLUE } from "../../Helper/theme";
import useMasterUomsList from "../../api/hooks/uoms/useMasterUomsList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import TableUoms from "../../components/molecules/tables/tableUoms";
import { permissionState } from "../../provider/atoms";
import { CreateUomsModal } from "../../components/molecules/modal/modalCreateUoms";

function ProductUoms() {
  const [key, setKey] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const nav = useNavigate();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useMasterUomsList({
    "filter[name]": key,
    page: currentPage,
  });
  const [dataPermission] = useRecoilState<any>(permissionState);
  const productList = data?.pages.flatMap((page) => page.data);
  const countData = data?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, key]);
  return (
    <DashboardContainer>
      {!dataPermission?.product_access?.product_access ? (
        <NoPermission />
      ) : (
        <>
          <div>
            <Header headerName={"Uoms"} back={true} />
          </div>
          <div
            className="row space-x-11"
            style={{ marginBottom: "2rem", display: "flex" }}
          >
            <Input
              placeholder={"Search Uoms"}
              w={"90%"}
              bg={"white"}
              value={key}
              onChange={(e) => setKey(e?.target?.value)}
            />
            <CreateUomsModal refetch={refetch} />
          </div>
          <TableUoms
            header={"Product"}
            data={productList}
            col1={"ID"}
            col2={"Name"}
            col3={"Created At"}
            col4={"Updated At"}
            col5={"Action"}
            inpName="Search Uoms name"
            refetch={refetch}
          />
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </DashboardContainer>
  );
}

export default ProductUoms;
