import { Box } from "@chakra-ui/react";
import React, { SetStateAction, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Header from "../../components/molecules/header";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import useStockMovement from "../../api/hooks/stocks/useStockMovement";
import moment from "moment";
import Pagination from "../../components/molecules/pagination";
import Loading from "../../components/atoms/loading";

export default function StockMovement() {
  const location = useLocation();
  const params = location.state;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    queries: [{ data: dataTable }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([
    useStockMovement({
      page: currentPage,
      "filter[stock_product_unit_id]": params?.id,
    }),
  ] as const);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  const countData = dataTable?.pages[0].meta.total;
  const dataList = dataTable?.pages.flatMap((page) => page.data);
  return (
    <DashboardContainer>
      <Header
        headerName={`${params?.product_unit?.name} Movement`}
        back={true}
      />
      <Box h={10} />
      {isLoading ? <Loading /> : <TableMovement data={dataList} />}
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
    </DashboardContainer>
  );
}

const TableMovement = ({ data }: any) => {
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-bold  text-black uppercase tracking-wider "
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-bold  text-black uppercase tracking-wider"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-bold  text-black uppercase tracking-wider"
            >
              Movement
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-bold  text-black uppercase tracking-wider"
            >
              User
            </th>
          </tr>
        </thead>
        <tbody
          className="bg-white divide-y divide-gray-200"
          style={{ borderRadius: "10px 0 0 10px", background: "#e1eaf6" }}
        >
          {data?.map((item: any) => (
            <tr key={item?.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.description}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap"
                style={{
                  color: item?.is_increment === 0 ? "red" : "green",
                }}
              >
                {item?.is_increment === 0
                  ? `-${item?.value}`
                  : `+${item?.value}`}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.user?.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
