import { Box, FormControl } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import useUserList from "../../api/hooks/user/useUserList";

export const SelectCustomerList = ({
  selected,
  setSelected,
  type,
  disabled,
  noLabel,
}: any) => {
  const [search, setSearch] = useState<string>("");
  const [selectedColors, setSelectedColors] = useState<readonly any[]>([]);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useUserList({
    // page: currentPage,
    "filter[name]": search,
    "filter[type]": type,
  });
  console.log(data, "eee");
  useEffect(() => {
    refetch();
  }, [search]);
  const users = data?.pages?.flatMap((page) => page.data);
  return (
    <Box>
      {!noLabel ? (
        <h3 style={{ fontWeight: 500, fontSize: "1", marginBottom: "1rem" }}>
          Select Customer
        </h3>
      ) : null}
      <FormControl style={{ backgroundColor: "white" }}>
        <Select
          inputValue={search}
          onInputChange={(e) => setSearch(e)}
          name="colors"
          options={users?.map((item) => ({
            value: item.id,
            label: `${item.name} - ${item.phone}`,
          }))}
          placeholder="Select Customer"
          closeMenuOnSelect={true}
          value={selected}
          onChange={setSelected}
        />
      </FormControl>
    </Box>
  );
};
