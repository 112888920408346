import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useRecoilState } from "recoil";
import { permissionState, userState } from "../../../provider/atoms";
import { useAuth } from "../../../provider/auth";
import { useAxios } from "../useApi";

interface ApiResponse {
  data: any;
}

export const useGetUserMe = (
  params?: any,
  options?: UseQueryOptions<any>
): UseQueryResult<ApiResponse> => {
  const api = useAxios();
  const [, setPermission] = useRecoilState(permissionState);
  const [, setUserData] = useRecoilState(userState);
  const { onLogout } = useAuth();
  return useQuery<any>(
    "userMe",
    () => {
      return api
        .get(`users/me`, {
          params: {
            ...params,
          },
        })
        .then((res) => {
          setUserData(res.data.data);
          setPermission(res.data.data.permissions);
          const data = res.data as ApiResponse;
          return data;
        })
        .catch((err) => {
          onLogout();
        });
    },
    {
      retry: false,
      enabled: true,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
