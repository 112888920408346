import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import { SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useGetBatchList from "../../../api/hooks/invoice/voucher/useGetBatchList";
import useGetCategoryVoucherList from "../../../api/hooks/invoice/voucher/useGetCategoryVoucherList";
import { useAxios } from "../../../api/hooks/useApi";
import Header from "../../../components/molecules/header";
import Pagination from "../../../components/molecules/pagination";
import { SelectVoucherCategory } from "../../../components/molecules/selectVoucherCategory";
import { COLOR_PRIMARY } from "../../../Helper/theme";

export default function BatchAndCategory() {
  return (
    <div>
      <Header headerName={"Batch & Category"} back={true} />
      <div>
        <Tabs isFitted variant="enclosed" isLazy={true}>
          <TabList mb="1em">
            <Tab className="font-medium">Batch</Tab>
            <Tab className="font-medium">Category</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Batch />
            </TabPanel>
            <TabPanel>
              <Category />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

const Batch = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState<any>();
  const { data, refetch, isLoading } = useGetBatchList({
    page: currentPage,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const api = useAxios();

  const handleSubmit = () => {
    api
      .post(`vouchers/generate-batches`, {
        voucher_category_id: category?.value,
        value: amount,
        description: description,
      })
      .then((res) => {
        toast("Batch added");
        refetch();
        onClose();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const countData = data?.pages[0].meta.total;
  const batchList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  return (
    <div>
      <div className="flex justify-center">
        <Button
          onClick={onOpen}
          bg={COLOR_PRIMARY}
          color="white"
          className="w-full self-center"
        >
          Create batch
        </Button>
      </div>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>
            <Pagination
              pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
              handlePagination={handlePagination}
            />
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Release Date</Th>
              <Th>Source</Th>
              <Th>Description</Th>
              <Th>Voucher total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {batchList?.map((item) => (
              <Tr>
                <Td>{moment(item?.created_at).format("DD/MM/YYYY hh:mm")}</Td>
                <Td>{item?.source}</Td>
                <Td>{item?.description}</Td>
                <Td>25.4</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
      </TableContainer>
      <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Batch</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="min-h-[300px]">
              <SelectVoucherCategory
                selected={category}
                setSelected={setCategory}
              />
              <div className="mb-5" />
              <Input
                onChange={(e) => setDescription(e.target.value)}
                bg={"white"}
                placeholder="Description batch"
                value={description}
              />
              <div className="mb-5" />

              <Input
                onChange={(e) => setAmount(e.target.value)}
                bg={"white"}
                type="number"
                placeholder="Voucher amount"
                value={amount}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              alignSelf={"center"}
              w={"100%"}
              bg={COLOR_PRIMARY}
              color={"#fff"}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const Category = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [name, setName] = useState("");
  const [type, setType] = useState<any>({});
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState<any>();
  const { data, refetch, isLoading } = useGetCategoryVoucherList({
    page: currentPage,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const api = useAxios();

  const handleSubmit = () => {
    api
      .post(`voucher-categories`, {
        name: name,
        description: description,
        discount_type: type?.value,
        discount_amount: amount,
      })
      .then((res) => {
        toast("category added");
        refetch();
        onClose();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const countData = data?.pages[0].meta.total;
  const categoryList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  return (
    <div>
      <div className="flex justify-center">
        <Button
          onClick={onOpen}
          bg={COLOR_PRIMARY}
          color="white"
          className="w-full self-center"
        >
          Create Category
        </Button>
      </div>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>
            <Pagination
              pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
              handlePagination={handlePagination}
            />
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Release Date</Th>
              <Th>Name</Th>
              <Th>discount</Th>
              <Th>Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {categoryList?.map((item) => (
              <Tr>
                <Td>{moment(item?.created_at).format("DD/MM/YYYY hh:mm")}</Td>
                <Td>{item?.name}</Td>
                <Td>
                  {item?.discount_amount}
                  {item?.discount_type === "percentage" ? "%" : null}
                </Td>
                <Td>{item?.description}</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
      </TableContainer>
      <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="min-h-[300px]">
              <div className="mb-5" />
              <Input
                onChange={(e) => setName(e.target.value)}
                bg={"white"}
                placeholder="Name Category"
                value={name}
              />
              <div className="mb-5" />
              <Input
                onChange={(e) => setDescription(e.target.value)}
                bg={"white"}
                placeholder="Description"
                value={description}
              />
              <div className="mb-5" />
              <Select
                options={[
                  {
                    value: "percentage",
                    label: "percentage",
                  },
                  {
                    value: "nominal",
                    label: "nominal",
                  },
                ]}
                placeholder="Select type"
                closeMenuOnSelect={true}
                value={type}
                onChange={(e: any) => setType(e)}
              />
              <div className="mb-5" />

              <Input
                onChange={(e) => setAmount(e.target.value)}
                bg={"white"}
                type="number"
                placeholder="Discount amount"
                value={amount}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              alignSelf={"center"}
              w={"100%"}
              bg={COLOR_PRIMARY}
              color={"#fff"}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
