import { Button, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../Helper/theme";
import useUpdateCategory from "../../api/hooks/product/useUpdateCategory";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";

function FormUpdateCategory() {
  const { mutateAsync } = useUpdateCategory();
  const location = useLocation();
  const dataProps = location.state;
  const nav = useNavigate();
  const [form, setForm] = useState({
    name: dataProps?.name,
    description: dataProps?.description,
  });

  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: form.name,
      description: form.description,
    }).then(() => {
      nav("/category");
    });
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update Category" />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Name
      </Text>
      <Input
        placeholder="input name here"
        value={form.name}
        mt={"0.5rem"}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
      />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Description
      </Text>
      <Input
        placeholder="input name here"
        value={form.description}
        mt={"0.5rem"}
        onChange={(e) => setForm({ ...form, description: e.target.value })}
      />

      <Button
        size={"md"}
        onClick={handleSubmit}
        mt={"0.5rem"}
        bg={COLOR_YELLOW}
        color="#fff"
      >
        Update
      </Button>
    </DashboardContainer>
  );
}

export default FormUpdateCategory;
