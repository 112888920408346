import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useCreateProductBlacklist from "../../../api/hooks/product/useCreateProductBlacklist";
import { useState } from "react";
import { SelectProductBlacklist } from "../selectProductBlacklist";

export function AddProductBlacklist({ refetch, item }: any) {
  const [items, setItems] = useState<any>([]);
  const [nextId, setNextId] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useCreateProductBlacklist();
  const handleAdd = () => {
    const productUnitIds = items?.map((e: any) => e?.id);
    mutateAsync({
      product_unit_ids: productUnitIds,
    }).then((res) => {
      refetch();
      onClose();
    });
  };
  const handleAddItem = async (value: any) => {
    await setItems((prevItems: any): any => [...prevItems, value]);
  };

  const handleRemoveItem = (itemId: any) => {
    setItems((prevItems: any[]) =>
      prevItems?.filter((item: any) => item?.id !== itemId)
    );

    if (items?.length === 1) {
      setNextId(1);
    }
  };
  return (
    <>
      <Button
        mr="1rem"
        ml="2rem"
        bg={"#000"}
        color="#fff"
        size={"sm"}
        onClick={() => {
          onOpen();
        }}
      >
        Add exclude list
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Product Blacklist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <SelectProductBlacklist
                productList={items}
                setSelectedProduct={(e: any) => handleAddItem(e)}
              />
              {items?.map((item: any, index: any) => {
                return (
                  <Flex
                    borderRadius={5}
                    p={5}
                    bg="#E1EAF6"
                    m={5}
                    alignItems="center"
                  >
                    <Box w={"80%"}>
                      <Text>{item?.product_unit?.name}</Text>
                    </Box>
                    <Box w={"10%"} textAlign="center">
                      <Button
                        variant={"link"}
                        borderBottom={1}
                        borderBottomColor={"#01338C"}
                        borderBottomWidth={1}
                        color={"#01338C"}
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Flex>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={handleAdd} bg={"green"} color={"#fff"} ml={"1rem"}>
              Add Blacklist
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
