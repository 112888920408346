import { Button } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../../Helper/theme";
import { formatCurrency } from "../../atoms/formatCurrency";

function TableSalesOrder({ data, header }: any) {
  const nav = useNavigate();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 text-[12px]">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              SO Number
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Created by
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Customer
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Items
            </th>
            <th
              scope="col"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
            >
              Amount
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Est Shipment
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
              style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className=" divide-y divide-gray-200 bg-[#e1eaf6] text-[12px]">
          {data?.map((item: any) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.invoice_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.user?.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.reseller?.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item?.transaction_date).format("D MMMM YYYY")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {item?.details_count}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {formatCurrency(item?.price)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(item?.shipment_estimation_datetime).format(
                  "D MMMM YYYY"
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <Button
                  onClick={() => nav("/sales/detail", { state: item.id })}
                  size="xs"
                  bg={COLOR_YELLOW}
                  color="#fff"
                >
                  See Detail
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableSalesOrder;
