import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
};

const useDeleteUser = () => {
  const api = useAxios();
  return useMutation(({ id }: ParamType) => {
    return api
      .delete(`users/${id}`)
      .then((res) => {
        console.log(res, "check res");
        toast(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useDeleteUser;
