import { ButtonProps, ChakraProvider } from "@chakra-ui/react";
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from "chakra-paginator";
import { COLOR_PRIMARY } from "../../../Helper/theme";

const Pagination = ({
  pagesQuantity = 12,
  handlePagination,
}: {
  pagesQuantity?: number;
  handlePagination: (param: any) => void;
}) => {
  const { currentPage, setCurrentPage, offset } = usePaginator({
    initialState: { currentPage: 1, pageSize: 5 },
  });

  const normalStyles: ButtonProps = {
    w: 7,
    bg: "white",
    borderWidth: 1,
    fontSize: "sm",
    _hover: {
      bg: "blue.300",
    },
  };

  const activeStyles: ButtonProps = {
    w: 7,
    bg: COLOR_PRIMARY,
    fontSize: "sm",
    color: "white",
    _hover: {
      bg: "blue.300",
    },
  };

  const separatorStyles: ButtonProps = {
    w: 7,
    bg: "green.200",
  };

  return (
    <>
      <ChakraProvider>
        {/* @ts-ignore */}
        <Paginator
          outerLimit={3}
          innerLimit={3}
          activeStyles={activeStyles}
          normalStyles={normalStyles}
          separatorStyles={separatorStyles}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          onPageChange={(data) => {
            handlePagination(data);
            setCurrentPage(data);
          }}
        >
          <Container align="center" justify="space-between" w="full" p={4}>
            <Previous>
              Previous
              {/* Or an icon from `react-icons` */}
            </Previous>
            <PageGroup isInline align="center" />
            <Next>
              Next
              {/* Or an icon from `react-icons` */}
            </Next>
          </Container>
        </Paginator>
      </ChakraProvider>
    </>
  );
};

export default Pagination;
