import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useAxios } from "../../useApi";

interface User {
  // define the properties of your user object here
}

const useGetShowQuotationDetail = (
  params?: any,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  const api = useAxios();

  return useQuery<any>(
    `quotationDetail-${params.id}`,
    () => {
      return api
        .get(`orders/${params.id}`, {
          params: {
            ...params,
          },
        })
        .then((res) => {
          const data = res.data as any;
          return data;
        });
    },
    options
  );
};

export default useGetShowQuotationDetail;
