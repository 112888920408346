import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import PrintableContent from ".";
import { useAxios } from "../../../api/hooks/useApi";
import { permissionState } from "../../../provider/atoms";
import { useRecoilState } from "recoil";
import { DeleteGroup } from "../modal/DeleteGroup";

export default function PrintGroup({ groupId, nameProduct, refetch }: any) {
  const api = useAxios();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const printableContentRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState<number>();
  const printhandlerSmall = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      margin: 228px 75px 0px 50px !important;
    }
    @media print {
      html, body {
        height: initial !important;
        margin: 0px 19px 0px 25px !important;
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        margin: 0px 19px 0px 25px !important;
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      onClose();
    },
  });
  const printhandlerBig = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      // margin: 665px 120px 0px 220px !important;
      margin: 283px 50px 0px 110px !important;
    }
    @media print {
      html, body {
        height: initial !important;
        // margin: 0px 19px 0px 25px !important;
        // padding: 0 !important;
        // overflow: hidden;
      }
      .page-break {
        // margin: 0px 19px 0px 25px !important;
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      onClose();
    },
  });
  const printhandlerBigTest = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
    }
    @media print {
      html, body {
        height: initial !important;
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      onClose();
    },
  });
  const [data, setData] = useState<any>();

  const handlePrint = async () => {
    try {
      const res = await api.get(
        `stocks/print-all?filter[parent_id]=${groupId.id}`
      );
      setData(res.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  const handleUngroup = async () => {
    try {
      const res = await api.post(`stocks/${groupId.id}/ungrouping`);
      refetch();
      toast("Ungroup succesfully");
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };
  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     printhandlerBig();
  //   }
  // }, [data]);
  useEffect(() => {
    if (data && data.length > 0) {
      if (type === 1) {
        printhandlerSmall();
      } else if (type === 2) {
        printhandlerBig();
      } else {
        printhandlerBigTest();
      }
    }
  }, [data]);
  console.log(data?.concat(groupId), "naming");
  return (
    <>
      <div className="print-content">
        <PrintableContent
          ref={printableContentRef}
          data={data?.concat(groupId)}
          multiple
          name={nameProduct}
          type={type}
        />
      </div>

      <Button
        ml={10}
        bg={"#01338C"}
        color={"#fff"}
        onClick={onOpen}
        isDisabled={!dataPermission?.stock_access?.stock_print}
      >
        Print group
      </Button>
      {/* <Button ml={5} bg={"grey"} color={"#fff"} onClick={handleUngroup}>
        X
      </Button> */}
      <DeleteGroup onClick={handleUngroup} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>QR Size</ModalHeader>
          <ModalCloseButton />
          <ModalBody justifyContent={"center"} alignSelf={"center"}>
            {/* <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(1);
                handlePrint();
              }}
            >
              QR Kecil
            </Button> */}
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(2);
                handlePrint();
              }}
            >
              QR Besar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setType(3);
                handlePrint();
              }}
            >
              QR Besar Test
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
