import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Badge, Box, Flex, Image, Input } from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import useReceiveOrderList from "../../../api/hooks/receiveOrder/useReceiveOrderList";
import Boxx from "../../../assets/icon/roBox.png";
import { collapsedState } from "../../../provider/atoms";

export default function ReceiveOrderMobile() {
  const nav = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const { data, refetch, isLoading } = useReceiveOrderList({
    include: "user",
    page: currentPage,
    "filter[invoice_no]": search,
  });
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);
  const countData = data?.pages[0].meta.total;
  const dataList = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  const handleClick = (item: any) => {
    nav("/ro-detail", { state: item });
  };
  console.log(search);
  return (
    <div style={{ background: "#fff" }}>
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Receive Order
        </h3>
      </Flex>
      <Input
        placeholder="Search here"
        padding={5}
        mt={"0.5rem"}
        width={"93%"}
        ml="1rem"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {/* <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          justifyContent: "space-between",
        }}
      >
        <ImportCSV refetch={() => refetch()} />
        <Button mr="1rem" ml="2rem" bg={COLOR_PRIMARY} color="#fff" size={"lg"}>
          + Add New
        </Button>
      </div> */}
      {dataList?.map((item: any) => (
        <button
          onClick={() => handleClick(item)}
          style={{ width: "100%", alignSelf: "start" }}
        >
          <div
            style={{
              height: "12.5rem",
              width: "93%",
              background: "#fff",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "0.5rem",
              margin: "0.5rem 1rem 0.5rem 1rem ",
            }}
          >
            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3
                  style={{
                    fontSize: "0.8em",
                    color: "#979797",
                    textAlign: "start",
                  }}
                >
                  {moment(item?.receive_datetime).format("L")}
                </h3>
                <h3
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "#000",
                    marginTop: "0.3rem",
                    textAlign: "start",
                    textTransform: "uppercase",
                  }}
                >
                  {item?.purchase_order_no}
                </h3>
                {/* <h3
                  style={{
                    fontSize: "1.1em",
                    color: "#000",
                    marginTop: "0.3rem",
                    textAlign: "start",
                  }}
                >
                  {item?.sales_order?.reseller?.name}
                </h3> */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Image
                  justifyContent={"center"}
                  alignSelf={"center"}
                  src={Boxx}
                  boxSize={"8"}
                  mr="0.5rem"
                />
                <h3
                  style={{
                    fontSize: "2em",
                    color: "#2E79FF",
                    fontWeight: "bold",
                  }}
                >
                  {item?.details_count}
                </h3>
              </div>
            </div>
            <div
              style={{
                margin: "0rem 1rem 0rem 1rem",
                height: "1px",
                background: "#979797",
              }}
            ></div>
            <div style={{ padding: "1rem" }}>
              <div style={{ display: "flex" }}>
                <h3>Warehouse:</h3>
                <h3>&nbsp;{item?.warehouse?.name}</h3>
              </div>
              <div style={{ display: "flex" }}>
                <h3>Supplier :</h3>
                <h3>
                  &nbsp;
                  {item?.supplier?.name}
                </h3>
              </div>
            </div>
            <Box>
              <Badge
                colorScheme={item?.is_complete === false ? "yellow" : "green"}
                variant={"solid"}
                size={"lg"}
                fontSize={"1em"}
              >
                {item?.is_complete === false ? "Pending" : "Complete"}
              </Badge>
            </Box>
          </div>
        </button>
      ))}
    </div>
  );
}
