import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

interface ApiResponse {
  links: any;
  meta: any;
  data: any;
  pages: any;
  users: User[];
  totalPages: number;
  currentPage: number;
}

const useMasterBlackList = (
  params?: any,
  options?: UseInfiniteQueryOptions<ApiResponse>
): UseInfiniteQueryResult<ApiResponse> => {
  const api = useAxios();

  return useInfiniteQuery<ApiResponse>(
    "productblacklist",
    ({ pageParam = 1 }) => {
      return api
        .get("product-unit-blacklists", {
          params: {
            ...params,
            page: pageParam,
            perPage: 10,
          },
        })
        .then((res) => {
          const data = res.data as ApiResponse;
          return data;
        });
    },
    {
      getNextPageParam: (lastQuery, pages) => {
        // Error
        if (lastQuery === undefined) {
          return false;
        }

        const currentPage = lastQuery.meta!.current_page;

        if (!lastQuery.links!.next) return false;
        return currentPage! + 1;
      },
      ...options,
      retry: false,
    }
  );
};

export default useMasterBlackList;
