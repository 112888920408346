import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../../../provider/query";
import { useAxios } from "../../useApi";

type ParamType = {
  categoryId: number;
  description: string;
  files: any;
};

const useImportVoucherMutation = () => {
  const api = useAxios();
  return useMutation(({ categoryId, description, files }: ParamType) => {
    const formData = new FormData();
    formData.append("file", files);
    formData.append("voucher_category_id", categoryId.toString());
    formData.append("description", description);

    return api
      .post(`vouchers/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        queryClient.invalidateQueries("VoucherList");
        toast(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useImportVoucherMutation;
