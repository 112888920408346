/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { Html5QrcodeScanType } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useScanOpnameMutation from "../../api/hooks/opnames/useScanOpnameMutation";
import useStockOpnameHistory from "../../api/hooks/opnames/useStockOpnameHistory";
import Html5QrcodePlugin from "../../components/molecules/scanner";
import { COLOR_PRIMARY } from "../../Helper/theme";

type OnNewScanResult = (decodedText: string) => void;
const OpnameScanner = () => {
  const [decoded, setDecoded] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const { mutateAsync } = useScanOpnameMutation();
  const location = useLocation();
  const params = location.state;
  const nav = useNavigate();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useStockOpnameHistory({
    id: params?.id,
    detailId: params?.detailId,
  });
  const dataOpname = data?.pages?.[0];
  const filteredData = dataOpname?.filter((e: any) => e?.is_scanned === 1);
  const handleSubmit = (decodedText: string) => {
    mutateAsync({
      opnameDetailId: params?.detailId,
      qrcode: decoded,
      opnameId: params?.id,
    }).then(() => {
      refetch();
    });
  };
  const onNewScanResult: OnNewScanResult = (decodedText) => {
    setDecoded(decodedText);
  };
  useEffect(() => {
    if (decoded !== code) {
      handleSubmit(decoded);
      setCode(decoded);
    }
  }, [decoded]);
  return (
    <Container
      flex={1}
      maxW="container.sm"
      p={4}
      display="flex"
      flexDirection="column"
      h="100vh" // Set the height to 100% of the viewport height
      overflowY="scroll" // Enable vertical scrolling
    >
      <Flex align={"center"}>
        <button onClick={() => nav(-1)}>
          <ChevronLeftIcon mx={2} boxSize={8} />
        </button>
        <h3
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#01338C",
            background: "#fff",
          }}
        >
          Scan QR
        </h3>
      </Flex>
      <Box flex={1}>
        <Html5QrcodePlugin
          fps={5}
          qrbox={250}
          disableFlip={true}
          qrCodeSuccessCallback={onNewScanResult}
          supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
        />
      </Box>
      <Box>
        <Text textAlign={"center"} py={5} fontSize={"1.2em"}>
          Scan progress ( {filteredData?.length} / {dataOpname?.length} )
        </Text>
        <Box>
          {dataOpname?.map((e: any) => (
            <>
              {e.is_scanned === 1 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  w="100%"
                  bg="#fff"
                  my={4}
                  p={5}
                  borderRadius={10}
                  boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
                >
                  <Text color={COLOR_PRIMARY} fontWeight="semibold">
                    {params?.name}
                  </Text>
                  <Text>Scanned at : {e?.updated_at}</Text>
                  <Text color={"#c4c4c4"}>{e?.stock_id}</Text>
                </Box>
              ) : null}
            </>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default OpnameScanner;
