import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  description: string;
  brand: string;
  category: string;
};

const useCreateProductMutation = () => {
  const api = useAxios();
  return useMutation(({ name, description, brand, category }: ParamType) => {
    return api
      .post(`products`, {
        name: name,
        description: description,
        product_category_id: category,
        product_brand_id: brand,
      })
      .then((res) => {
        toast("Product berhasil dibuat");
      })
      .catch(defaultErrorHandler);
  });
};

export default useCreateProductMutation;
