import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import { Text } from "@chakra-ui/react";

export default function PaymentDetailScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param: any = JSON.parse(
    decodeURIComponent(queryParams.get("param1") || "{}")
  );
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Payment method
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Amount
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Note
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-small text-black-500 uppercase"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Transaction proof
            </th>
          </tr>
        </thead>
        <tbody>
          {param?.map((e: any) => (
            <tr>
              <th scope="col" className="px-6 py-3">
                <Text className="uppercase text-xs">{e?.type}</Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <Text className="uppercase text-xs">
                  {formatCurrency(e?.amount)}
                </Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <Text className="uppercase text-xs">{e?.note}</Text>
              </th>
              <th scope="col" className="px-6 py-3">
                <img
                  src={e?.files[0]?.url}
                  alt="Selected"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
