/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "@chakra-ui/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { COLOR_FIGMA_LIGHT_BLUE } from "../../Helper/theme";
import useUserList from "../../api/hooks/user/useUserList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Loading from "../../components/atoms/loading";
import Header from "../../components/molecules/header";
import NoPermission from "../../components/molecules/noPermission";
import Pagination from "../../components/molecules/pagination";
import TableUser from "../../components/molecules/tables/tableUser";
import { permissionState } from "../../provider/atoms";

const Users = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const nav = useNavigate();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useUserList({
    page: currentPage,
    "filter[name]": search,
    "filter[type]": "admin",
    include: "warehouses",
  });
  const countData = data?.pages[0].meta.total;
  const users = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  return (
    <DashboardContainer>
      {!dataPermission?.user_access?.user_access ? (
        <NoPermission />
      ) : (
        <>
          {!!isLoading ? (
            <Loading />
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <Header headerName={"Users"} />
                <div
                  className="row space-x-11"
                  style={{ marginBottom: "2rem", display: "flex" }}
                >
                  <Input
                    placeholder={"search username"}
                    w="90%"
                    bg={"white"}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                  <Button
                    size={"md"}
                    bg={COLOR_FIGMA_LIGHT_BLUE}
                    color="#fff"
                    w="9rem"
                    onClick={() => nav("/users/new-user")}
                    isDisabled={!dataPermission?.user_access?.user_create}
                  >
                    + User
                  </Button>
                </div>
              </div>
              <TableUser data={users} refetch={refetch} />
            </div>
          )}
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </DashboardContainer>
  );
};

export default Users;
