/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import useScanOrder from "../../../api/hooks/deliveryOrder/useScanOrder";
import Html5QrcodePlugin from "../scanner";

type OnNewScanResult = (decodedText: string) => void;

export function ScanModal({ data, refetch }: any) {
  const { mutateAsync } = useScanOrder();
  const handleSubmit = (decodedText: string) => {
    mutateAsync({
      orderId: data.DO,
      qrcode: decodedText,
      salesOrderId: data.SO,
    }).then(() => {
      refetch();
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onNewScanResult: OnNewScanResult = (decodedText) => {
    handleSubmit(decodedText);
  };
  return (
    <>
      <Button
        bg={"yellow.400"}
        color="#fff"
        mr={3}
        onClick={() => {
          onOpen();
        }}
      >
        Verification
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scan Qr Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
