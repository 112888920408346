import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  email: string;
  phone: number;
  address: string;
  code: string;
};

const useCreateCustomerMutation = () => {
  const api = useAxios();
  return useMutation(({ email, name, phone, address, code }: ParamType) => {
    return api
      .post(`users`, {
        name: name,
        email: email,
        phone: phone,
        address: address,
        code: code,
        type: "reseller",
      })
      .then((res) => {
        toast("Reseller berhasil dibuat");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useCreateCustomerMutation;
