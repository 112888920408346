import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  sales_order_id: number;
  amount: string;
  type: string;
  note: string;
  files: any;
  is_full_payment: boolean;
};

const useCreatePaymentMutation = () => {
  const api = useAxios();
  return useMutation(
    ({
      sales_order_id,
      amount,
      type,
      note,
      files,
      is_full_payment,
    }: ParamType) => {
      const formData = new FormData();
      if (files !== null && files !== undefined && files?.length !== 0) {
        formData.append("files[]", files);
      }
      formData.append("sales_order_id", sales_order_id.toString());
      if (!is_full_payment) {
        formData.append("amount", amount);
      }

      formData.append("is_full_payment", is_full_payment ? "1" : "0");

      formData.append("note", note);
      formData.append("type", type);

      return api
        .post(`payments`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res, "check res");
          toast(res?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useCreatePaymentMutation;
