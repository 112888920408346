import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import useProductUnitList from "../../api/hooks/product/useProductUnitList";
import useMultipleQueries from "../../api/hooks/useMultipleQueries";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import { formatCurrency } from "../../components/atoms/formatCurrency";
import FormProductUnit from "../../components/forms/productUnitForm";
import Header from "../../components/molecules/header";
import Pagination from "../../components/molecules/pagination";
import { COLOR_PRIMARY, COLOR_YELLOW } from "../../Helper/theme";
import { permissionState } from "../../provider/atoms";

export default function ProductDetail() {
  const location = useLocation();
  const props = location.state;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPackageOpen,
    onOpen: onPackageOpen,
    onClose: onClosePackage,
  } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const {
    queries: [{ data: dataTable }],
    meta: { isLoading, refetch },
  } = useMultipleQueries([
    useProductUnitList({
      page: currentPage,
      "filter[product_id]": props?.id,
      include: "packaging",
    }),
  ] as const);
  const countData = dataTable?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  const paginatedData = dataTable?.pages?.flatMap((page) => page.data);
  return (
    <DashboardContainer>
      <Header back={true} headerName={props?.name} />

      <Box bg="white" p={5} borderWidth={1} borderTopRadius={10}>
        <Flex justify={"space-between"}>
          <Text
            fontWeight="normal"
            justifyContent={"center"}
            alignSelf={"center"}
          >
            {props?.description}
          </Text>
          <>
            {dataPermission?.product_unit_access?.product_unit_create ? (
              <Flex>
                <Button
                  w={"10rem"}
                  bg={COLOR_YELLOW}
                  color="#fff"
                  // size={"lg"}
                  onClick={() => {
                    onPackageOpen();
                  }}
                >
                  + Packaging
                </Button>
                <Button
                  w={"10rem"}
                  ml="2rem"
                  bg={"#2E79FF"}
                  color="#fff"
                  // size={"lg"}
                  onClick={() => {
                    onOpen();
                  }}
                >
                  + Product Unit
                </Button>
              </Flex>
            ) : null}
            <Modal size={"4xl"} isCentered isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>New Product Unit</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormProductUnit
                    refetch={refetch}
                    onClose={onClose}
                    productID={props?.id}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Modal
              size={"xl"}
              isCentered
              isOpen={isPackageOpen}
              onClose={onClosePackage}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>New Package</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormProductUnit
                    isPackage
                    refetch={refetch}
                    onClose={onClose}
                    productID={props?.id}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        </Flex>
      </Box>
      <Flex
        borderBottomRadius={10}
        borderWidth={1}
        justify="center"
        bg="white"
        py="5"
      >
        <Box flex={1} h="100px" alignItems={"center"}>
          <Flex p={"1rem"} h="100%" flexDir="column">
            <Text textAlign={"center"}>Created at</Text>
            <Text
              textAlign={"center"}
              color={COLOR_PRIMARY}
              fontWeight="semibold"
            >
              {moment(props?.created_at).format("D MMMM YYYY")}
            </Text>
          </Flex>
        </Box>
        <Box flex={1} h="100px" alignItems={"center"}>
          <Flex p={"1rem"} h="100%" flexDir="column">
            <Text textAlign={"center"}>Product Brand</Text>
            <Text
              textAlign={"center"}
              color={COLOR_PRIMARY}
              fontWeight="semibold"
            >
              {props?.product_brand?.name}
            </Text>
          </Flex>
        </Box>
        <Box flex={1} h="100px" alignItems={"center"}>
          <Flex p={"1rem"} h="100%" flexDir="column">
            <Text textAlign={"center"}>Product Category</Text>
            <Text
              textAlign={"center"}
              color={COLOR_PRIMARY}
              fontWeight="semibold"
            >
              {props?.product_category?.name}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <TableProductUnit data={paginatedData} />
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
    </DashboardContainer>
  );
}

const TableProductUnit = ({ data }: any) => {
  const [dataPermission] = useRecoilState<any>(permissionState);
  const nav = useNavigate();
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Item No.
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Description
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Price
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Category
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Brand
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Unit
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody
        className="bg-white divide-y divide-gray-200"
        style={{ borderRadius: "10px 0 0 10px", background: "#e1eaf6" }}
      >
        {data?.map((item: any) => (
          <tr key={item?.id}>
            <td className="px-6 py-4 whitespace-nowrap">{item?.code}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item?.name}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {formatCurrency(item?.price)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product?.product_category?.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item?.product?.product_brand?.name}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">{item?.uom?.name}</td>

            <td className="px-6 py-4 whitespace-nowrap">
              <Button
                onClick={() =>
                  nav("/product/detail/edit-detail", { state: item })
                }
                size="sm"
                bg={COLOR_PRIMARY}
                color="#fff"
                isDisabled={
                  !dataPermission?.product_unit_access?.product_unit_edit
                }
              >
                Edit
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
