import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  description: string;
  resellerId: string;
  warehouseId: string;
  invoice_no: string;
  shipment_fee: number;
  transactionDate: any;
  estimatedDate: any;
  discount: number;
  items: any;
  id: number;
};

const useEditSOMutation = () => {
  const api = useAxios();
  return useMutation(
    ({
      description,
      resellerId,
      warehouseId,
      transactionDate,
      estimatedDate,
      invoice_no,
      shipment_fee,
      items,
      discount,
      id,
    }: ParamType) => {
      return api
        .put(`sales-orders/${id}`, {
          invoice_no: invoice_no,
          shipment_fee: shipment_fee,
          description: description,
          reseller_id: resellerId,
          warehouse_id: warehouseId,
          transaction_date: transactionDate,
          shipment_estimation_datetime: estimatedDate,
          additional_discount: discount,
          items: items,
        })
        .then((res) => {
          toast("Sales Order berhasil diedit");
        })
        .catch((err) => {
          console.log(err, "err");
          toast.warn(err.axiosError.response.data.message);
        });
    }
  );
};

export default useEditSOMutation;
