import { Image } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import wave from "../../assets/image/botLogin.png";
import storage from "../../assets/image/illus.png";
import LoginForm from "../../components/forms/loginForm";
import { COLOR_LOGIN } from "../../Helper/theme";
import { useAuth } from "../../provider/auth";
import platLogo from "../../assets/logo/Logo-Gram-Full-White.png";

function Login() {
  const { data } = useAuth();
  const navigate = useNavigate();
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  if (!!data.jwt) {
    navigate("/");
  }
  return (
    <div>
      {MobileDevice ? (
        <div>
          <div className="flex h-screen" style={{ background: COLOR_LOGIN }}>
            <Image
              src={storage}
              h={"90%"}
              w={"70%"}
              // ml="5rem"
              position={"absolute"}
              alt="storage"
              userSelect={"none"}
            />
            <div
              style={{
                position: "fixed",
                top: "10%",
                right: "15%",
                zIndex: 999,
              }}
            >
              <h1
                style={{
                  fontSize: "5rem",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Warehouse System
              </h1>
              <div>
                <LoginForm />
              </div>
            </div>
            <Image
              src={wave}
              h={"50%"}
              w={"100%"}
              // ml="5rem"
              bottom={0}
              position={"absolute"}
              alt="Wave"
              userSelect={"none"}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center h-screen"
          style={{ background: COLOR_LOGIN }}
        >
          <div className="w-full max-w-md mx-10">
            <div className="flex justify-center items-center mb-10">
              <Image src={platLogo} boxSize={"70px"} alt="Platinum Logo" />
              <div className="ml-5">
                <p className="text-white font-bold text-xl">PLATINUM</p>
                <p className="text-white font-bold text-xl">WAREHOUSE</p>
              </div>
            </div>
            <LoginForm />
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
