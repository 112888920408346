import React, { useState } from "react";
import {
  Button,
  Input,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useCounter } from "@chakra-ui/counter";

interface CounterComponentProps {
  onCountChange: (newCountValue: number) => void;
  defaultValue?: number;
  value?: number;
  stockCount?: number;
}

const CounterComponent: React.FC<CounterComponentProps> = ({
  onCountChange,
  defaultValue,
  stockCount,
  value,
}) => {
  const [count, setCount] = useState<number>();

  return (
    <Flex align="center">
      <NumberInput
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onCountChange(parseInt(e))}
        min={0}
        max={stockCount}
        autoFocus={true}
        outline={1}
      >
        <NumberInputField autoFocus />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {/* <Button onClick={handleDecrement} disabled={count === 0}>
        -
      </Button>
      <Input
        textAlign={"center"}
        minW={"5rem"}
        style={{ width: getInputWidth() }}
        type="number"
        value={count}
        bg="white"
        onChange={handleInputChange}
        min={0}
        max={stockCount}
      />
      <Button
        onClick={handleIncrement}
        disabled={stockCount !== undefined && count >= stockCount}
      >
        +
      </Button> */}
    </Flex>
  );
};

export default CounterComponent;
