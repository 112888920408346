import { Button, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../Helper/theme";
import useUpdateCategory from "../../api/hooks/product/useUpdateCategory";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";
import useUpdateDeliveryOrder from "../../api/hooks/product/useUpdateDeliveryOrder";
import moment from "moment";

function DeliveryOrderUpdate() {
  const { mutateAsync } = useUpdateDeliveryOrder();
  const location = useLocation();
  const dataProps = location.state;
  console.log(dataProps, "check ada kg ini");
  const nav = useNavigate();
  const [form, setForm] = useState({
    invoice: dataProps?.name,
  });

  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      invoice_no: form.invoice,
      reseller_id: dataProps?.reseller_id,
      shipment_estimation_datetime: dataProps?.est,
      transaction_date: moment(dataProps?.date).format("YYYY-MM-DD HH:mm:ss"),
      warehouse_id: dataProps?.warehouse_id,
    }).then(() => {
      nav("/delivery");
    });
  };

  return (
    <DashboardContainer>
      <Header back={true} headerName="Update Category" />

      <Text fontWeight={"bold"} fontSize={"1em"}>
        Delivery No
      </Text>
      <Input
        placeholder="input DO number here"
        value={form.invoice}
        mt={"0.5rem"}
        onChange={(e) => setForm({ ...form, invoice: e.target.value })}
      />

      <Button
        size={"md"}
        onClick={handleSubmit}
        mt={"0.5rem"}
        bg={COLOR_YELLOW}
        color="#fff"
      >
        Update
      </Button>
    </DashboardContainer>
  );
}

export default DeliveryOrderUpdate;
