import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  orderId: number;
};

const useSubmitFinishOrder = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(({ orderId }: ParamType) => {
    return api
      .put(`delivery-orders/${orderId}/done`, {
        is_done: 1,
      })
      .then((res) => {
        console.log(res);
        toast("Delivery Order berhasil di submit");
      })
      .catch((err) => {
        toast.error(err.axiosError.response.data.message);
      });
  });
};

export default useSubmitFinishOrder;
