import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  productUnitId: number;
  qty: number;
  description: string;
  is_increment: number;
};

const useRequestAdjustmentMutation = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(
    ({ productUnitId, qty, description, is_increment }: ParamType) => {
      return api
        .post(`adjustment-requests`, {
          stock_product_unit_id: productUnitId,
          value: qty,
          is_increment: is_increment,
          description: description,
        })
        .then((res) => {
          toast("Adjustment request has been delivered");
        })
        .catch((err) => {
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useRequestAdjustmentMutation;
