/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import useSubmitFinishOrder from "../../../api/hooks/deliveryOrder/useSubmitFinishOrder";
import { permissionState } from "../../../provider/atoms";

export function SubmitOrder({ data, refetch }: any) {
  const { mutateAsync } = useSubmitFinishOrder();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = () => {
    mutateAsync({
      orderId: data.id,
    }).then(() => {
      onClose();
      refetch();
    });
  };
  return (
    <>
      {data?.is_done ? null : (
        <Button
          bg={COLOR_PRIMARY}
          color="#fff"
          size={"xs"}
          onClick={() => {
            onOpen();
          }}
        >
          Finish
        </Button>
      )}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data?.fulfilled_qty !== data?.qty
              ? `Delivery order has ${
                  data?.qty - data?.fulfilled_qty
                } unverified order, Are you sure want to finish this order ?`
              : "Are you sure want to finish this order ?"}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
