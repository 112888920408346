/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import useSubmitFinishRO from "../../../api/hooks/receiveOrder/useSubmitFinishRO";

export function SubmitReceive({ data, refetch, isLoading }: any) {
  const { mutateAsync } = useSubmitFinishRO();
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log(data, "ini pas submit");
  const handleSubmit = () => {
    mutateAsync({
      id: data?.id,
    }).then(() => {
      onClose();
      refetch();
    });
  };
  return (
    <>
      <Button
        bg={COLOR_PRIMARY}
        color="#fff"
        size={"xs"}
        onClick={() => {
          onOpen();
        }}
      >
        Done
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit Receive Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure want to finish this order</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose} isLoading={isLoading}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
