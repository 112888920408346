import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useAxios } from "../useApi";

interface ApiResponse {
  data: any;
}

const useStockShow = (
  params?: any,
  options?: UseQueryOptions<ApiResponse>
): UseQueryResult<ApiResponse> => {
  const api = useAxios();

  return useQuery<ApiResponse>(
    "stockshow",
    () => {
      return api
        .get(`stocks/${params.id}`, {
          params: {
            ...params,
          },
        })
        .then((res) => {
          console.log(res, "cobain aprilio");
          const data = res.data as ApiResponse;
          return data;
        });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      ...options,
    }
  );
};

export default useStockShow;
