import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  id: number;
  name: string;
  description: string;
  product_id: number | string;
  price: number | string;
  code: string;
  uom_id: number | string;
  package_id: number;
};

const useUpdateProductUnit = () => {
  const api = useAxios();
  const navigate = useNavigate();
  return useMutation(
    ({
      id,
      name,
      description,
      price,
      product_id,
      code,
      uom_id,
      package_id,
    }: ParamType) => {
      return api
        .put(`product-units/${id}`, {
          name: name,
          description: description,
          product_id: product_id,
          price: price,
          code: code,
          uom_id: uom_id,
          packaging_id: package_id,
        })
        .then((res) => {
          toast("Product unit berhasil di update");
          navigate(-1);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.axiosError.response.data.message);
        });
    }
  );
};

export default useUpdateProductUnit;
