import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, Icon, Text } from "@chakra-ui/react";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import { useNavigate } from "react-router-dom";
import { TbFileExport } from "react-icons/tb";
import ExportXML from "./exportXml";
import { AddProductBlacklist } from "../modal/AddBlacklist";
import { useMediaQuery } from "react-responsive";
import { useRecoilState } from "recoil";
import { permissionState } from "../../../provider/atoms";

function Header({
  headerName,
  back = false,
  exportBtn = false,
  edit = false,
  blacklist = false,
  navName,
  params,
  id,
  type,
  refetch,
}: any) {
  const nav = useNavigate();
  const MobileDevice = useMediaQuery({ query: "(min-width: 1200px)" });
  const [dataPermission] = useRecoilState<any>(permissionState);
  return (
    <div
      className="border-b-2 mb-4"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>
        <Text
          mb={"1rem"}
          color={COLOR_PRIMARY}
          fontWeight="bold"
          textTransform={"uppercase"}
        >
          {back === true ? (
            <Button
              onClick={() => nav(-1)}
              variant={"ghost"}
              _hover={{ bg: "#fff" }}
            >
              <ArrowBackIcon fontSize={"2rem"} />
            </Button>
          ) : null}
          {headerName}
        </Text>
      </div>
      <div style={{ flexDirection: "row" }}>
        {exportBtn === true ? (
          <ExportXML id={id} type={type} title={headerName} />
        ) : null}
        {edit === true ? (
          <Button
            ml={"1rem"}
            w="6rem"
            size={"sm"}
            bg={COLOR_PRIMARY}
            color={"#fff"}
            isDisabled={!dataPermission?.sales_order_access?.sales_order_edit}
            onClick={() => nav(navName, { state: params })}
          >
            Edit
          </Button>
        ) : null}
        {blacklist === true ? <AddProductBlacklist refetch={refetch} /> : null}
      </div>
    </div>
  );
}

export default Header;
