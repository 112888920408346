import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import useRequestAdjustmentMutation from "../../../api/hooks/stocks/useRequestAdjustmentMutation";
import { COLOR_PRIMARY } from "../../../Helper/theme";
import { permissionState } from "../../../provider/atoms";
import CounterComponent from "../counter";

export function ModalStockAdjustment({ data, refetch }: any) {
  const [valueRadio, setValueRadio] = useState<string>("true");
  const { mutateAsync, isLoading } = useRequestAdjustmentMutation();
  const [countValue, setCountValue] = useState<number>(0);
  const [desc, setDesc] = useState<string>("");
  const handleSubmit = () => {
    mutateAsync({
      productUnitId: data,
      qty: countValue,
      description: desc,
      is_increment: valueRadio === "true" ? 1 : 0,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const handleCountChange = (newCountValue: number): void => {
    setCountValue(newCountValue);
  };
  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
        size="xs"
        mx={3}
        isDisabled={
          !dataPermission?.adjustment_request_access?.adjustment_request_create
        }
        bg={"#41B94E"}
        color="#fff"
      >
        Adjust
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={COLOR_PRIMARY}>Adjustment Stock</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              onChange={setValueRadio}
              value={valueRadio}
              className="mb-5"
            >
              <Stack direction="row">
                <Radio value="true">Increase</Radio>
                <Radio value="false">Decrease</Radio>
              </Stack>
            </RadioGroup>
            <Text pb={5} fontWeight="semibold">
              Qty
            </Text>
            <CounterComponent onCountChange={handleCountChange} />
            <Text py={5} fontWeight="semibold">
              Notes
            </Text>
            <Textarea onChange={(event) => setDesc(event.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mx={5}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleSubmit}
              bgColor={COLOR_PRIMARY}
              color="white"
            >
              Send Request
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
