import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../api/hooks/useApi";
import brand from "../../assets/logo/brand.png";
import categories from "../../assets/logo/categories.png";
import customer from "../../assets/logo/customer.png";
import exportLogo from "../../assets/logo/export.png";
import warehouse from "../../assets/logo/inventory.png";
import uoms from "../../assets/logo/package.png";
import product from "../../assets/logo/packages.png";
import supplier from "../../assets/logo/partnership.png";
import voucher from "../../assets/logo/voucher.png";

export default function MasterScreen() {
  const navigate = useNavigate();
  const api = useAxios();
  const [loading, setLoading] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const printInvoicesReport = () => {
    setLoadingInvoices(true);
    api
      .get(`invoices/export`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `Invoices-Pameran-${moment(new Date()).format(
          "YYYY-MM-DD"
        )}.xlsx`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the PDF file:", error);
        // Handle the error case accordingly
      })
      .finally(() => setLoadingInvoices(false));
  };
  const printStock = () => {
    setLoading(true);
    api
      .get(`stocks/export`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `stock-bejo-${moment(new Date()).format(
          "YYYY-MM-DD"
        )}.xlsx`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the PDF file:", error);
        // Handle the error case accordingly
      })
      .finally(() => setLoading(false));
  };
  return (
    <div>
      <p className=" bg-slate-200 text-center font-semibold py-4 text-[20]">
        Master Warehouse
      </p>
      <div className="grid grid-cols-4">
        <button onClick={() => navigate("/supplier")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={supplier}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center ">Supplier</p>
          </div>
        </button>

        <button onClick={() => navigate("/customer")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={customer}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Customer</p>
          </div>
        </button>
        <button onClick={() => navigate("/product")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={product}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Product</p>
          </div>
        </button>
        <button onClick={() => navigate("/warehouse")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={warehouse}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Warehouse</p>
          </div>
        </button>
        <button onClick={() => navigate("/brand")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={brand}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Brand</p>
          </div>
        </button>
        <button onClick={() => navigate("/category")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={categories}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Category</p>
          </div>
        </button>
        <button onClick={() => navigate("/uoms")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img src={uoms} alt="Platinum Logo" className="w-[70px] h-[70px]" />
            <p className="mt-3 text-center">UOMS</p>
          </div>
        </button>
        <button onClick={() => navigate("/voucher")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={voucher}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Voucher</p>
          </div>
        </button>
        <button onClick={() => navigate("/mobile/products-unit")}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            <img
              src={voucher}
              alt="Platinum Logo"
              className="w-[70px] h-[70px]"
            />
            <p className="mt-3 text-center">Product Pameran </p>
          </div>
        </button>
        <button disabled={loading} onClick={printStock}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            {!!loading ? (
              <p>...loading</p>
            ) : (
              <img
                src={exportLogo}
                alt="Platinum Logo"
                className="w-[70px] h-[70px]"
              />
            )}
            <p className="mt-3 text-center">Export Stock</p>
          </div>
        </button>
        <button disabled={loading} onClick={printInvoicesReport}>
          <div className="bg-slate-100 py-5 flex flex-col items-center justify-center m-5 border border-slate-400 rounded-md">
            {!!loadingInvoices ? (
              <p>...loading</p>
            ) : (
              <img
                src={exportLogo}
                alt="Platinum Logo"
                className="w-[70px] h-[70px]"
              />
            )}
            <p className="mt-3 text-center">Export Invoices</p>
          </div>
        </button>
      </div>
    </div>
  );
}
