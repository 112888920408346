import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useAxios } from "../useApi";

interface User {
  // define the properties of your user object here
}

interface ApiResponse {
  links: any;
  meta: any;
  data: any;
  pages: any;
  users: User[];
  totalPages: number;
  currentPage: number;
  map: any;
  length: number;
  filter: any;
}

const useStockOpnameHistory = (
  params?: any,
  options?: UseInfiniteQueryOptions<ApiResponse>
): UseInfiniteQueryResult<ApiResponse> => {
  const api = useAxios();

  return useInfiniteQuery<ApiResponse>(
    "stockOpnameHistory",
    ({ pageParam = 1 }) => {
      return api
        .get(`stock-opnames/${params?.id}/details/${params?.detailId}/items`, {
          params: {
            ...params,
            page: pageParam,
            perPage: 10,
          },
        })
        .then((res) => {
          const data = res.data as ApiResponse;
          return data;
          //DEBT: DOCS RESULT
          //   return {
          //     ...data,
          //     users: data.users.map((user) => ({
          //       // map the user properties to your own interface here
          //     })),
          //   };
        });
    },
    {
      // getNextPageParam: (lastQuery, pages) => {
      //   // Error
      //   if (lastQuery === undefined) {
      //     return false;
      //   }

      //   const currentPage = lastQuery.meta!.current_page;

      //   if (!lastQuery.links!.next) return false;
      //   return currentPage! + 1;
      // },
      ...options,
      retry: false,
    }
  );
};

export default useStockOpnameHistory;
