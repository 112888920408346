import React, { useState, useCallback, useEffect, SetStateAction } from "react";
import Header from "../../components/molecules/header";
import TableUser from "../../components/molecules/tables/tableUser";
import useUserList from "../../api/hooks/user/useUserList";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import Loading from "../../components/atoms/loading";
import { Button, Input } from "@chakra-ui/react";
import Pagination from "../../components/molecules/pagination";
import { COLOR_FIGMA_LIGHT_BLUE, COLOR_PRIMARY } from "../../Helper/theme";
import { useNavigate } from "react-router-dom";
import TableRoles from "../../components/molecules/tables/tableRoles";
import useRoleList from "../../api/hooks/roles/useRoleList";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";
import NoPermission from "../../components/molecules/noPermission";

const Roles = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const nav = useNavigate();
  const [dataPermission] = useRecoilState<any>(permissionState);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useRoleList({
    "filter[name]": search,
  });
  const countData = data?.pages[0].meta.total;
  const users = data?.pages.flatMap((page) => page.data);
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  useEffect(() => {
    refetch();
  }, [currentPage, search]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <DashboardContainer>
      {!dataPermission?.role_access?.role_access ? (
        <NoPermission />
      ) : (
        <>
          <div>
            <Header headerName={"Roles"} />
            <div
              className="row space-x-11"
              style={{ marginBottom: "2rem", display: "flex" }}
            >
              <Input
                placeholder={"search roles"}
                w="90%"
                bg={"white"}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Button
                size={"md"}
                bg={COLOR_FIGMA_LIGHT_BLUE}
                color="#fff"
                w="9rem"
                onClick={() => nav("/roles/new-roles")}
              >
                + Role
              </Button>
            </div>
          </div>
          <TableRoles
            data={users}
            refetch={refetch}
            navName={"/roles/edit-roles"}
          />
        </>
      )}
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
        handlePagination={handlePagination}
      />
    </DashboardContainer>
  );
};

export default Roles;
