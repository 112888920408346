import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  description: string;
  product_id: number;
  price: number;
  code: string;
  uom_id: string;
  package_id: number;
  isPackage: number;
  is_auto_tempel: boolean | number;
  is_ppn: boolean | number;
};

const useCreateProductUnitMutation = () => {
  const api = useAxios();
  return useMutation(
    ({
      name,
      description,
      product_id,
      price,
      code,
      uom_id,
      package_id,
      isPackage,
      is_auto_tempel,
      is_ppn,
    }: ParamType) => {
      return api
        .post(`product-units`, {
          name: name,
          description: description,
          product_id: product_id,
          price: price,
          code: code,
          uom_id: uom_id,
          packaging_id: package_id,
          is_generate_qr: isPackage,
          is_auto_tempel: is_auto_tempel,
          is_ppn: is_ppn,
        })
        .then((res) => {
          console.log(res, "test");
          toast("Product Unit berhasil dibuat");
        })
        .catch((err) => {
          console.log(err, "err");
          toast("Something went wrong");
        });
    }
  );
};

export default useCreateProductUnitMutation;
