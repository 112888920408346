import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import useVerifyOrder from "../../../api/hooks/receiveOrder/useVerifyOrder";
import { COLOR_BLUE, COLOR_PRIMARY } from "../../../Helper/theme";
import { useState } from "react";
import useCreateUoms from "../../../api/hooks/uoms/useCreateUoms";

export function CreateUomsModal({ refetch, isLoading }: any) {
  const [name, setName] = useState("");
  const { mutateAsync } = useCreateUoms();
  const handleSubmit = () => {
    mutateAsync({
      name: name,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        backgroundColor={COLOR_BLUE}
        color="white"
        w={"8rem"}
        onClick={() => {
          onOpen();
        }}
      >
        Create Uoms
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uoms</ModalHeader>
          <ModalCloseButton />
          <ModalBody>are you sure you want to create this uoms?</ModalBody>
          <ModalBody>
            <div style={{ padding: "0.5rem" }}>
              <h3 style={{ fontSize: "1em", fontWeight: "bold" }}>Uoms name</h3>
              <Input
                mt="0.5rem"
                placeholder="Input here"
                inputMode="text"
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mx={5}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bgColor={COLOR_PRIMARY}
              color="white"
              isLoading={isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
