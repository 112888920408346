import { SetStateAction } from "react";
import { MultiSelect } from "react-multi-select-component";

interface Option {
  value: string;
  label: string;
}

function MultipleSelect({ data, selectedOptions, setSelectedOptions }: any) {
  const options = !data
    ? []
    : data?.map((e: any) => ({
        label: e.product_unit.name,
        value: e.id,
      }));
  const handleSelectChange = (selected: SetStateAction<never[]>) => {
    setSelectedOptions(selected);
  };

  return (
    <MultiSelect
      options={options}
      value={selectedOptions}
      onChange={handleSelectChange}
      hasSelectAll={true}
      disableSearch
      labelledBy="select"
      overrideStrings={{
        selectSomeItems: "Select options",
        allItemsAreSelected: "All options are selected",
        search: "Search",
      }}
    />
  );
}

export default MultipleSelect;
