/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAttachProductMutation from "../../api/hooks/deliveryOrder/useAttachProductMutation";
import useDeleteDO from "../../api/hooks/deliveryOrder/useDeleteDO";
import useSalesOrderProduct from "../../api/hooks/salesOrder/useSalesOrderProduct";
import { useAxios } from "../../api/hooks/useApi";
import { COLOR_PRIMARY, COLOR_RED, COLOR_YELLOW } from "../../Helper/theme";
import { queryClient } from "../../provider/query";
import MultipleSelectProduct from "./select/multipleSelect";
import { SelectSalesOrderList } from "./selectSalesOderList";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";

const PrintDeliveryOrder = ({ data, props }: any) => {
  const api = useAxios();
  console.log(data, "check data kirim do detail");
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataPermission] = useRecoilState<any>(permissionState);
  const { mutateAsync } = useDeleteDO();
  const handleDelete = ({ item }: any) => {
    mutateAsync({
      id: props.id,
    }).then(() => {
      navigation(-1);
    });
  };
  const printData = () => {
    api
      .get(`delivery-orders/${props?.id}/print`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${props?.soNumber}.pdf`; // Specify the desired file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the PDF file:", error);
        // Handle the error case accordingly
      });
  };
  return (
    <>
      <Box display={"flex"}>
        <Button
          bg={COLOR_YELLOW}
          mt={"1rem"}
          color="#fff"
          size={"md"}
          isDisabled={
            !dataPermission?.delivery_order_access?.delivery_order_create
          }
          mr={5}
          onClick={() => {
            onOpen();
          }}
        >
          + SO Related
        </Button>
        <Button
          bg={COLOR_PRIMARY}
          mt={"1rem"}
          mr={5}
          color="#fff"
          size={"md"}
          isDisabled={
            !dataPermission?.delivery_order_access?.delivery_order_print
          }
          onClick={printData}
        >
          Download DO
        </Button>
        <Button
          bg={"#d63031"}
          mt={"1rem"}
          color="#fff"
          size={"md"}
          onClick={handleDelete}
          isDisabled={
            !dataPermission?.delivery_order_access?.delivery_order_delete
          }
        >
          Delete DO
        </Button>
      </Box>
      <ModalSO isOpen={isOpen} onClose={onClose} id={props?.id} />
    </>
  );
};

const ModalSO = ({ isOpen, onClose, id }: any) => {
  const [selectedSO, setSelectedSO] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { mutateAsync } = useAttachProductMutation();
  const location = useLocation();
  const dataProps = location.state;
  console.log(selectedSO?.id, "ini so idnya");
  console.log(dataProps?.reseller_id, "ini so reseller_id");
  const { data, refetch, isLoading } = useSalesOrderProduct(
    {
      id: selectedSO?.id,
      "filter[has_delivery_order]": 0,
    },
    {
      enabled: false,
    }
  );
  const dataList = data?.pages.flatMap((page) => page.data);
  console.log(dataList, "check datalist");
  useEffect(() => {
    if (selectedSO) {
      refetch();
    }
    return;
  }, [selectedSO?.id]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent h={"60%"}>
        <ModalHeader>Add Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <SelectSalesOrderList
              setSelected={setSelectedSO}
              selected={selectedSO}
              resellerId={dataProps?.reseller_id}
            />
          </Box>
          <h3 style={{ marginTop: 10, marginBottom: 10 }}>Select Product</h3>
          <MultipleSelectProduct
            data={dataList}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            bg={COLOR_YELLOW}
            color="#fff"
            size={"md"}
            onClick={() => {
              mutateAsync({
                id: id,
                product: selectedOptions.map((e: any) => e.value),
              }).then(() => {
                queryClient.invalidateQueries("DeliveryOrderDetail");
              });
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default PrintDeliveryOrder;
