import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSalesOrderList from "../../api/hooks/salesOrder/useSalesOrderList";
import useReceiveStock from "../../api/hooks/stocks/useReceiveStock";
import { Select } from "chakra-react-select";

export const SelectStocks = ({ selected, setSelected }: any) => {
  const [searchValue, setSearchValue] = useState<string>();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage,
  } = useReceiveStock({
    "filter[product_unit]": searchValue,
  });
  useEffect(() => {
    refetch();
  }, [searchValue]);
  const dataStocks = data?.pages?.flatMap((page) => page.data);
  return (
    <>
      <Box mt={5}>
        <h3>Select Stocks</h3>
        <FormControl style={{ backgroundColor: "white" }}>
          <Select
            inputValue={searchValue}
            onInputChange={(e) => setSearchValue(e)}
            name="colors"
            options={dataStocks?.map((item: any) => ({
              id: item.id,
              label: item.product_unit?.name,
            }))}
            placeholder="Select Product"
            closeMenuOnSelect={true}
            value={selected}
            onChange={setSelected}
          />
        </FormControl>
        {/* <Menu isLazy={false} autoSelect={false}>
          <MenuButton
            textAlign={"start"}
            fontWeight="normal"
            bg={"white"}
            w={"100%"}
            mt={"0.5rem"}
            borderWidth={1}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {!selected ? "Select Stocks" : selected?.product_unit?.name}
          </MenuButton>
          <MenuList>
            <Input
              borderLeftWidth={0}
              borderRightWidth={0}
              placeholder="Search here..."
              onChange={(event) => setSearchValue(event.target.value)}
            />
            {dataStocks?.map((item: any) => (
              <MenuItem
                style={{ color: "#000" }}
                id={item?.id}
                key={item?.id}
                onClick={() => setSelected(item)}
              >
                {item?.product_unit?.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu> */}
      </Box>
    </>
  );
};
