import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { defaultErrorHandler } from "../../errors";
import { useAxios } from "../useApi";

type ParamType = {
  name: string;
  permission_ids: any;
};

const useCreateRolesList = () => {
  const api = useAxios();
  return useMutation(({ name, permission_ids }: ParamType) => {
    return api
      .post(`roles`, {
        name: name,
        permission_ids: permission_ids,
      })
      .then((res) => {
        console.log(res);
        toast("Role berhasil dibuat");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  });
};

export default useCreateRolesList;
