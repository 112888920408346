import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../useApi";

type ParamType = {
  name?: string;
  address: string;
  phone?: string;
  description: string;
  invoice_no: string;
  shipment_fee: number;
  warehouseId: string;
  transactionDate: any;
  estimatedDate: any;
  items: any;
  discount: number;
  voucher: string;
  selectedCustomerId: number;
  type: string;
  is_additional_discount_percentage: "nominal" | "percentage";
};

const usePreviewInvoiceMutation = () => {
  const api = useAxios();
  const nav = useNavigate();

  return useMutation(
    ({
      description,
      warehouseId,
      transactionDate,
      invoice_no,
      shipment_fee,
      estimatedDate,
      items,
      discount,
      name,
      type,
      address,
      phone,
      voucher,
      selectedCustomerId,
      is_additional_discount_percentage,
    }: ParamType) => {
      return api
        .post(`invoices`, {
          is_preview: true,
          customer_name: name,
          customer_phone: phone,
          customer_address: address,
          invoice_no: invoice_no,
          shipment_fee: shipment_fee,
          description: description,
          warehouse_id: warehouseId,
          transaction_date: transactionDate,
          shipment_estimation_datetime: estimatedDate,
          items: items,
          additional_discount: discount,
          voucher_code: voucher,
          reseller_id: selectedCustomerId,
          type: type,
          is_additional_discount_percentage:
            is_additional_discount_percentage === "nominal" ? 0 : 1,
        })
        .then((res) => {
          const data = res.data.data;
          return data;
        })
        .catch((err) => {
          console.log(err, "err");
          toast(err?.axiosError?.response?.data?.message);
        });
    }
  );
};

export default usePreviewInvoiceMutation;
