import { Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import salesOrder from "../../../assets/logo/customer.png";
import opname from "../../../assets/logo/inventory.png";
import platLogo from "../../../assets/logo/Logo-Gram-Full-White.png";
import { userState } from "../../../provider/atoms";
import { useAuth } from "../../../provider/auth";

export default function MobileInvoiceList() {
  const { onLogout } = useAuth();
  const [userData, setUserData] = useRecoilState<any>(userState);
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-gradient-to-r from-[#01338C] to-[#00A3E0]  h-[30p] p-5 w-full flex justify-between">
        <div>
          <p className=" text-md font-medium text-white">
            Hi, {userData?.name}{" "}
          </p>
          <p className="text-sm text-white">Have a great day</p>
        </div>
        <Image src={platLogo} boxSize={"50px"} alt="Platinum Logo" />
      </div>
      <div className="flex flex-col items-center justify-center flex-grow">
        <div
          onClick={() => navigate("/mobile/invoice-order/create")}
          className="bg-gradient-to-r from-[#E0E7FF] mt-4 to-[#FFFAE0] p-6 rounded-lg shadow-lg w-[95%] flex items-center justify-between"
        >
          <p className="text-gray-800 text-md  font-medium">Create Invoice</p>
          <Image src={salesOrder} boxSize={"30px"} alt="Platinum Logo" />
        </div>
        <div
          onClick={() => navigate("/mobile/invoice-order/list")}
          className="bg-gradient-to-r from-[#E0E7FF] mt-4 to-[#FFFAE0] p-6 rounded-lg shadow-lg w-[95%] flex justify-between"
        >
          <p className="text-gray-800 text-md font-medium">List Invoice</p>
          <Image src={opname} boxSize={"30px"} alt="Platinum Logo" />
        </div>
        <div
          onClick={() => navigate("/mobile/products-unit")}
          className="bg-gradient-to-r from-[#E0E7FF] mt-4 to-[#FFFAE0] p-6 rounded-lg shadow-lg w-[95%] flex justify-between"
        >
          <p className="text-gray-800 text-md font-medium">Products Unit</p>
          <Image src={opname} boxSize={"30px"} alt="Platinum Logo" />
        </div>

        <div
          onClick={() => onLogout()}
          className="bg-gradient-to-r from-[#E0E7FF] mt-4 to-[#FFFAE0] p-6 rounded-lg shadow-lg w-[95%]"
        >
          <p className="text-gray-800 text-md font-medium">Log out</p>
        </div>
      </div>
      {/* <Button
          onClick={() => {
            onLogout();
          }}
          bg={COLOR_PRIMARY}
          color={"#fff"}
        >
          Logout
        </Button> */}
    </div>
  );
}
