import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { COLOR_YELLOW } from "../../Helper/theme";
import useProductBrands from "../../api/hooks/product/useProductBrands";
import { DashboardContainer } from "../../components/atoms/dashboardContainer";
import FormProductBrand from "../../components/forms/productBrandForm";
import Header from "../../components/molecules/header";
import { DeleteBrand } from "../../components/molecules/modal/DeleteBrand";
import Pagination from "../../components/molecules/pagination";
import { permissionState } from "../../provider/atoms";
import NoPermission from "../../components/molecules/noPermission";

function BrandMaster() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const { data, refetch } = useProductBrands({
    "filter[name]": search,
    page: currentPage,
  });
  const brandList = data?.pages.flatMap((page) => page.data);
  const countData = data?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    refetch();
  }, [currentPage, search]);

  return (
    <DashboardContainer>
      {!dataPermission?.product_brand_access?.product_brand_access ? (
        <NoPermission />
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <Header headerName={"Brand"} back={true} />
              <div
                className="row space-x-11"
                style={{ marginBottom: "2rem", display: "flex" }}
              >
                <Input
                  bg={"white"}
                  placeholder={"Search"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <>
                  {dataPermission?.product_brand_access
                    ?.product_brand_create ? (
                    <Button
                      w={"10rem"}
                      ml="2rem"
                      bg={"#2E79FF"}
                      color="#fff"
                      // size={"lg"}
                      onClick={() => {
                        onOpen();
                      }}
                    >
                      New Brand
                    </Button>
                  ) : null}

                  <Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>New Brand</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <FormProductBrand refetch={refetch} onClose={onClose} />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </>
              </div>
            </div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {brandList?.map((item: any, index: any) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {moment(item?.created_at).format("L")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Button
                        onClick={() =>
                          nav("/brand/edit-brand", { state: item })
                        }
                        bg={COLOR_YELLOW}
                        color={"#fff"}
                        ml={"1rem"}
                      >
                        Update
                      </Button>
                      <DeleteBrand refetch={refetch} item={item} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            pagesQuantity={countData ? Math.ceil(countData / 15) : 0}
            handlePagination={handlePagination}
          />
        </>
      )}
    </DashboardContainer>
  );
}

export default BrandMaster;
