import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import useCreateProductUnitMutation from "../../api/hooks/product/useCreateProductUnitMutation";
import useUomsList from "../../api/hooks/uoms/useUomsList";
import { COLOR_PRIMARY } from "../../Helper/theme";
import { SelectPackagingProductUnit } from "../molecules/selectPackagingProduct";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("this field is required")
    .min(5, "name must be more than 5 characters"),
  description: Yup.string()
    .required("this field is required")
    .min(10, "description must be more then 10 characters"),
  price: Yup.number().required("this field is required"),
  code: Yup.string()
    .min(5, "code must be more then 5 character")
    .required("this field is required"),
  uom_id: Yup.string().required("this field is required"),
});

function FormProductUnit({ refetch, productID, onClose, isPackage }: any) {
  const [selected, setSelected] = useState<any>();
  const [items, setItems] = useState<any>({});
  const { mutateAsync } = useCreateProductUnitMutation();
  const initialValues = {
    name: "",
    description: "",
    price: 0,
    code: "",
    uom_id: "",
    is_auto_tempel: true,
    is_ppn: false,
  };

  const handleSubmit = (values: any) => {
    mutateAsync({
      name: values.name,
      description: values.description,
      product_id: productID,
      price: values.price,
      code: values.code,
      uom_id: selected?.id || 1,
      package_id: items?.id,
      isPackage: isPackage,
      is_auto_tempel: values.is_auto_tempel,
      is_ppn: values.is_ppn,
    }).then(() => {
      refetch();
      onClose();
    });
  };

  const UomPicker = ({ selected, setSelected }: any) => {
    const { data } = useUomsList({});
    const dataUoms = data?.pages.flatMap((page) => page.data);
    const [search, setSearch] = useState<any>();
    return (
      <Box>
        <FormControl style={{ backgroundColor: "white" }}>
          <Select
            inputValue={search}
            onInputChange={(e) => setSearch(e)}
            name="colors"
            options={dataUoms?.map((item: any) => ({
              id: item.id,
              label: item.name,
            }))}
            placeholder="Select Uoms"
            closeMenuOnSelect={true}
            value={selected}
            onChange={setSelected}
          />
        </FormControl>
      </Box>
    );
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <FormControl my={3} isInvalid={!!(errors.name && touched.name)}>
              <FormLabel fontWeight={"medium"} fontSize={"1em"}>
                Name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Input name here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl my={3} isInvalid={!!(errors.price && touched.price)}>
              <FormLabel fontWeight={"medium"} fontSize={"1em"}>
                Price
              </FormLabel>
              <Field
                as={Input}
                name="price"
                placeholder="Input price here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.price}</FormErrorMessage>
            </FormControl>
            <FormControl my={3} isInvalid={!!(errors.code && touched.code)}>
              <FormLabel fontWeight={"medium"} fontSize={"1em"}>
                Code:
              </FormLabel>
              <Field
                as={Input}
                name="code"
                placeholder="Input code here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.code}</FormErrorMessage>
            </FormControl>
            <FormControl my={3} isInvalid={!!(errors.code && touched.code)}>
              <FormLabel fontWeight={"medium"} fontSize={"1em"}>
                UOM:
              </FormLabel>
              <UomPicker
                selected={selected}
                setSelected={(e: any) => setSelected(e)}
              />
              <FormErrorMessage>{errors.code}</FormErrorMessage>
            </FormControl>
            {isPackage ? null : (
              <SelectPackagingProductUnit
                selectedProducts={items}
                setSelectedProduct={(e: any) => setItems(e)}
              />
            )}
            <FormControl
              my={3}
              isInvalid={!!(errors.description && touched.description)}
            >
              <FormLabel fontWeight={"medium"} fontSize={"1em"}>
                Description
              </FormLabel>
              <Field
                as={Textarea}
                name="description"
                placeholder="Input description here..."
                size={"lg"}
              />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormLabel>Include PPN</FormLabel>
              <Switch
                id="is_auto_tempel"
                isChecked={values.is_ppn}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  setFieldValue("is_ppn", isChecked);
                }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormLabel>Auto Tempel QR?</FormLabel>
              <Switch
                id="is_auto_tempel"
                isChecked={values.is_auto_tempel}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  setFieldValue("is_auto_tempel", isChecked);
                }}
              />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                type="submit"
                size={"md"}
                my={10}
                bg={COLOR_PRIMARY}
                color="#fff"
                onClick={() => handleSubmit(values)}
              >
                Create Product Unit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FormProductUnit;
